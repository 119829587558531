import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController';
import { notificationController } from "./NotificationController";

import dayjs from 'dayjs';
import customParseFormat  from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

class RecebimentosController {

  searchRecebimentos = async (searchOptions, page, itensPerPage) => {
    try {
      let query = ENDPOINT + '/api/Recebimentos?countTotal=true';

      if (searchOptions.numeroNota?.length > 0) {
        query += '&numeroNota=' + searchOptions.numeroNota;
      }

      if (searchOptions.solicitacaoId) {
        query += '&solcitacaoId=' + searchOptions.solicitacaoId.toString();
      }

      if (searchOptions.startDate) {
        query += '&dataInicial=' + searchOptions.startDate;
      }

      if (searchOptions.finalDate) {
        query += '&dataFinal=' + searchOptions.finalDate;
      }

      if (searchOptions.fornecedorId) {
        query += '&fornecedorId=' + searchOptions.fornecedorId;
      }

      if (page) {
        const skip = (page - 1) * itensPerPage; 
        query += '&skip=' + skip.toString() + '&limit=' + itensPerPage.toString();
      } else {
        query += '&limit=100';
      }

      const response = await fetch(query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao pesquisar os recebimentos', await response.text());
        return [];
      }
    } catch (error) {
      notificationController.publishError('Falha ao pesquisar os recebimentos', error);
      return [];
    }
  }

  getRecebimento = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Recebimentos/' + id.toString(), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao carregar os recebimentos', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao carregar os recebimentos', error);
      return null;
    }
  }

  insertRecebimento = async (recebimento) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Recebimentos', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(recebimento)
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao inserir o recebimento', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao inserir o recebimento', error);
      return null;
    }
  }
  
  updateRecebimento = async (recebimento, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Recebimentos/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(recebimento)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar o recebimento', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar o recebimento', error);
      return false;
    }
  }
  
  deleteRecebimento = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Recebimentos/' + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao excluir o recebimento', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao excluir o recebimento', error);
      return false;
    }
  }

  downloadRecebimento = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Recebimentos/gerar-relatorio-recebimento-pdf?recebimentoId=' + id.toString(), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return await response.blob();
      } else {
        notificationController.publishError('Falha ao baixar o recebimento', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao baixar o recebimento', error);
      return null;
    }
  }
}

export const recebimentosController = new RecebimentosController();
