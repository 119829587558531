import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Input,
  Button,
  HStack,
  FormControl,
  Select,
  VStack,
  Text,
  Modal,
  Center
} from 'native-base';

import { constsController } from "../controllers/ConstsController";
import CurrencyInput from "../components/CurrencyInput";
import NumberInput from "../components/NumberInput";

export default class CompraProdutoEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: props.produto?.itemId ?? 0,
      id: props.produto?.id ?? 0,
      codigo: props.produto?.codigo ?? '',
      marca: props.produto?.marca ?? '',
      tipo: props.produto?.tipo ?? 1,
      descricao: props.produto?.descricao ?? '',
      unidade: props.produto?.unidade ?? '',
      qtd: props.produto?.qtd ?? 0,
      valorUnitario: props.produto?.valorUnitario ?? 0,
      quantidadeMinima: props.produto?.quantidadeMinima ?? 0,
      quantidadeMaxima: props.produto?.quantidadeMaxima ?? 0,
      saldo: props.produto?.saldo ?? 0,
      saldoOriginal: props.produto?.saldoOriginal ?? 0,
      qtdOriginal: props.produto?.qtdOriginal ?? 0,
      invalidInputs: {
        codigo: false,
        marca: false,
        descricao: false,
        unidade: false,
        qtd: false,
        valorUnitario: false
      },
      errorQuantidade: "Informe a quantidade"
    }
    this.viewOnly = props.produto?.itemId > 0;
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        size="lg"
      >
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header>{((this.state.id > 0) ? 'Editar' : 'Adicionar') + ' produto'}</Modal.Header>
          <Modal.Body>
            <VStack space="2">
              <FormControl
                isInvalid={this.state.invalidInputs.codigo}
                isDisabled={this.props.isDisabled || this.props.qtdEdit}
              >
                <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Código</FormControl.Label>
                <Input value={this.state.codigo} onChangeText={(text) => this.setState({ codigo: text })} isDisabled={this.viewOnly} />
                <FormControl.ErrorMessage>Informe a código do produto</FormControl.ErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={this.state.invalidInputs.descricao}
                isDisabled={this.props.isDisabled || this.props.qtdEdit}
              >
                <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Descrição do produto</FormControl.Label>
                <Input value={this.state.descricao} onChangeText={(text) => this.setState({ descricao: text }) }  isDisabled={this.viewOnly}  />
                <FormControl.ErrorMessage>Informe a descrição do produto</FormControl.ErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={this.state.invalidInputs.marca}
                isDisabled={this.props.isDisabled || this.props.qtdEdit}
              >
                <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Marca</FormControl.Label>
                <Input value={this.state.marca} onChangeText={(text) => this.setState({ marca: text })} isDisabled={this.viewOnly}  />
                <FormControl.ErrorMessage>Informe a marca do produto</FormControl.ErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={this.state.invalidInputs.unidade}
                isDisabled={this.props.isDisabled || this.props.qtdEdit}
              >
                <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Unidade</FormControl.Label>
                <Select
                  defaultValue={this.state.unidade?.toString() ?? ''}
                  onValueChange={(selectedItem) => this.setState({ unidade: selectedItem })}
                  placeholder="Escolher unidade"
                  key={"unidade" + this.state.unidade} isDisabled={this.viewOnly} 
                >
                  {
                    Object.entries(constsController.tiposUnidade).map(([id, desc]) => {
                      return <Select.Item label={desc} value={id} key={id} />;
                    })
                  }
                </Select>
                <FormControl.ErrorMessage>Informe a unidade</FormControl.ErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={this.state.invalidInputs.qtd}
                isDisabled={this.props.isDisabled}
              >
                <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Quantidade</FormControl.Label>
                <HStack>
                  <VStack flex={1}>
                    <NumberInput
                      number={this.state.qtd}
                      onChangeNumber={(newNumber) => {
                        let saldo = 0;
                        if (this.props.isTipoSolicitacaoContrato){
                          saldo =  this.state.saldoOriginal - (newNumber - this.state.qtdOriginal);
                        }
                        this.setState({ qtd: newNumber, saldo: saldo })
                      }}
                    />
                    <HStack>
                      <Text bold={true} paddingX={2}>{"Saldo atual: " + this.state.saldo}</Text> 
                      <Text fontSize={12} paddingX={2}>{"Qtd. Mímima: " + this.state.quantidadeMinima}</Text> 
                      <Text fontSize={12} paddingX={2}>{"Qtd. Máxima: " + this.state.quantidadeMaxima}</Text>
                    </HStack>
                  </VStack>
                </HStack>
                <FormControl.ErrorMessage>{this.state.errorQuantidade}</FormControl.ErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={this.state.invalidInputs.valorUnitario}
                isDisabled={this.viewOnly || this.props.isDisabled || this.props.qtdEdit}
              >
                <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Valor unitário</FormControl.Label>
                <CurrencyInput
                  value={this.state.valorUnitario}
                  onChangeValue={(newValue) => this.setState({ valorUnitario: newValue })}
                />
                <FormControl.ErrorMessage>Informe valor maior que zero</FormControl.ErrorMessage>
              </FormControl>

              <FormControl isDisabled={this.props.isDisabled}>
                <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Valor total</FormControl.Label>
                <Input
                  value={
                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  })
                      .format(this.state.valorUnitario * this.state.qtd)
                  }
                  isDisabled={true}
                />
              </FormControl>
            </VStack >
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => this.props.onClose?.()}
              >
                Cancelar
              </Button>
              <Button onPress={this.#handleAddProdutoPress}>
                Salvar
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }

  #handleAddProdutoPress = () => {
    const invalidInputs = {
      codigo: this.state.codigo.length === 0,
      //marca: this.state.marca.length === 0,
      descricao: this.state.descricao.length === 0,
      unidade: this.state.unidade.length === 0,
      qtd: this.state.qtd === 0.0 || Number(this.state.saldo) < 0,
      valorUnitario: this.state.valorUnitario === 0.0
    }

    if (invalidInputs.qtd){
      if (this.state.saldo < 0)
        this.setState({errorQuantidade: "O Saldo não pode ser negativo"})
      else 
        this.setState({errorQuantidade: "Informe a quantidade"})
    }
    
    if (this.state.qtd > 0){
      if (this.state.quantidadeMaxima > 0.0 && this.state.qtd > this.state.quantidadeMaxima) {
        invalidInputs.qtd = true;
        this.setState({errorQuantidade: "A quantidade não pode ser maior que a quantidade máxima"})
      }
      else if (this.state.quantidadeMinima > 0.0 && this.state.qtd < this.state.quantidadeMinima) {
        invalidInputs.qtd = true;
        this.setState({errorQuantidade: "A quantidade não pode ser menor que a quantidade mínima"})
      }
    }

    let allInputIsValid = true;
    for (let key in invalidInputs) {
      if (invalidInputs[key]) {
        allInputIsValid = false;
        break;
      }
    }

    if (allInputIsValid) {
      this.props.onSave?.(this.state);
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }
}
