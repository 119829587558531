import React from 'react';
import {
  Input
} from 'native-base';

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numberTxt: this.props.number?.toString() ?? '',
      number: this.props.number
    }
  }

  render() {
    return (
      <Input
        value={this.state.numberTxt}
        onChangeText={this.#handleTextChange}
        {...this.props}
      />
    );
  }

  #handleTextChange = (text) => {
    text = text.replace(',', '.');
    let value = parseFloat(text);
    if (isNaN(value)) {
      value = this.props.defaultNumber ?? 0
    }
    if (this.state.number !== value) {
      this.props.onChangeNumber?.(value);
    }
    this.setState({ number: value, numberTxt: text });
  }
}

export default NumberInput;