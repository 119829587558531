import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Text,
  Input,
  IconButton,
  Center,
  HStack,
  VStack,
  Box
} from 'native-base';

import { GridRow, GridView, ResponsiveGridItem } from "../components/GridView";
import { constsController } from "../controllers/ConstsController";
import { ListNavigator } from "../components/ListNavigator";
import TextSearchPanel from "../components/TextSearchPanel";

const NAVIGATOR_ITENS_PER_PAGE = 15;

const ActionButton = (props) => {
  return (
    <IconButton
      variant="ghost"
      size="sm"
      padding="1"
      onPress={props.onPress}
      isDisabled={props.isDisabled}
      _icon={{
        as: FontAwesome5,
        name: props.iconName,
        size: "4"
      }}
    />
  );
}

const ActionButtons = (props) => {
  return (
    <Center padding="0">
      <HStack space="1">
        <ActionButton
          onPress={props.onEdit}
          iconName="edit"
          isDisabled={props.isDisabled}
        />
        <ActionButton
          onPress={props.onDelete}
          iconName="trash-alt"
          isDisabled={props.isDisabled}
        />
      </HStack>
    </Center>
  );
}

export default class ProdutoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedText: '',
    };
  }

  render() {
    return (
      <VStack space={2}>
        

        <TextSearchPanel
          placeholder="Buscar produtos"
          disablePersistence={true}
          onSearch={(text) => this.setState({ searchedText: text })}
        />

        <ListNavigator
          key={'ListNavigator' + this.props.page}
          page={this.props.page}
          countItens={this.props.produtos.length}
          itensPerPage={NAVIGATOR_ITENS_PER_PAGE}
          onChangePage={this.props.onChangePage}
        />

        <GridView
          minHeight="60"
          data={this.#getProdutosSlice(this.props.produtos, this.props.page)}
          keyExtractor={(item, index) => { return index.toString(); }}
          columnCaptions={[
            "Código",
            "Descrição",
            "Lote",
            "Marca",
            "Unidade",
            "Saldo",
            "Quantidade",
            "Valor Unitário",
            "Total",
            ""
          ]}
          ListFooterComponent={
            <HStack flex={1} space={2} marginY={1}>
              <Box flex={0.3} />
              <Text flex={0.1}>Valor total geral:</Text>
              <Text flex={0.2}>{this.#getValorTotalItens()}</Text>
              <Text flex={0.2}>Valor total da página:</Text>
              <Text flex={0.2}>{this.#getValorTotalItensPage()}</Text>
            </HStack>
          }
          renderGridItem={(item, index, separators, props) => {
            return (
              <ResponsiveGridItem flex="1"
                tooltipVisibool={true}
                values={[
                  item.codigo,
                  item.descricao,
                  item.lote,
                  item.marca,
                  constsController.tiposUnidade[item.unidade],
                  item.saldo,
                  item.qtd,
                  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(item.valorUnitario),
                  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(item.qtd * item.valorUnitario),
                  <ActionButtons
                    isDisabled={this.props.isDisabled}
                    onDelete={() => this.props.onDeleteItem?.(item)}
                    onEdit={() => this.props.onEditItem?.(item)}
                  />
                ]}
                backgroundColor={(index % 2 === 0) ? "coolGray.100" : null}
                width="100%"
                gridProps={props}
                dimensions={props.columnDimensions}
              />
            )
          }}
        />
      </VStack>
    );
  }

  #getProdutosSlice = (produtos, page) => {
    const maxPages = Math.ceil(produtos.length / NAVIGATOR_ITENS_PER_PAGE);
    if (page > maxPages) {
      page = maxPages;
    }
    const skip = (page - 1) * NAVIGATOR_ITENS_PER_PAGE;
    return produtos
      .filter(produto => {
        return this.state.searchedText.trim() === "" ||
          (
            produto.descricao.toUpperCase().includes(this.state.searchedText.trim().toUpperCase())
            ||
            produto.codigo.toUpperCase().includes(this.state.searchedText.trim().toUpperCase())
          )
      })
      .slice(skip, skip + NAVIGATOR_ITENS_PER_PAGE);
  }

  #getValorTotalItens = () => {
    let valorTotal = 0.0;
    this.props.produtos.forEach((produto) => {
      valorTotal += produto.qtd * produto.valorUnitario;
    });
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(valorTotal);
  }

  #getValorTotalItensPage = () => {
    let valorTotal = 0.0;
    this.#getProdutosSlice(this.props.produtos, this.props.page).forEach((produto) => {
      valorTotal += produto.qtd * produto.valorUnitario;
    });
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(valorTotal);
  }

}