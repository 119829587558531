import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController';
import { notificationController } from "./NotificationController";

class ImportacaoController {
  importLicitacao = async (file) => {
    const formData = new FormData()
    formData.append('file', file, file.path);
    try {
      const response = await fetch(ENDPOINT + '/api/Importacao/licitacao', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: formData
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao importar a licitação', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao importar a licitação', error);
      return null;
    }
  }
}

export const importacaoController = new ImportacaoController();
