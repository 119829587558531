import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController';
import { notificationController } from "./NotificationController";

class PrefeituraController {
  #prefeitura

  getPrefeitura = async () => {
    if (!this.#prefeitura) {
      this.#prefeitura = await this.fetchPrefeitura()
    }
    return this.#prefeitura;
  }

  fetchPrefeitura = async () => {
    try {
      const response = await fetch(ENDPOINT + '/api/Prefeitura/', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao carregar a prefeitura', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao carregar a prefeitura', error);
      return null;
    }
  }

}

export const prefeituraController = new PrefeituraController();
