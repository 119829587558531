import React from "react";

import {
  Pressable,
} from 'native-base';

export const TooltipPressable = (props) => {
    return (
      <Pressable {...props}>
      {
        ({ isHovered, isFocused, isPressed }) => {
          const pressableChild = props.children({isHovered, isFocused, isPressed});
          return (
            React.cloneElement(pressableChild, {onTextPress: props.onPress})
          )
        }
      }
      </Pressable>
    );
  }