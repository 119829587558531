import React from "react";

import {
  Center,
  VStack,
  Text,
  Button,
  Heading,
  Spacer,
  HStack,
  Icon,
  Spinner,
  Modal,
  IconButton,
  Tooltip,
  Link,
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { GridView, ResponsiveGridItem } from '../components/GridView';
import { ListNavigator } from '../components/ListNavigator';
import { comprasController } from "../controllers/ComprasController";
import { constsController } from "../controllers/ConstsController";
import SearchPanel from "./ViewComprasSearchPanel";
import { authController } from "../controllers/AuthController";
import StatusPopup from "./ViewComprasStatusPopup";
import ConfirmModal from "../components/ConfirmModal";
import PDFViewer from "../components/PDFViewer";
import MailSender from "../components/MailSender";
import { fornecedoresController } from "../controllers/FornecedoresController";
import { htmlTemplateMailBodySolicitacao, templateMailBodySolicitacao } from "./MailBodySolicitacao"
import { prefeituraController } from "../controllers/PrefeituraController";

const NAVIGATOR_ITENS_PER_PAGE = 15;

const ActionButton = (props) => {
  return (
    <Tooltip label={props.tooltip} openDelay={500} placement="top">
      <IconButton
        isDisabled={props.isDisabled}
        variant="ghost"
        size="sm"
        padding="1"
        onPress={() => {
          if (!props.isDisabled)
            props.onPress();
        }}
        _icon={{
          as: FontAwesome5,
          name: props.iconName,
          size: "4"
        }}
      />
    </Tooltip>
  );
}

const ActionButtons = (props) => {
  return (
    <Center padding="0">
      <HStack space="1">
        <ActionButton
          isDisabled={props.isDisabledVizualizar}
          onPress={props.onView}
          iconName="eye"
          tooltip="Visualizar"
        />
        <ActionButton
          isDisabled={props.isDisabledEditar}
          onPress={props.onEdit}
          iconName="edit"
          tooltip="Editar"
        />
        <ActionButton
          isDisabled={props.isDisabledImprimir}
          onPress={props.onPrint}
          iconName="print"
          tooltip="Imprimir"
        />
        <ActionButton
          isDisabled={props.isDisabledMail}
          onPress={props.onSendMail}
          iconName={props.iconNameMail}
          tooltip={props.tooltipMail}
        />
        <ActionButton
          isDisabled={props.isDisabledDelete}
          onPress={props.onDelete}
          iconName="trash-alt"
          tooltip="Excluir"
        />
      </HStack>
    </Center>
  );
}

class ViewCompras extends React.Component {

  constructor(props) {
    super(props);

    this.permicoes = JSON.parse(authController.getCurrentUser().perfil);

    this.state = {
      searching: false,
      waitMessage: '',
      currentPage: 1,
      countItens: 0,
      solicitacoes: [],
      statusViewSolicId: -1,
      solicitacaoForDeletion: 0,
      urlSource: null,
      mailRecipient: null,
      mailSolicitacaoId: 0,
      mailBody: '',
    }
    if (localStorage.getItem('page') !== 'compra') {
      localStorage.removeItem('filtro');
      localStorage.removeItem('currentPage');
      localStorage.setItem('page', 'compra');
    } else {
      if (localStorage.getItem('currentPage'))
        this.state.currentPage = localStorage.getItem('currentPage');
      else
        this.state.currentPage = 1;
    }
    this.currentFilter = {};
  }

  componentDidMount() {
    this.loadSolicitacoes();
  }

  loadSolicitacoes() {
    if (localStorage.getItem('page') === 'compra') {
      const filtroStr = localStorage.getItem('filtro');
      if (filtroStr) {
        const filtro = JSON.parse(filtroStr);
        this.#searchSolicitacoes(filtro);
      } else {
        this.#searchSolicitacoes({});
      }
    }
  }

  render() {
    return (
      <Center flex={1}>
        <VStack flex={1} width="100%" space={2}>
          <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
            <Heading color="white" >Compras e Serviços</Heading>
            <Spacer />
            <Button
              my="1"
              leftIcon={<Icon as={FontAwesome5} name="plus" size="4" />}
              onPress={() => { this.props.viewManager.showView('ViewCompra', { insertion: true }) }}>
              Adicionar
            </Button>
          </HStack>

          <SearchPanel
            onSearch={(options) => {
              this.state.currentPage = 1;
              this.#searchSolicitacoes(options);
            }}
            onDownload={this.#downloadSolicitacoes}
          />


          <ListNavigator
            key={'ListNavigator' + this.state.currentPage}
            page={this.state.currentPage}
            countItens={this.state.countItens}
            itensPerPage={NAVIGATOR_ITENS_PER_PAGE}
            onChangePage={(page) => {
              this.setState({ currentPage: page }, () => this.#searchSolicitacoes(this.currentFilter));
            }}
          />

          <GridView
            borderRadius="4"
            data={this.state.searching ? [] : this.state.solicitacoes}
            keyExtractor={(item) => { return item.id.toString(); }}
            columnDimensions={[0.05, 0.1, 0.1, 0.1, 0.1, 0.1, 0.15, 0.1, 0.1, 0.1]}
            columnCaptions={[
              "Número",
              "Status",
              "Localização",
              "Solicitante",
              "Destino",
              "Fornecedor",
              "Data de criação",
              "Modalidade",
              "Valor",
              ""
            ]}
            ListEmptyComponent={
              (
                this.state.searching &&
                <Spinner marginY={3} size="lg" />
              )
              ||
              (
                <Center>
                  <Text>Sem resultados</Text>
                </Center>
              )
            }
            renderGridItem={(item, index, separators, props) => {
              return (
                <ResponsiveGridItem flex="1"
                  tooltipVisibool={true}
                  values={[
                    item.id,
                    <Link
                      paddingLeft={2}
                      _text={{ isTruncated: true }}
                      onPress={() => { this.setState({ statusViewSolicId: item.id }) }}
                    >
                      {constsController.statusSolicitacao[item.status]}
                    </Link>,
                    item.localizacao,
                    item.solicitante,
                    item.destino,
                    item.fornecedor,
                    new Date(item.dataHora).toLocaleString(),
                    constsController.tiposSolicitacao[item.tipo],
                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(item.valor),
                    <ActionButtons
                      isDisabledVizualizar={!(this.permicoes.includes("SolicitacoesComprasPolicies.Visualizar"))}
                      isDisabledEditar={!(this.permicoes.includes("SolicitacoesComprasPolicies.Atualizar"))}
                      isDisabledImprimir={!(this.permicoes.includes("SolicitacoesComprasPolicies.ImprimirRelatorio"))}
                      isDisabledDelete={!(this.permicoes.includes("SolicitacoesComprasPolicies.Remover") && (item.status in [0]))}
                      isDisabledMail={item.status !== 1 /* aprovado */}
                      iconNameMail={item.mailSent? "check-circle" : "envelope"}
                      tooltipMail={item.mailSent? "E-mail enviado" :  item.status === 1? "Enviar e-mail" : "Envio de e-mail indisponível"}
                      onEdit={() => {
                        this.props.viewManager.showView('ViewCompra', {
                          idSolicitacao: item.id, viewOnly: !(item.status in [0] | (['Compras', 'Admin'].includes(authController.getCurrentUser().nomePerfil))),
                          canReceive: [1, 5, 9, 3, 10, 11].includes(item.status)
                        })
                      }}
                      onView={() => { this.props.viewManager.showView('ViewCompra', { idSolicitacao: item.id, viewOnly: true, canReceive: false }) }}
                      onPrint={() => { this.#downloadSolicitacao(item.id) }}
                      onDelete={() => this.setState({ solicitacaoForDeletion: item.id })}
                      onSendMail={() => this.#confirmMail(item.fornecedorId, item.id)}
                    />
                  ]}
                  backgroundColor={(index % 2 === 0) ? "coolGray.100" : null}
                  width="100%"
                  gridProps={props}
                  dimensions={[0.05, 0.1, 0.1, 0.1, 0.1, 0.1, 0.15, 0.1, 0.1, 0.1]}
                />
              )
            }}
          />
        </VStack>

        <Modal
          isOpen={this.state.waitMessage.length > 0}
          onClose={() => this.setState({ waitMessage: '' })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>{this.state.waitMessage}</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>

        <StatusPopup
          isOpen={this.state.statusViewSolicId > 0}
          onClose={() => { this.setState({ statusViewSolicId: -1 }) }}
          idSolicitacao={this.state.statusViewSolicId}
          key={this.state.statusViewSolicId.toString()}
        />

        <ConfirmModal
          isOpen={this.state.solicitacaoForDeletion > 0}
          onClose={() => this.setState({ solicitacaoForDeletion: 0 })}
          onConfirm={() => this.#deleteSolicitacao(this.state.solicitacaoForDeletion)}
          headerText="Excluir Solicitacao"
          bodyText="Atenção! a solicitacao será excluída. Confirma esta ação?"
          buttonConfirmText="Excluir"
        />

        <PDFViewer
          isOpen={!!this.state.urlSource}
          onClose={() => this.setState({ urlSource: null })}
          urlSource={this.state.urlSource}
        />

        <MailSender
          isOpen={!!this.state.mailRecipient}
          onClose={() => this.setState({ mailRecipient: null, mailSolicitacaoId: 0})}
          onConfirm={this.#sendMail}
          mailRecipient={this.state.mailRecipient}
          mailBody={this.state.mailBody}
          key={this.state.mailRecipient ?? 'mailRecipient'}
        />
      </Center>
    );
  }

  #searchSolicitacoes = async (options) => {
    this.setState({ searching: true })

    localStorage.setItem('filtro', JSON.stringify(options));
    localStorage.setItem('currentPage', this.state.currentPage);
    this.currentFilter = options;

    const solicitacoes = await comprasController.searchSolicitacoesCompras(options,
      this.state.currentPage, NAVIGATOR_ITENS_PER_PAGE);
    this.setState({
      searching: false,
      solicitacoes: solicitacoes.items,
      countItens: solicitacoes.totalCount,
    })
  }

  #downloadSolicitacoes = async (options) => {
    this.setState({ waitMessage: 'Gerando relatório. Aguarde...' });
    const pdf = await comprasController.downloadSolicitacoesCompras(options);
    this.setState({ waitMessage: '', urlSource: (pdf) ? URL.createObjectURL(pdf) : null })
  }

  #deleteSolicitacao = async (options) => {
    this.setState({ waitMessage: 'Excluindo solicitação. Aguarde...' })
    await comprasController.deleteSolicitacao(options);
    this.setState({ waitMessage: '' })
    this.setState({ solicitacaoForDeletion: 0 });
  }

  #downloadSolicitacao = async (id) => {
    this.setState({ waitMessage: 'Gerando solicitação. Aguarde...', solicitacaoForDeletion: 0 });
    const pdf = await comprasController.downloadSolicitacaoCompra(id)
    if (pdf)
      this.#searchSolicitacoes(this.currentFilter);
    this.setState({ waitMessage: '', urlSource: (pdf) ? URL.createObjectURL(pdf) : null });
  }

  #confirmMail = async (fornecedorId, solicitacaoId) => {
    const fornecedor = await fornecedoresController.getFornecedor(fornecedorId);
    const mailBodySolicitacao = templateMailBodySolicitacao
      .replace(":fornecedor_name:", fornecedor.razaoSocial)
      .replace(":nome_prefeitura:", (await prefeituraController.getPrefeitura()).nomePrefeitura);
    this.setState({
      mailRecipient: fornecedor?.eMail,
      mailSolicitacaoId: solicitacaoId,
      mailBody: mailBodySolicitacao
    });
  }

  #sendMail = async (mailRecipient, mailBody) => {
    const mailBodyLines = mailBody
      .replace("\r\n", "\n")
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
      .split('\n')
      .map(line => line.length > 0 ? '<p>' + line + '</p>' : '<br>')
      .join('');

    const htmlMailBody = htmlTemplateMailBodySolicitacao.replace(':body:', mailBodyLines);

    this.setState({ waitMessage: 'Enviando e-mail. Aguarde...', mailRecipient: null });
    await comprasController.sendMail({
      "solicitacaoId": this.state.mailSolicitacaoId,
      "to": mailRecipient,
      "body": htmlMailBody
    });
    this.setState({ waitMessage: '', mailSolicitacaoId: 0 });
    this.loadSolicitacoes();
  }
}

export default ViewCompras;