import PubSub from 'pubsub-js'

export const NOTICATION_ERROR = '_ERROR_';
export const NOTICATION_WARNING = '_WARNING_';

class NotificationController {

  subscribe(notification, func) {
    return PubSub.subscribe(notification, func);
  }

  unsubscribe(subToken) {
    PubSub.unsubscribe(subToken);
  }

  publish(notification, data) {
    PubSub.publish(notification, data);
  }
  
  publishError(message, detail) {
    PubSub.publish(NOTICATION_ERROR, {
      message: message,
      detail: detail
    });
  }

  onError(func) {
    return PubSub.subscribe(NOTICATION_ERROR, (topic, message) => {
      func(message);
    });
  }
}

export const notificationController = new NotificationController();
