import React from "react";
import {
  Center,
  VStack,
  Input,
  Button,
  Heading,
  Pressable,
  Spacer,
  HStack,
  FlatList,
  Icon,
  Spinner,
  Text
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { GridRow, GridView, ResponsiveGridItem } from './../components/GridView';
import TextSearchPanel from "../components/TextSearchPanel";
import { usuariosController } from "../controllers/UsuariosController";
import { authController } from "../controllers/AuthController";
import ConfirmModal from "../components/ConfirmModal";
import CommonActionButtons from "../components/CommonActionButtons";
import { TooltipPressable } from "../components/TooltipPressable";

class ViewUsuarios extends React.Component {

  constructor(props) {
    super(props);

    let permicoes=JSON.parse(authController.getCurrentUser().perfil);
    this.viewOnly=!(permicoes.includes("UsuariosPolicies.Criar")||permicoes.includes("UsuariosPolicies.Atualizar"));
    this.notRemover = !(permicoes.includes("UsuariosPolicies.Remover"));

    this.state = {
      searching: false,
      usuarioForDeletion: 0,
      usuarios: []
    }
    if(localStorage.getItem('page') !=='usuario'){
      localStorage.removeItem('filtro');
      localStorage.setItem('page','usuario');
    }
  }

  componentDidMount() {
    this.#loadUsuarios();
  }

  render() {
    return (
      <Center flex={1}>
        <VStack flex={1} width={'100%'} space={2}>
          <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
            <Heading color="white">Usuários</Heading>
            <Spacer />
            <Button
              my="1"
              leftIcon={<Icon as={FontAwesome5} name="plus" size="4" />}
              onPress={() => { this.props.viewManager.showView('ViewUsuario', { insertion: true }) }}>
              Adicionar
            </Button>
          </HStack>

          <TextSearchPanel
            placeholder="Buscar usuários"
            onSearch={this.#searchUsuarios}
          />

          <GridView
            borderRadius="4"
            data={this.state.searching? [] : this.state.usuarios}
            keyExtractor={(item) => { return item.id.toString(); }}
            columnCaptions={["Nome", "Login", "Perfil", ""]}
            columnDimensions={[0.3, 0.3, 0.25, 0.15]}
            ListEmptyComponent={
              (
                this.state.searching &&
                <Spinner marginY={3} size="lg"/>
              )
              ||
              (
                <Center>
                  <Text>Sem resultados</Text>
                </Center>
              )
            }
            renderGridItem={(item, index, separators, props) => {
              return (
                <TooltipPressable 
                  onPress={() => { this.props.viewManager.showView('ViewUsuario', { idUsuario: item.id }) }}>
                  {
                    ({ isHovered, isFocused, isPressed }) => {
                      return (
                        <ResponsiveGridItem flex="1"
                          tooltipVisibool={true}
                          values={[
                            item.nome,
                            item.login,
                            item.perfil,
                            <CommonActionButtons flex={0.1} 
                              isDisabled={this.notRemover}
                              onDelete={() => this.setState({usuarioForDeletion: item.id})}
                            />,
                          ]}
                          backgroundColor={isPressed ? "primary.200" : isHovered ? "primary.100" : (index % 2 === 0) ? "coolGray.100" : null}
                          width="100%"
                          gridProps={props}
                          dimensions={props.columnDimensions}
                        />
                      )
                    }
                  }
                </TooltipPressable>
              )
            }}
          />
        </VStack>
        <ConfirmModal
          isOpen={this.state.usuarioForDeletion > 0}
          onClose={() => this.setState({usuarioForDeletion: 0})}
          onConfirm={() => this.#deleteContrato(this.state.usuarioForDeletion)}
          headerText="Excluir usuário"
          bodyText="Atenção! O usuário será excluído. Confirma esta ação?"
          buttonConfirmText="Excluir"
        />
      </Center>
    );
  }

  #searchUsuarios = async (searchedText) => {
    this.setState({searching: true});
    localStorage.setItem('filtro', JSON.stringify(searchedText));
    const usuarios = await usuariosController.searchUsuarios(searchedText);
    this.setState({searching: false, usuarios: usuarios.filter(u => u.perfil.toLowerCase() !== 'simple' )});
  }

  #deleteContrato = async (id) => {
    this.setState({ usuarioForDeletion: 0 });
    this.setState({await: true});
    if (await usuariosController.deleteUsuario(id)) {
      this.#loadUsuarios();
    }
    this.setState({await: false});
  }

  #loadUsuarios = () => {
    if(localStorage.getItem('page') ==='usuario') {
      const filtroStr = localStorage.getItem('filtro');
      if(filtroStr){
        const filtro = JSON.parse(filtroStr);
        this.#searchUsuarios(filtro);
      }else 
        this.#searchUsuarios('');
    }
  }
}

export default ViewUsuarios;