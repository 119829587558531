import React from "react";

import {
  Center,
  Text,
  VStack,
  HStack,
  IconButton,
  Menu,
  Box,
  Button,
  Input,
  FormControl,
  Modal,
} from 'native-base';

import { authController } from "../controllers/AuthController";

class MainScreenChangePasswordDlg extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword: '',
      newPasswordCopy: '',
      changingPassword: false
    }
  }
  
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Alterar senha</Modal.Header>
          <Modal.Body>
            <VStack space={2}>
              <FormControl>
                <FormControl.Label marginBottom="0">Senha atual</FormControl.Label>
                <Input
                  type="password"
                  value={this.state.currentPassword}
                  onChangeText={(text)=> this.setState({currentPassword: text})}
                />
              </FormControl>
              <FormControl>
                <FormControl.Label marginBottom="0">Senha nova:</FormControl.Label>
                <Input
                  type="password"
                  value={this.state.newPassword}
                  onChangeText={(text)=> this.setState({newPassword: text})}
                />
              </FormControl>
              <FormControl>
                <FormControl.Label marginBottom="0">Repetir a senha nova:</FormControl.Label>
                <Input
                  type="password"
                  value={this.state.newPasswordCopy}
                  onChangeText={(text)=> this.setState({newPasswordCopy: text})}
                />
              </FormControl>
            </VStack>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button width={110} variant="ghost" colorScheme="blueGray" onPress={this.props.onClose}>
                Cancelar
              </Button>
              <Button width={110} 
                onPress={this.#handlePressed}
                isLoading={this.state.changingPassword}
                isLoadingText={this.state.changingPassword? 'Alterando...' : 'Alterar'}
                isDisabled={
                  this.state.currentPassword.length === 0 || this.state.newPassword.length === 0 ||
                  this.state.newPassword !== this.state.newPasswordCopy || this.state.changingPassword
                }
              >
                Alterar
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }

  #handlePressed = async () => {
    this.setState({changingPassword: true});
    await authController.changeUserPassword(this.state.currentPassword, this.state.newPassword);
    this.setState({changingPassword: false});
    this.props.onClose?.();
  }
}

export default MainScreenChangePasswordDlg;
