import React from "react";

import {
  Button,
  AlertDialog
} from 'native-base';


class ConfirmModal extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AlertDialog
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{this.props.headerText ?? 'Confirmação'}</AlertDialog.Header>
          <AlertDialog.Body>
            {this.props.bodyText}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button variant="unstyled" colorScheme="coolGray" onPress={this.props.onClose}>
                {this.props.buttonCancelText ?? 'Cancelar'}
              </Button>
              <Button colorScheme="danger" onPress={this.props.onConfirm}>
                {this.props.buttonConfirmText ?? 'OK'}
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    )
  }
}

export default ConfirmModal;