import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController';
import { notificationController } from "./NotificationController";

class UsuariosController {

  searchUsuarios = async (text) => {
    try {
      let query = ENDPOINT + '/api/Usuarios?limit=100';

      if (text?.length > 0) {
        query += '&q=' + text;
      }

      const response = await fetch(query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao pesquisar os usuários', await response.text());
        return [];
      }
    } catch (error) {
      notificationController.publishError('Falha ao pesquisar os usuários', error);
      return [];
    }
  }

  getUsuario = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Usuarios/' + id.toString(), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao carregar o usuário', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao carregar o usuário', error);
      return null;
    }
  }
  
  insertUsuario = async (usuario) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Usuarios', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(usuario)
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao inserir o usuário', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao inserir o usuário', error);
      return null;
    }
  }
  
  updateUsuario = async (usuario, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Usuarios/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(usuario)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar o usuário', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar o usuário', error);
      return false;
    }
  }

  updateSenhaUsuario = async (login, novaSenha) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Usuarios/mudar-senha', {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify({
          novaSenha: novaSenha,
          login: login
        })
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar a senha do usuário', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar a senha do usuário', error);
      return false;
    }
  }
  
  deleteUsuario = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Usuarios/' + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao excluir o usuário', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao excluir o usuário', error);
      return false;
    }
  }
}

export const usuariosController = new UsuariosController();
