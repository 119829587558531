export const htmlTemplateMailBodySolicitacao =
`<!DOCTYPE html>
<html lang="pt">

<head>
    <meta charset="utf-8">

    <style type="text/css">
        body {
            font-family: Arial, Helvetica, sans-serif;
        }
    </style>
</head>



<body>
  :body:
</body>
</html>`;

export const templateMailBodySolicitacao =
`Olá :fornecedor_name:

Segue anexo a solicitação de compras da :nome_prefeitura:

Simples Compras Agradece!
`;