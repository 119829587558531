import React from 'react';
import {
  Input
} from 'native-base';

class CurrencyInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ?? 0
    }
  }

  render() {
    return (
      <Input
        {...this.props}
        value={
          new Intl.NumberFormat(
            this.props.locale ?? 'pt-BR',
            {
              style: 'currency',
              currency: this.props.currency ?? 'BRL',
              minimumFractionDigits: 2,
            }
          ).format(this.state.value)
        }
        onChangeText={this.#handleTextChange}
      />
    );
  }

  #handleTextChange = (text) => {
    const numArray = text.match(/\d/g);
    if (!numArray || numArray.length === 0)
      return;

    text = numArray.join('');

    if (text.length === 0) {
      text = '000';
    }
    else if (text.length === 1) {
      text = '00' + text;
    }
    else if (text.length === 2) {
      text = '0' + text;
    }

    text = text.substring(0, text.length - 2) + '.' + text.substring(text.length - 2);
    let newValue = parseFloat(text);
    if (isNaN(newValue)) {
      newValue = this.props.defaultValue ?? 0
    }

    if (this.state.value !== newValue) {
      this.props.onChangeValue?.(newValue);
    }

    this.setState({ value: newValue });
  }
}

export default CurrencyInput;