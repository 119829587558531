import React from "react";
import {
  Input,
  Button,
  HStack,
  Icon,
  Box,
  IconButton
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

export default class TextSearchPanel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      searchedText: '' 
    }

    if (!this.props.disablePersistence) {
      const filtroStr = localStorage.getItem('filtro');
      if(filtroStr){
        const options = JSON.parse(filtroStr);
        if (options) {
          this.state.searchedText=options;
        }
      }
    }

    this.textInput = React.createRef();
  }

  render() {
    return (
      <Box {...this.props}>
        <HStack flex={1} space={2}>
          <Input
            flex="auto"
            placeholder={this.props.placeholder}
            value={this.state.searchedText}
            onChangeText={(text) => this.setState({ searchedText: text })}
            onSubmitEditing={({ text }) => this.#handleSearch(text)}
            ref={this.textInput}
            InputLeftElement={
              <IconButton
                marginLeft={1}
                variant="ghost"
                padding="1"
                onPress={this.#handleSearch}
                _icon={{
                  as: FontAwesome5,
                  name: "search",
                  size: "5"
                }}
              />
            }
          />
        </HStack>
      </Box>
    );
  }

  #handleSearch = async () => {
    await this.props.onSearch?.(this.state.searchedText);
    this.textInput.current.focus();
  }
}