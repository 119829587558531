import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController';
import { notificationController } from "./NotificationController";


import dayjs from 'dayjs';
import customParseFormat  from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

class ComprasController {
  getSolicitacaoCompra = async (id, includeSaldoRecebimento) => {
    try {
      const getSaldoRecebimento = includeSaldoRecebimento ? 'true' : 'false';
      const response = await fetch(ENDPOINT + '/api/SolicitacoesCompras/' + id 
      + '?includeItens=true&includeHistoricoStatus=true&includeSaldoRecebimento=' + getSaldoRecebimento, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao carregar a solicitação', await response.text());
        return [];
      }
    } catch (error) {
      notificationController.publishError('Falha ao carregar a solicitação', error);
      return [];
    }
  }

  insertSolicitacaoCompra = async (solicitacaoCompra) => {
    try {
      const response = await fetch(ENDPOINT + '/api/SolicitacoesCompras', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(solicitacaoCompra)
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao inserir a solicitação', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao inserir a solicitação', error);
      return null;
    }
  }
  
  updateSolicitacaoCompra = async (solicitacaoCompra, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/SolicitacoesCompras/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(solicitacaoCompra)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar a solicitação', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar a solicitação', error);
      return false;
    }
  }

  updateStatusSolicitacaoCompra = async (infoStatus, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/SolicitacoesCompras/' + id + '/AtualizarStatus', {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(infoStatus)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar o status da solicitação', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar o status da solicitação', error);
      return false;
    }
  }

  searchSolicitacoesCompras = async (options, page, itensPerPage) => {
    const skip = (page - 1) * itensPerPage;
    const filter = 'countTotal=true&skip=' + skip.toString() + '&limit=' + itensPerPage.toString() + this.#createFilter(options);
    try {
      const response = await fetch(ENDPOINT + '/api/SolicitacoesCompras?' + filter, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao pesquisar solicitações', await response.text());
        return [];
      }
    } catch (error) {
      notificationController.publishError('Falha ao pesquisar solicitações', error);
      return [];
    }
  }

  downloadSolicitacoesCompras = async (options) => {
    try {
      let filter = this.#createFilter(options);
      if (filter.length > 0) {
        filter = '?' + filter.substring(1);
      }
      const response = await fetch(ENDPOINT + '/api/SolicitacoesCompras/gerar-relatorio-pdf' + filter, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return await response.blob();
      } else {
        notificationController.publishError('Falha ao baixar relatório de solicitações', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao baixar relatório de solicitações', error);
      return null;
    }
  }

  downloadSolicitacaoCompra = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/SolicitacoesCompras/gerar-relatorio-solicitacao-pdf?solicitacaoId=' + id.toString(), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return await response.blob();
      } else {
        notificationController.publishError('Falha ao baixar a solicitação', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao baixar a solicitação', error);
      return null;
    }
  }

  #createFilter = (options) => {
    let filter = '';
    if (options) {
      if (options.searchedText?.length > 0) {
        filter += '&q=' + options.searchedText;
      }

      if (options.solicitacaoId > 0) {
        filter += '&solicitacaoId=' + options.solicitacaoId;
      }

      if (options.fornecedorId > 0) {
        filter += '&fornecedorId=' + options.fornecedorId;
      }

      if (options.fundoId > 0) {
        filter += '&fundoId=' + options.fundoId;
      }

      if (options.startDate?.length > 0) {
        filter += '&dataInicial=' + options.startDate;
      }

      if (options.finalDate?.length > 0) {
        filter += '&dataFinal=' + options.finalDate;
      }
      
      if (options.status > -1) {
        filter += '&status=' + options.status;
      }

      if (options.tipoItensSolicitacao > -1) {
        filter += '&tipoItensSolicitacao=' + options.tipoItensSolicitacao;
      }

      if (options.valor > 0) {
        filter += '&valor=' + options.valor;
      }

      if (options.tipoSolicitacao > -1) {
        filter += '&tipoSolicitacao=' + options.tipoSolicitacao;
      }
      
    }
    return filter;
  }

  deleteSolicitacao = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/SolicitacoesCompras/' + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao remover o solicitação', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao remover o solicitação', error);
      return false;
    }
  }
  
  sendMail = async (requestData) => {
    try {
      const response = await fetch(ENDPOINT + '/api/SolicitacoesCompras/send-email-solicitacao', {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(requestData)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao enviar o e-mail', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao enviar o e-mail', error);
      return false;
    }
  }
}

export const comprasController = new ComprasController();
