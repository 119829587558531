import React from "react";
import {
  Popover,
  Box,
  Button,
  IconButton,
  FlatList,
  HStack,
  VStack,
  Text,
  Icon,
  Divider,
  Center,
  Pressable,
  Badge,
  ScrollView 
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { mensagensController } from "../controllers/MensagensController";

import dayjs from 'dayjs';
import customParseFormat  from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

class MainsScreenNotificationPanel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      unreadCount: 0,
      mensagens: ["asdf", "asdf"]
    }
  }

  #fetchMensagens = async () => {
    const mensagens = await mensagensController.searchMensagens('');
    let unreadCount = 0;
    mensagens.forEach(item => {
      if (!item.lida) {
        ++unreadCount;
      }
    });
    this.setState({
      mensagens: mensagens,
      unreadCount: unreadCount
    });
  }

  componentDidMount() {
    this.#fetchMensagens();
    setInterval(this.#fetchMensagens, 30000);
  }

  render() {
    return (
      <Popover
        trigger={(_props) =>
          <IconButton variant="ghost"
            _icon={{
              as: FontAwesome5,
              name: "bell",
              color: "white",
              size: "sm"
            }}
            {..._props}
          >
          {
            this.state.unreadCount > 0 &&
            <Box
              width={3}
              height={3}
              position="absolute"
              rounded="full"
              right={2}
              backgroundColor="primary.500"
              zIndex={1}
            />
          }
          </IconButton>
        }
        {...this.props}
      >
        <Popover.Content>
          <Popover.Arrow />
          <Popover.Header>Notificações</Popover.Header>
          <Popover.Body >
            <ScrollView
              height={400}
            >
              <FlatList
                data={this.state.mensagens}
                keyExtractor={(item, index) => { return index.toString(); }}
                renderItem={({ item, index, separators }) => {
                  return (
                    <Pressable
                      onPress={async () => {
                        await mensagensController.updateMensagemLidaStatus(!item.lida, item.id);
                        await this.#fetchMensagens();
                      }}>
                      {
                        ({ isHovered, isFocused, isPressed }) => {
                          return (
                            <Box backgroundColor={(isHovered)? "primary.100": undefined}>
                              <HStack space={3} margin={2} >
                                <Center>
                                  <Icon
                                    as={FontAwesome5}
                                    name={(item.lida)? "envelope-open" : "envelope"}
                                    size="md"
                                  />
                                </Center>
                                <VStack flex="auto">
                                  <Text bold={true}>{item.titulo}</Text>
                                  <Text>{item.conteudo}</Text>
                                </VStack>
                                <Text>{new Date(item.dataHora).toLocaleString()}</Text>
                              </HStack>
                            </Box>
                          )
                        }
                      }
                    </Pressable>
                  );
                }}
              />
            </ScrollView>
          </Popover.Body>
        </Popover.Content>
      </Popover>
    );
  }
}

export default MainsScreenNotificationPanel;
