import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Spacer,
  HStack,
  FormControl,
  Modal,
  Icon,
  Select,
  Spinner,
  Divider,
  Box
} from 'native-base';

import { contratosController } from "../controllers/ContratosController";
import { fornecedoresController } from "../controllers/FornecedoresController";
import { fundosController } from "../controllers/FundosController";
import { constsController } from "../controllers/ConstsController";
import MaskedInput from "../components/MaskedInput";
import SearchInput from "../components/SearchInput";
import MultipleInput from "../components/MultipleInput";
import { ResponsiveStack, ResponsiveFormControl } from "../components/ResponsiveUtils";

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { licitacoesController } from "../controllers/LicitacoesController";
import CurrencyInput from "../components/CurrencyInput";
import { authController } from "../controllers/AuthController";
import ViewContratoEmpenhoList from "./ViewContratoEmpenhoList";
dayjs.extend(customParseFormat);

class ViewContrato extends React.Component {

  constructor(props) {
    super(props);
    this.creatingNewContrato = !!this.props.params?.insertion;

    let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.viewOnly = !(permicoes.includes("ContratosPolicies.Criar") || permicoes.includes("ContratosPolicies.Atualizar"));

    this.state = {
      await: false,
      id: 0,
      descricao: '',
      vencimento: '',
      valor: 0.0,
      numero: '',
      dataHomologacao: '',
      dataEmpenho: '',
      ataSRP: '',
      objeto: '',
      fundos: [],
      modalidade: 0,
      licitacaoId: 0,
      licitacaoDescricao: '',
      saldo: 0.0,
      saldoOriginal: 0.0,
      invalidInputs: {
        descricao: false,
        vencimento: false,
        valor: false,
        numero: false,
        dataHomologacao: false,
        dataEmpenho: false,
        ataSRP: false,
        objeto: false,
        fundos: false,
        modalidade: false,
        licitacao: false,
        saldo: false,
      }
    }
  }

  componentDidMount() {
    this.loadContrato();
  }

  async loadContrato() {
    if (!this.creatingNewContrato) {
      const contrato = await contratosController.getContrato(this.props.params.idContrato);
      this.setState({
        id: contrato.id,
        descricao: contrato.descricao,
        vencimento: dayjs(contrato.vencimento).format('DD/MM/YYYY'),
        valor: contrato.valor,
        numero: contrato.numero,
        dataHomologacao: dayjs(contrato.dataHomologacao).format('DD/MM/YYYY'),
        dataEmpenho: dayjs(contrato.dataEmpenho).format('DD/MM/YYYY'),
        ataSRP: contrato.ataSRP,
        objeto: contrato.objeto,
        fundos: contrato.fundos,
        modalidade: contrato.modalidade,
        licitacaoId: contrato.licitacaoId,
        licitacaoDescricao: contrato.licitacao + ' [' + contrato.fornecedor + ']',
        saldo: contrato.saldo,
        saldoOriginal: contrato.saldo,
      });
    }
  }

  render() {
    if (!this.creatingNewContrato && this.state.id === 0) {
      return (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      );
    }


    return (
      <VStack flex={1} space={2}>
        <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
          <Heading color="white">
            {
              this.creatingNewContrato ? 'Cadastrar novo Contrato' :
                this.viewOnly ? 'Visualizar Contrato' : 'Alterar Contrato'
            }
          </Heading>
          <Spacer />
          <Button
            my={1}
            leftIcon={<Icon as={FontAwesome5} name="arrow-left" size="4" />}
            onPress={() => { this.props.viewManager.showView('ViewContratos'); }}>
            Voltar
          </Button>
        </HStack>
        <VStack space={2}>
          <ResponsiveStack space="2">
            <ResponsiveFormControl flex={0.50} isInvalid={this.state.invalidInputs.descricao} isDisabled={this.viewOnly}>
              <FormControl.Label marginBottom="0">Nome</FormControl.Label>
              <Input defaultValue={this.state.descricao} onChangeText={(text) => this.setState({ descricao: text })} />
              <FormControl.ErrorMessage marginTop="0">Informe o nome do contrato</FormControl.ErrorMessage>
            </ResponsiveFormControl>

            <ResponsiveFormControl flex={0.1} isInvalid={this.state.invalidInputs.numero} isDisabled={this.viewOnly}>
              <FormControl.Label marginBottom="0">Número</FormControl.Label>
              <Input defaultValue={this.state.numero} onChangeText={(text) => this.setState({ numero: text })} />
              <FormControl.ErrorMessage marginTop="0">Informe o número do contrato</FormControl.ErrorMessage>
            </ResponsiveFormControl>

            <ResponsiveFormControl flex={0.1} isInvalid={this.state.invalidInputs.valor} isDisabled={this.viewOnly}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Valor</FormControl.Label>
              <CurrencyInput
                value={this.state.valor}
                onChangeValue={(newValue) => { this.setState({ valor: newValue }) }}
                key={"valor" + this.state.id.toString()}
              />
              <FormControl.ErrorMessage marginTop="0">Informe valor maior que zero</FormControl.ErrorMessage>
            </ResponsiveFormControl>

            <ResponsiveFormControl flex={0.1} isInvalid={this.state.invalidInputs.saldo} isDisabled={this.viewOnly}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Saldo</FormControl.Label>
              <CurrencyInput
                value={this.state.saldo}
                onChangeValue={(newValue) => { this.setState({ saldo: newValue }) }}
                key={"saldo" + this.state.id.toString()}
              />
              <FormControl.ErrorMessage marginTop="0">Informe saldo &gt;= 0</FormControl.ErrorMessage>
            </ResponsiveFormControl>

            <ResponsiveFormControl flex={0.1} isInvalid={this.state.invalidInputs.vencimento} isDisabled={this.viewOnly}>
              <FormControl.Label marginBottom="0">Vencimento</FormControl.Label>
              <MaskedInput
                mask="99/99/9999"
                placeholder="dd/mm/aaaa"
                value={this.state.vencimento}
                onChangeMaskedText={(text) => this.setState({ vencimento: text })}
              />
              <FormControl.ErrorMessage marginTop="0">Informe a data de vencimento</FormControl.ErrorMessage>
            </ResponsiveFormControl>
          </ResponsiveStack>

          <SearchInput
            isDisabled={this.viewOnly}
            label="Licitação"
            popOverWidth={550}
            popOverHeight={300}
            defaultValue={this.state.licitacaoDescricao}
            onSearchItems={this.#searchLicitacoes}
            onItemSelect={(item) => this.setState({ licitacaoId: item.id, licitacaoDescricao: item.nome })}
            placeholder="Pesquisar licitações"
            isInvalid={this.state.invalidInputs.licitacao}
            errorMessage="Informe a licitação"
            key={'licitacao' + this.state.licitacaoId}
          />

          <ResponsiveStack space="2" flex={1}>
            <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.modalidade} isDisabled={this.viewOnly}>
              <FormControl.Label marginBottom="0">Modalidade</FormControl.Label>
              <Select
                defaultValue={this.state.modalidade?.toString() ?? ''}
                onValueChange={(selectedItem) => this.setState({ modalidade: parseInt(selectedItem) })}
                placeholder="Escolher modalidade"
                key={'modalidade' + this.state.modalidade}
              >
                {
                  Object.entries(constsController.modalidadesContrato).map(([id, desc]) => {
                    return <Select.Item label={desc} value={id} key={id} />;
                  })
                }
              </Select>
              <FormControl.ErrorMessage marginTop="0">Selecione a modalidade</FormControl.ErrorMessage>
            </ResponsiveFormControl>

            <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.ataSRP} isDisabled={this.viewOnly}>
              <FormControl.Label marginBottom="0">Tipo de Contrato</FormControl.Label>
              <Input defaultValue={this.state.ataSRP} onChangeText={(text) => this.setState({ ataSRP: text })} />
              <FormControl.ErrorMessage marginTop="0">Informe a Tipo de Contrato</FormControl.ErrorMessage>
            </ResponsiveFormControl>

            <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.objeto} isDisabled={this.viewOnly}>
              <FormControl.Label marginBottom="0">Objeto</FormControl.Label>
              <Input defaultValue={this.state.objeto} onChangeText={(text) => this.setState({ objeto: text })} />
              <FormControl.ErrorMessage marginTop="0">Informe o objeto</FormControl.ErrorMessage>
            </ResponsiveFormControl>

            <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.dataEmpenho} isDisabled={this.viewOnly}>
              <FormControl.Label marginBottom="0">Data de empenho</FormControl.Label>
              <MaskedInput
                mask="99/99/9999"
                value={this.state.dataEmpenho}
                placeholder="dd/mm/aaaa"
                onChangeMaskedText={(text) => this.setState({ dataEmpenho: text })}
              />
              <FormControl.ErrorMessage marginTop="0">Informe a data de empenho</FormControl.ErrorMessage>
            </ResponsiveFormControl>

            <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.dataHomologacao} isDisabled={this.viewOnly}>
              <FormControl.Label marginBottom="0">Data de homologação</FormControl.Label>
              <MaskedInput
                mask="99/99/9999"
                placeholder="dd/mm/aaaa"
                value={this.state.dataHomologacao}
                onChangeMaskedText={(text) => this.setState({ dataHomologacao: text })}
              />
              <FormControl.ErrorMessage marginTop="0">Informe a data de homologação</FormControl.ErrorMessage>
            </ResponsiveFormControl>
          </ResponsiveStack>

          <MultipleInput
            isDisabled={this.viewOnly}
            isInvalid={this.state.invalidInputs.fundos}
            errorMessage="Informe no mínimo um fundo"
            items={this.state.fundos}
            title="Fundos"
            onSearchItems={fundosController.searchFundos}
            onItemSelect={(item) => {
              if (!this.state.fundos.find((f => f.id === item.id))) {
                const novosFundos = this.state.fundos.slice();
                novosFundos.push(item);
                this.setState({ fundos: novosFundos });
              }
            }}
            onDelete={(item) => {
              const novosFundos = this.state.fundos.filter((f) => f.id !== item.id);
              this.setState({ fundos: novosFundos });
            }}
            key={'fundo' + this.state.fundos.length}
          />
        </VStack>

        <HStack space="2">
          <Spacer />
          <Button
            isDisabled={!!this.state.editingProduto || this.viewOnly}
            leftIcon={<Icon as={FontAwesome5} name="check" size="4" />}
            onPress={this.#onSave}>
            {this.creatingNewContrato ? 'Cadastrar' : 'Alterar'}
          </Button>
        </HStack>

        <Divider marginY={2}/>

        {
          !this.creatingNewContrato && this.state.id &&
          <VStack space={1}>
            <HStack space={2}>
              <Box flexDirection="row" alignItems="center">
                <Heading size="md">Empenhos</Heading>
              </Box>
              <Spacer />
              <Button
                variant="ghost"
                leftIcon={<Icon as={FontAwesome5} name="plus" size="4" />}
                onPress={this.#handleEmpenhoAdd}
                isDisabled={this.viewOnly}
              >
                Incluir novo empenho
              </Button>
            </HStack>
            <ViewContratoEmpenhoList
              idContrato={this.state.id}
              onEdit={this.#handleEmpenhoEdit}
            />
          </VStack>
        }

        <Modal
          isOpen={this.state.await}
          onClose={() => this.setState({ await: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      </VStack>
    );
  }

  #searchLicitacoes = async (text) => {
    const licitacoes = await licitacoesController.searchLicitacoes({searchedText: text});
    return licitacoes.items;
  }

  #onSave = async () => {
    const invalidInputs = {
      descricao: this.state.descricao.length === 0,
      vencimento: !dayjs(this.state.vencimento, 'DD/MM/YYYY', true).isValid(),
      valor: this.state.valor === 0,
      numero: this.state.numero.length === 0,
      fornecedor: this.state.fornecedorId === 0,
      fundos: this.state.fundos.length === 0,
      licitacao: this.state.licitacaoId === 0,
      saldo: this.state.saldo < 0,
    }

    let allInputIsValid = true;
    for (let key in invalidInputs) {
      if (invalidInputs[key]) {
        allInputIsValid = false;
        break;
      }
    }

    if (allInputIsValid) {
      const contrato = {
        "descricao": this.state.descricao,
        "valor": this.state.valor,
        "vencimento": dayjs(this.state.vencimento, 'DD/MM/YYYY').toJSON(),
        "modalidade": this.state.modalidade,
        "numero": this.state.numero,
        "dataHomologacao": dayjs(this.state.dataHomologacao, 'DD/MM/YYYY').toJSON(),
        "dataEmpenho": dayjs(this.state.dataEmpenho, 'DD/MM/YYYY').toJSON(),
        "ataSRP": this.state.ataSRP,
        "objeto": this.state.objeto,
        "fundoIds": this.state.fundos.map((fundo) => fundo.id),
        "licitacaoId": this.state.licitacaoId
      };

      let success = false;
      this.setState({ await: true });
      if (this.creatingNewContrato) {
        success = await contratosController.insertContrato(contrato);
      } else {
        success = await contratosController.updateContrato(contrato, this.props.params.idContrato);
        if (success && this.state.saldo !== this.state.saldoOriginal) {
          success = await contratosController.updateSaldoContrato(this.state.saldo, this.props.params.idContrato);
        }
      }
      this.setState({ await: false });

      if (success)
        this.props.viewManager.showView('ViewContratos');
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }

  #handleEmpenhoAdd = () => {
    this.props.viewManager.showView('ViewContratoEmpenho', {insertion: true, idContrato: this.state.id});
  }

  #handleEmpenhoEdit = (idEmpenho) => {
    this.props.viewManager.showView('ViewContratoEmpenho', {idContrato: this.state.id, idEmpenho: idEmpenho});
  }
}

export default ViewContrato;