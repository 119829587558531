import React from "react";

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Pressable,
  Spacer,
  HStack,
  FlatList,
  Icon,
  Spinner
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { GridRow, GridView, ResponsiveGridItem } from '../components/GridView';
import { ListNavigator } from '../components/ListNavigator';
import { empenhosController } from "../controllers/EmpenhosController";
import TextSearchPanel from "../components/TextSearchPanel";
import { authController } from "../controllers/AuthController";
import ConfirmModal from "../components/ConfirmModal";
import CommonActionButtons from "../components/CommonActionButtons";
import { TooltipPressable } from "../components/TooltipPressable";

const NAVIGATOR_ITENS_PER_PAGE = 15;

class ViewContratoEmpenhoList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      empenhoForDeletion: 0,
      currentPage: 1,
      countItens: 0,
      empenhos: []
    }
  }

  componentDidMount() {
    this.#loadEmpenhos();
  }

  render() {
    return (
      <Center flex={1}>
        <VStack flex={1} width={'100%'} space={2}>
          <GridView
            borderRadius="4"
            data={this.state.empenhos}
            keyExtractor={(item) => { return item.id.toString(); }}
            columnCaptions={[
              "Descrição",
              "Usuário",
              "Valor",
              "Data",
              ""
            ]}
            ListEmptyComponent={
              <Center>
                <Text>Sem empenhos</Text>
              </Center>
            }
            renderGridItem={(item, index, separators, props) => {
              return (
                <TooltipPressable
                  onPress={() => { this.props.onEdit?.(item.id) }}>
                  {
                    ({ isHovered, isFocused, isPressed }) => {
                      return (
                        <ResponsiveGridItem flex="1"
                          tooltipVisibool={true}
                          values={[
                            item.descricao,
                            item.usuario,
                            new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(item.valor),
                            new Date(item.data).toLocaleDateString(),
                            <CommonActionButtons flex={0.1}
                              isDisabled={this.notRemover}
                              onDelete={() => this.setState({ empenhoForDeletion: item.id })}
                            />,
                          ]}
                          backgroundColor={isPressed ? "primary.200" : isHovered ? "primary.100" : (index % 2 === 0) ? "coolGray.100" : null}
                          gridProps={props}
                          width="100%"
                        />
                      )
                    }
                  }
                </TooltipPressable>
              )
            }}
          />

          {
            this.state.countItens > NAVIGATOR_ITENS_PER_PAGE &&
            <ListNavigator
              key={'ListNavigator' + this.state.currentPage}
              page={this.state.currentPage}
              countItens={this.state.countItens}
              itensPerPage={NAVIGATOR_ITENS_PER_PAGE}
              onChangePage={(page) => {
                this.setState({ currentPage: page }, () => this.#loadEmpenhos());
              }}
            />
          }

        </VStack>
        <ConfirmModal
          isOpen={this.state.empenhoForDeletion > 0}
          onClose={() => this.setState({ empenhoForDeletion: 0 })}
          onConfirm={() => this.#deleteEmpenho(this.state.empenhoForDeletion)}
          headerText="Excluir empenho"
          bodyText="Atenção! O empenho será excluído. Confirma esta ação?"
          buttonConfirmText="Excluir"
        />
      </Center>
    );
  }

  #searchEmpenhos = async (searchedText) => {
    const empenhos = await empenhosController.searchEmpenhos({
      text: searchedText,
      idContrato: this.props.idContrato,
      page: this.state.currentPage,
      itensPerPage: NAVIGATOR_ITENS_PER_PAGE
    });
    this.setState({ empenhos: empenhos.items, countItens: empenhos.totalCount });
  }

  #deleteEmpenho = async (id) => {
    this.setState({ empenhoForDeletion: 0 });
    if (await empenhosController.deleteEmpenho(id)) {
      this.#loadEmpenhos();
    }
  }

  #loadEmpenhos = () => {
    this.#searchEmpenhos('');
  }
}

export default ViewContratoEmpenhoList;
