import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';
import { Dimensions } from 'react-native';

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Spacer,
  HStack,
  FormControl,
  Box,
  Icon,
  Spinner,
  Modal,
} from 'native-base';

import LicitacaoProdutoEditor from "./ViewLicitacaoProdutoEditor";
import LicitacaoProdutoList from "./ViewLicitacaoProdutoList";
import ConfirmModal from "../components/ConfirmModal";

class LicitacaoLoteEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: props.lote?.id ?? -1,
      descricao: props.lote?.descricao ?? '',
      itens: props.lote?.itens ?? [],
      editingProduto: null,
      invalidInputs: {
        descricao: false,
        itens: false
      },
      itensPage: 1
    }

    this.produtoIdGen = -1;
  }

  render() {
    if (!this.props.lote)
      return null;

    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        size="full"
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{((this.props.lote.id > 0) ? 'Editar' : 'Adicionar') + ' lote'}</Modal.Header>
          <Modal.Body >
            <VStack space={1}>
              <FormControl isInvalid={this.state.invalidInputs.descricao}>
                <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Descrição</FormControl.Label>
                <Input
                  value={this.state.descricao}
                  onChangeText={(text) => { this.setState({ descricao: text }) }}
                />
                <FormControl.ErrorMessage marginTop="0">Informe a descrição do lote</FormControl.ErrorMessage>
              </FormControl>

              <HStack space="2">
                <Box flexDirection="row" alignItems="end">
                  <Heading size="md">Produtos</Heading>
                </Box>

                <Spacer />

                <Button
                  variant="ghost"
                  leftIcon={<Icon as={FontAwesome5} name="plus" size="4" />}
                  onPress={this.#handleProdutoAdd}
                  isDisabled={this.viewOnly}
                >
                  Incluir novo produto
                </Button>
              </HStack>

              <FormControl isInvalid={this.state.invalidInputs.itens}>
                <LicitacaoProdutoList
                  produtos={this.state.itens}
                  onProdutoDelete={this.#handleProdutoDelete}
                  onProdutoEdit={(produto) => this.setState({ editingProduto: produto })}
                  page={this.state.itensPage}
                  onChangePage={(page) => {
                    this.setState({itensPage: page});
                  }}
                />
                <FormControl.ErrorMessage marginTop="0">Informe no mínimo um produto</FormControl.ErrorMessage>
              </FormControl>

              <LicitacaoProdutoEditor
                isOpen={!!this.state.editingProduto}
                produto={this.state.editingProduto}
                onClose={() => this.setState({ editingProduto: null })}
                onSave={this.#handleProdutoSave}
                key={"LicitacaoEditorProduto" + this.state.editingProduto?.id}
              />
            </VStack>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => this.props.onClose?.()}
              >
                Cancelar
              </Button>
              <Button onPress={this.#handleLoteSave}
              >
                Salvar
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }

  #handleProdutoAdd = () => {
    const newProduto = {
      id: this.produtoIdGen--,
      codigo: '',
      marca: '',
      tipo: 0,
      descricao: '',
      unidade: '',
      quantidadeLimite: 0,
      valorUnitario: 0,
      saldo: 0,
      saldoOriginal: 0,
    }
    
    this.setState({
      editingProduto: newProduto,
      itensPage: this.state.itens.length + 1
    });
  }

  #handleProdutoDelete = (produto) => {
    const newItens = this.state.itens.filter(p => p.id !== produto.id);
    this.setState({
      itens: newItens,
    });
  }

  #handleProdutoSave = (produto) => {
    const existingProduto = this.state.itens.find((p) => p.id === produto.id);
    if (existingProduto) {
      Object.assign(existingProduto, produto);
      this.setState({
        itens: this.state.itens.slice(),
        editingProduto: null,
      });
    } else {
      const newItens = this.state.itens.slice();
      newItens.push(produto);
      this.setState({
        itens: newItens,
        editingProduto: null,
      });
    }
  }

  #handleLoteSave = () => {
    const invalidInputs = {
      descricao: this.state.descricao.length === 0,
      itens: this.state.itens.length === 0,
    }

    let allInputIsValid = true;
    for (let key in invalidInputs) {
      if (invalidInputs[key]) {
        allInputIsValid = false;
        break;
      }
    }

    if (allInputIsValid) {
      this.props.onSave?.(this.state);
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }
}

export default LicitacaoLoteEditor;