import React from "react";
import {
  Center,
  Text,
  VStack,
  HStack,
  IconButton,
  Menu,
  Box,
  Button,
  Popover,
  Icon,
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import MainsScreenNotificationPanel from "./MainScreenNotificationPanel";
import { authController } from "../controllers/AuthController";
import { ResponsiveComponentSelector } from "../components/ResponsiveUtils";

const PanelIconButton = (props) => {
  return (
    <Center>
      <IconButton variant="ghost"
        _icon={{
          as: FontAwesome5,
          name: props.name,
          color: "white",
          size: "sm"
        }}
        {...props}
      />
    </Center>
  );
}

class MainsScreenUserPanel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userOptionsOpen: false
    }
  }

  render() {
    return (
      <HStack space={2} >
        <HStack space={2}>
          <PanelIconButton onPress={this.#handlePressed.bind(this, 'message')} name="comment-alt" />
          <Center >
            <MainsScreenNotificationPanel />
          </Center>
          <PanelIconButton onPress={this.#handlePressed.bind(this, 'help')} name="question-circle" />
        </HStack>
        <ResponsiveComponentSelector
          portraitComponent={null}
          landscapeComponent={
            <VStack justifyContent="center">
              <Text textAlign="right" color="white" bold="true">{authController.getCurrentUser().nome}</Text>
              <Text textAlign="right" color="white">{authController.getCurrentUser().descricaoPerfil}</Text>
            </VStack>
          }
        />
        
        <Center>
          <Popover
            isOpen={this.state.userOptionsOpen}
            onClose={() => {
              this.setState({userOptionsOpen: false})
            }}
            trigger={triggerProps => {
              return (
                <IconButton size="md" variant="solid" backgroundColor="primary.800" alignSelf="center"
                  _hover={{
                    backgroundColor: "primary.600"
                  }}
                  _pressed={{
                    backgroundColor: "primary.700"
                  }}
                  _icon={{
                    as: FontAwesome5,
                    name: "user",
                    color: "white"
                  }}
                  {...triggerProps}
                  onPress={() => this.setState({userOptionsOpen: !this.state.userOptionsOpen})}
                />
              );
            }}
          >
            <Popover.Content width={200}>
              <Popover.Arrow />
              <Popover.Body >
                <VStack space={2}>
                  
                  <Button
                    variant="ghost"
                    onPress={() => {
                      this.setState({userOptionsOpen: false});
                      this.#handlePressed('password')
                    }}
                    leftIcon={<Icon as={FontAwesome5} name={"key"} size="sm"/>}
                  >
                    Alterar senha
                  </Button>
                  <Button
                    variant="ghost"
                    onPress={() => {
                      this.setState({userOptionsOpen: false});
                      this.#handlePressed('logoff')
                    }}
                    leftIcon={<Icon as={FontAwesome5} name={"sign-out-alt"} size="sm" />}
                  >
                    Sair
                  </Button>
                </VStack>
              </Popover.Body>
            </Popover.Content>
          </Popover>
        </Center>
      </HStack>
    );
  }

  #handlePressed = (action) => {
    this.props.onAction?.(action);
  }
}

export default MainsScreenUserPanel;
