import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController';
import { notificationController } from "./NotificationController";

class FornecedoresController {
  searchFornecedores = async (text, page, itensPerPage) => {
    try {
      let query = ENDPOINT + '/api/Fornecedores?countTotal=true';

      if (text?.length > 0) {
        query += '&q=' + text;
      }

      if (page) {
        const skip = (page - 1) * itensPerPage;
        query += '&skip=' + skip.toString() + '&limit=' + itensPerPage.toString();
      } else {
        query += '&limit=100';
      }

      const response = await fetch(query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        const fornecedores = await response.json();
        const newItems = fornecedores.items.map((item) => {
          return {...item, nome: item.razaoSocial}
        });
        fornecedores.items = newItems;
        return fornecedores;
      } else {
        notificationController.publishError('Falha ao pesquisar os fornecedores', await response.text());
        return [];
      }
    } catch (error) {
      notificationController.publishError('Falha ao pesquisar os fornecedores', error);
      return [];
    }
  }

  getFornecedor = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Fornecedores/' + id.toString(), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao carregar o fornecedor', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao carregar o fornecedor', error);
      return null;
    }
  }
  
  insertFornecedor = async (fornecedor) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Fornecedores', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(fornecedor)
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao inserir o fornecedor', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao inserir o fornecedor', error);
      return null;
    }
  }
  
  updateFornecedor = async (fornecedor, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Fornecedores/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(fornecedor)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar o fornecedor', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar o fornecedor', error);
      return false;
    }
  }

  deleteFornecedor = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Fornecedores/' + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao excluir o fornecedor', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao excluir o fornecedor', error);
      return false;
    }
  }
}

export const fornecedoresController = new FornecedoresController();
