import React from "react";
import { Dimensions } from 'react-native';
import {
  Center,
  Button,
  Modal,
  VStack,
} from 'native-base';


const PDFContainer = (props) => {
  return <embed
    src={props.urlSource}
    type="application/pdf"
    width={props.width}
    height={props.height}
  />;
}

class PDFViewer extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        size="full"
      >
        <Modal.Content width={Dimensions.get('window').width < 1366? Dimensions.get('window').width - 10 : 1366}>
          <Modal.Header>Pré-visualizar documento</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            <PDFContainer 
              height={Dimensions.get('window').height < 600? Dimensions.get('window').height : 600}
              urlSource = {this.props.urlSource}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                onPress={() => this.props.onClose?.()}
              >
                Fechar
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    )
  }
};

export default PDFViewer;