import React from "react";

import {
  Button,
  FormControl,
  Input,
  Modal,
  TextArea,
  VStack
} from 'native-base';


class MailSender extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      mailRecipient: props.mailRecipient ?? '',
      mailBody: props.mailBody ?? '',
      invalidInputs: [],
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        size="lg"
      >
        <Modal.Content>
          <Modal.Header>Enviar e-mail</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            <VStack space={1}>
              <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('mailRecipient') !== -1} >
                <FormControl.Label marginBottom="0">E-mail do destinatário:</FormControl.Label>
                <Input
                  value={this.state.mailRecipient}
                  onChangeText={(text) => this.setState({mailRecipient: text})}
                />
                <FormControl.ErrorMessage marginTop="0">E-mail inválido</FormControl.ErrorMessage>
              </FormControl>
              <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('mailBody') !== -1} >
                <FormControl.Label marginBottom="0">Texto do e-mail:</FormControl.Label>
                <TextArea
                  height={200}
                  defaultValue={this.state.mailBody}
                  onChangeText={(text) => this.setState({mailBody: text})}
                />
                <FormControl.ErrorMessage marginTop="0">O texto não pode está vazio</FormControl.ErrorMessage>
              </FormControl>
            </VStack>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button variant="ghost" colorScheme="coolGray" onPress={this.props.onClose}>
                Cancelar
              </Button>
              <Button onPress={this.#handleSendMail}>
                Enviar
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    )
  }

  #validateMail = (email) => {
      return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
  }

  #handleSendMail = () => {
    const invalidInputs = [];

    if (!this.#validateMail(this.state.mailRecipient)) {
      invalidInputs.push('mailRecipient');
    }

    if (this.state.mailBody.length === 0) {
      invalidInputs.push('mailBody');
    }

    if (invalidInputs.length === 0) {
      this.props.onConfirm?.(this.state.mailRecipient, this.state.mailBody);
    } else {
      this.setState({invalidInputs: invalidInputs});
    }
  }
}

export default MailSender;