import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';


import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Pressable,
  Spacer,
  HStack,
  FormControl,
  Box,
  FlatList,
  IconButton,
  Spinner,
  Popover,
  Icon,
} from 'native-base';
import { GridRow } from "./GridView";


import ActionButton from "./ActionButton";

const ActionButtons = (props) => {
  return (
    <HStack space="1" justifyContent="flex-end">
      <ActionButton
        isDisabled={props.isDisabled}
        onPress={props.onDelete}
        iconName="trash-alt"
        tooltip="Excluir"
      />
    </HStack>
  );
}


class SelectItemsPopOver extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPopOverOpen: false,
      searching: false,
      searchItems: []
    }
    this.timeoutID = -1;
  }

  render() {
    return (

      <Popover
        isOpen={this.state.isPopOverOpen}
        onOpen={this.#handlePopoverOpen}
        onClose={() => this.setState({ isPopOverOpen: false })}
        trigger={(triggerProps) => {
          if (this.props.isDisabled) {
            return undefined;
          }
          return (
            <IconButton
              isDisabled={this.props.isDisabled}
              variant="solid"
              padding="1"
              size="sm"
              justifyContent="center"
              _icon={{
                as: FontAwesome5,
                name: "plus",
                size: "4",
                textAlign: "center",
                alignSelf: "center",
                color: "white",
              }}
              {...triggerProps}
            />
          )
        }}>
        <Popover.Content>
          <Popover.Arrow />
          <Popover.CloseButton />
          <Popover.Header>{"Selecionar " + this.props.title}</Popover.Header>
          <Popover.Body width={this.props.popOverWidth ?? 350} height={this.props.popOverHeight ?? 210}>
            <VStack space={2}>
              <Input
                autoFocus={true}
                placeholder="Pesquisar"
                onSubmitEditing={() => {
                  if (this.state.searchItems.length === 1) {
                    this.#handleItemPress(this.state.searchItems[0]);
                  }
                }}
                onChangeText={(text) => {
                  if (this.timeoutID > 0) {
                    clearTimeout(this.timeoutID);
                  }
                  this.timeoutID = setTimeout(() => {
                    this.timeoutID = -1;
                    this.#searchText(text);
                  }, 400);
                }}
                InputLeftElement={
                  <Icon
                    as={FontAwesome5}
                    name="search"
                    size="4"
                    textAlign="center"
                    alignSelf="center"
                    marginLeft={1}
                  />
                }
              />
              {
                this.state.searching &&
                <Center
                  height={this.props.popOverHeight ? this.props.popOverHeight - 65 : 145}
                  borderWidth={1}
                  borderColor="coolGray.400"
                  borderRadius={5}
                >
                  <Spinner></Spinner>
                </Center>
                ||
                !this.state.searching &&
                <FlatList
                  height={this.props.popOverHeight ? this.props.popOverHeight - 65 : 145}
                  data={this.state.searchItems}
                  renderItem={this.#renderSearchItem}
                  keyExtractor={(item) => { return item.id.toString(); }}
                  borderWidth={1}
                  borderColor="coolGray.400"
                  borderRadius={5}
                  ListEmptyComponent={<Center><Text>Sem resultados</Text></Center>}
                />
              }
            </VStack>
          </Popover.Body>
        </Popover.Content>
      </Popover>
    );
  }

  #handlePopoverOpen = async () => {
    if (this.props.onSearchItems) {
      this.setState({ searching: true, isPopOverOpen: true });
      const items = await this.props.onSearchItems('');
      this.setState({
        searching: false,
        searchItems: items ?? [],
      });
    }
  }

  #searchText = async (text) => {
    if (this.props.onSearchItems) {
      this.setState({ searching: true });
      const items = await this.props.onSearchItems(text);
      this.setState({
        searching: false,
        searchItems: items ?? [],
      });
    }
  }

  #handleItemPress = (item) => {
    this.props.onItemSelect?.(item);
    this.setState({ isPopOverOpen: false });
  }

  #renderSearchItem = ({ item, index, separators }) => {
    return (
      <Pressable
        onPress={this.#handleItemPress.bind(this, item)}>
        {
          ({ isHovered, isFocused, isPressed }) => {
            return (
              <GridRow
                tooltipVisibool={false}
                flex="1"
                values={[item.nome]}
                dimensions={[1]}
                backgroundColor={isPressed ? "primary.200" : isHovered ? "primary.100" : (index % 2 === 0) ? "coolGray.100" : "white"}
              />
            );
          }
        }
      </Pressable>
    )
  }
}

class MultipleInput extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FormControl
        flex={this.props.flex}
        isDisabled={this.props.isDisabled}
        isInvalid={this.props.isInvalid}
      >
        <FlatList
          borderRadius="4"
          data={this.props.items}
          keyExtractor={(item) => { return item.id.toString(); }}
          ListHeaderComponent={
            <HStack backgroundColor="primary.800" padding={1}>
              <Heading flex={1} color="white" size="sm">
                {this.props.title}
              </Heading>
              <SelectItemsPopOver
                title={this.props.title}
                isDisabled={this.props.isDisabled}
                onItemSelect={this.props.onItemSelect}
                onSearchItems={this.props.onSearchItems}
              />
            </HStack>
          }
          ListEmptyComponent={
            <Center>
              <Text>Sem {this.props.title?.toLowerCase()}</Text>
            </Center>
          }
          renderItem={({ item, index, separators }) => {
            return (
              <GridRow flex="1"
                tooltipVisibool={true}
                dimensions={[0.8, 0.2]}
                values={[
                  item.nome,
                  <ActionButtons
                    onDelete={() => { this.props.onDelete?.(item) }}
                  />
                ]}
                backgroundColor={(index % 2 === 0) ? "coolGray.100" : null}
              />
            )
          }}
        />
        <FormControl.ErrorMessage marginTop="0">{this.props.errorMessage}</FormControl.ErrorMessage>
      </FormControl>
    )
  }
}

export default MultipleInput;