import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController'

class MensagensController {
  
  searchMensagens = async (text) => {
    return [];

    try {
      let query = ENDPOINT + '/api/Mensagens?limit=100';

      if (text?.length > 0) {
        query += '&q=' + text;
      }

      const response = await fetch(query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  updateMensagemLidaStatus = async (lida, id) => {
    try {
      let status = {
        valor: lida
      }
      const response = await fetch(ENDPOINT + '/api/Mensagens/' + id.toString() + '/situacao-lida', {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(status)
      });
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export const mensagensController = new MensagensController();
