import React from 'react';
import {
  Input
} from 'native-base';

class MaskedInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clearText: '',
      maskedText: ''
    }
  }

  render() {
    return (
      <Input
        value={this.state.maskedText}
        onChangeText={this.#handleTextChange}
        {...this.props}
      />
    );
  }

  #handleTextChange = (text) => {
    let newClearText = '';
    let newMaskedText = '';
    const reversedChars = text.split('').reverse();
    
    if (text.length > this.props.mask.length) {
      return;
    }

    if (this.props.mask?.length > 0 && reversedChars.length > 0) {
      for (let i = 0; i < this.props.mask.length && reversedChars.length > 0; i++) {
        const ch = reversedChars.pop();
        switch (this.props.mask.charAt(i)) {
          case '_':
            newClearText += ch;
            newMaskedText += ch;
            break;

          case '9':
            if ((/\d/g).test(ch)) {
              newClearText += ch;
              newMaskedText += ch;
            }
            break;

          case 'A':
            if ((/[A-Za-z]/g).test(ch)) {
              newClearText += ch;
              newMaskedText += ch;
            }
            break;

          default:
            newMaskedText += this.props.mask[i];
            if (ch !== this.props.mask[i])
              reversedChars.push(ch);
            break;
        }
      }
    } else {
      newClearText = text;
      newMaskedText = text;
    }
    this.setState({ clearText: newClearText, maskedText: newMaskedText });
    this.props.onChangeClearText?.(newClearText);
    this.props.onChangeMaskedText?.(newMaskedText);
  }
}

export default MaskedInput;