import React from "react";
import {
  Box,
  Button,
  HStack,
  FlatList,
  Icon,
  Center,
  Spacer,
  VStack,
  ScrollView,
  Popover,
  IconButton
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';
import { ResponsiveComponentSelector } from "./ResponsiveUtils";

export default class EmbeddedViewManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentView: null,
      isPopOverOpen: false
    }
  }

  showView = (viewID, props) => {
    if (viewID != this.state.currentView?.id) {
      let view = this.props.views.find(view => view.id === viewID);

      if (!view && this.props.views.length > 0) {
        view = this.props.views[0];
      }

      if (view) {
        view.props = { params: props, viewManager: this };
        this.setState({ currentView: view });
      }
    }
  }

  componentDidMount() {
    this.showView(this.props.initialView);
  }

  render() {
    return (
      <HStack flex={1} >
        <ResponsiveComponentSelector
          portraitComponent={
            <Center width={50} height={55}>
              <Popover
                isOpen={this.state.isPopOverOpen}
                onOpen={() => this.setState({ isPopOverOpen: true })}
                onClose={() => this.setState({ isPopOverOpen: false })}
                trigger={(triggerProps) => {
                  return (
                    <IconButton
                      variant="outline"
                      colorScheme="gray"
                      padding="1"
                      size="10"
                      justifyContent="center"
                      _icon={{
                        as: FontAwesome5,
                        name: "bars",
                        size: "5",
                        textAlign: "center",
                        alignSelf: "center",
                      }}
                      {...triggerProps}
                    />
                  )
                }}>
                <Popover.Content>
                  <Popover.Arrow />
                  <Popover.CloseButton />
                  <Popover.Header>Menu</Popover.Header>
                  <Popover.Body  >
                    <VStack backgroundColor="coolGray.200" borderRadius={4} width={250}>
                      <FlatList
                        data={this.props.views}
                        keyExtractor={view => view.id}
                        renderItem={this._renderMenuItem}
                      />
                    </VStack>
                  </Popover.Body>
                </Popover.Content>
              </Popover>
            </Center>

          }
          landscapeComponent={
            <Box width={220}>
              <VStack backgroundColor="coolGray.200" margin={2} borderRadius={4}>
                <FlatList
                  data={this.props.views}
                  keyExtractor={view => view.id}
                  renderItem={this._renderMenuItem}
                />
              </VStack>
            </Box>
          }
        />

        <Box flex="auto" marginY={2} marginRight={2}>
          <ScrollView>
            {this._renderCurrentView()}
          </ScrollView>
        </Box>

      </HStack>
    );
  }

  _renderCurrentView = () => {
    if (this.state.currentView) {
      return React.createElement(this.state.currentView.component, this.state.currentView.props, null);
    }
    return null;
  }

  _renderMenuItem = ({ item }) => {
    if (item.hidden) {
      return null;
    } else {
      const highlighted = this.state.currentView?.title === item.title;
      return (
        <Button
          leftIcon={item.iconName && <Icon as={FontAwesome5} name={item.iconName} size="xs" />}
          alignItems="left"
          justifyContent="left"
          variant={"ghost"}
          backgroundColor={(highlighted ? 'primary.700' : null)}
          _text={{
            color: (highlighted ? 'white' : null),
          }}
          _stack={{
            marginLeft: 15
          }}

          onPress={() => {
            this.showView(item.id);
            this.setState({ isPopOverOpen: false });
          }}
        >
          {item.title}
        </Button>
      );
    }
  }
}