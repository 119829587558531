import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Spacer,
  HStack,
  FormControl,
  Box,
  Icon,
  Modal,
  Spinner,
  AlertDialog,
  TextArea
} from 'native-base';

import { licitacoesController } from "../controllers/LicitacoesController";
import { fornecedoresController } from "../controllers/FornecedoresController";
import LicitacaoLoteList from "./ViewLicitacaoLoteList";
import LicitacaoLoteEditor from "./ViewLicitacaoLoteEditor";
import ViewLicitacaoEditorProduto from "./ViewLicitacaoProdutoEditor";
import SearchInput from "../components/SearchInput";
import CurrencyInput from "../components/CurrencyInput";

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { authController } from "../controllers/AuthController";
import ConfirmModal from "../components/ConfirmModal";
import { ResponsiveStack, ResponsiveFormControl } from "../components/ResponsiveUtils";
dayjs.extend(customParseFormat);

class ViewLicitacao extends React.Component {

  constructor(props) {
    super(props);
    this.creatingNewLicitacao = !!this.props.params?.insertion;

    let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.viewOnly = !(permicoes.includes("LicitacoesPolicies.Criar") || permicoes.includes("LicitacoesPolicies.Atualizar"));

    this.state = {
      await: false,
      id: 0,
      descricao: '',
      fornecedorDescricao: '',
      fornecedorId: 0,
      valorTotal: 0,
      lotes: [],
      invalidInputs: [],
      loteForDeletion: null,
      editingLote: null,
    }

    this.loteIdGen = -1;
  }

  componentDidMount() {
    this.loadLicitacao();
  }

  async loadLicitacao() {
    if (!this.creatingNewLicitacao) {
      const licitacao = await licitacoesController.getLicitacao(this.props.params.idLicitacao);
      this.setState({
        id: licitacao.id,
        descricao: licitacao.descricao,
        fornecedorDescricao: licitacao.fornecedor,
        fornecedorId: licitacao.fornecedorId,
        valorTotal: licitacao.valorTotal,
        lotes: licitacao.lotes.map((lote, index) => {
          return {
            id: lote.id,
            descricao: lote.descricao,
            valorTotal: lote.valorTotal,
            itens: lote.itens.map(item => {
              return {
                id: item.id,
                tipo: item.tipo,
                codigo: item.codigo,
                marca: item.marca,
                descricao: item.descricao,
                unidade: item.unidade,
                valorUnitario: item.valorUnitario,
                quantidadeLimite: item.quantidadeLimite,
                quantidadeMinima: item.quantidadeMinima,
                quantidadeMaxima: item.quantidadeMaxima,
                saldo: item.saldo,
                saldoOriginal: item.saldo,
              }
            })
          }
        })
      });
    }
  }

  render() {
    if (!this.creatingNewLicitacao && this.state.id === 0) {
      return (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      );
    }


    return (
      <VStack flex={1} space={2}>
        <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
          <Heading color="white">
            {
              this.creatingNewLicitacao ? 'Cadastrar nova Licitação' :
                this.viewOnly ? 'Visualizar Licitação' : 'Alterar Licitação'
            }
          </Heading>
          <Spacer />
          <Button
            my={1}
            leftIcon={<Icon as={FontAwesome5} name="arrow-left" size="4" />}
            onPress={() => { this.props.viewManager.showView('ViewLicitacoes'); }}>
            Voltar
          </Button>
        </HStack>

        <Modal
          isOpen={this.state.await}
          onClose={() => this.setState({ await: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>

        <ResponsiveStack space="2">
          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('descricao') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Descrição</FormControl.Label>
            <TextArea
              height="75"
              defaultValue={this.state.descricao}
              onChangeText={(text) => this.setState({ descricao: text })}
            />
            <FormControl.ErrorMessage marginTop="0">Informe o descrição da licitação </FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <SearchInput
            flex={1}
            label="Fornecedor"
            defaultValue={this.state.fornecedorDescricao}
            onSearchItems={this.#searchFornecedores}
            onItemSelect={(item) => this.setState({ fornecedorId: item.id, fornecedorDescricao: item.nome })}
            placeholder="Pesquisar fornecedores"
            isInvalid={this.state.invalidInputs.indexOf('fornecedor') !== -1}
            errorMessage="Informe o fornecedor"
            key={'fornecedor' + this.state.fornecedorId}
          />

          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('valorTotal') !== -1} isDisabled={true}>
            <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Valor</FormControl.Label>
            <CurrencyInput
              value={this.state.valorTotal}
              onChangeValue={(newValue) => { this.setState({ valorTotal: newValue }) }}
              key={"valorTotal" + this.state.valorTotal.toString()}
            />
            <FormControl.ErrorMessage marginTop="0">Informe valor total maior que zero</FormControl.ErrorMessage>
          </ResponsiveFormControl>
        </ResponsiveStack>

        <VStack space="1">
          <HStack space="2">
            <Box flexDirection="row" alignItems="end">
              <Heading size="md">Lotes</Heading>
            </Box>

            <Spacer />

            <Button
              variant="ghost"
              leftIcon={<Icon as={FontAwesome5} name="plus" size="4" />}
              onPress={this.#handleLoteAdd}
              isDisabled={this.viewOnly}
            >
              Incluir novo lote
            </Button>
          </HStack>

          <FormControl isInvalid={this.state.invalidInputs.indexOf('produtos') !== -1} isDisabled={this.viewOnly}>
            <LicitacaoLoteList
              lotes={this.state.lotes}
              onLoteEdit={(lote) => this.setState({ editingLote: lote })}
              onLoteDelete={(lote) => this.setState({ loteForDeletion: lote })}
              onLoteChanged={this.#handleLoteSave}
            />
            <FormControl.ErrorMessage marginTop="0">Informe no mínimo um produto</FormControl.ErrorMessage>
          </FormControl>
        </VStack>

        <HStack space="2">
          <Spacer />
          <Button
            isDisabled={this.viewOnly}
            onPress={this.#onSave}
            leftIcon={<Icon as={FontAwesome5} name="check" size="4" />}
          >
            {this.creatingNewLicitacao ? 'Cadastrar' : 'Alterar'}
          </Button>
        </HStack>

        <ConfirmModal
          isOpen={!!this.state.loteForDeletion}
          onClose={() => this.setState({ loteForDeletion: null })}
          onConfirm={() => this.#handleLoteDelete(this.state.loteForDeletion)}
          headerText="Excluir lote"
          bodyText="Atenção! O lote será excluído. Confirma esta ação?"
          buttonConfirmText="Excluir"
        />

        <LicitacaoLoteEditor
          isOpen={!!this.state.editingLote}
          lote={this.state.editingLote}
          onClose={() => this.setState({ editingLote: null })}
          onSave={(lote) => { this.#handleLoteSave(lote) }}
          key={"LicitacaoEditorLote" + this.state.editingLote?.id}
        />

      </VStack>
    );
  }

  #searchFornecedores = async (text) => {
    const fornecedores = await fornecedoresController.searchFornecedores(text);
    return fornecedores.items;
  }

  #handleLoteAdd = () => {
    const newLote = {
      id: this.loteIdGen--,
      descricao: '',
      itens: []
    }

    this.setState({
      editingLote: newLote,
    });
  }

  #handleLoteSave = (lote) => {
    const existingLote = this.state.lotes.find((l) => l.id === lote.id);
    if (existingLote) {
      Object.assign(existingLote, lote);
      this.setState({
        lotes: this.state.lotes.slice(),
        editingLote: null,
      }, () => this.setState({ valorTotal: this.#getValorTotalLicitacao() }));
    } else {
      const newLotes = this.state.lotes.slice();
      newLotes.push(lote);
      this.setState({
        lotes: newLotes,
        editingLote: null,
      }, () => this.setState({ valorTotal: this.#getValorTotalLicitacao() }));
    }
  }

  #handleLoteDelete = (lote) => {
    const newLotes = this.state.lotes.filter(l => l.id !== lote.id);
    this.setState({
      lotes: newLotes,
      loteForDeletion: null,
      valorTotal: this.#getValorTotalLicitacao(),
    });
  }

  #getValorTotalLicitacao = () => {
    let valorTotal = 0;
    this.state.lotes.forEach((lote) => {
      lote.itens.forEach(p => valorTotal += p.valorUnitario * p.quantidadeLimite)
    });
    return valorTotal;
  }

  #getSaldosModificadosLicitacao = () => {
    let result = {
      "itens": []
    };

    this.state.lotes.forEach((lote) => {
      lote.itens.forEach((produto) => {
        if (produto.id > 0 && produto.saldo !== produto.saldoOriginal) {
          result.itens.push({
            "itemId": produto.id,
            "saldoFinal": produto.saldo
          });
        }
      })
    });
    return result;
  }

  #validate = () => {
    let invalidInputs = [];
    if (this.state.descricao.length === 0) {
      invalidInputs.push('descricao');
    }
    if (this.state.fornecedorId === 0) {
      invalidInputs.push('fornecedor');
    }
    if (this.state.valorTotal === 0) {
      invalidInputs.push('valorTotal');
    }
    return invalidInputs;
  }

  #onSave = async () => {
    const invalidInputs = this.#validate();
    if (invalidInputs.length === 0) {
      const licitacao = {
        "descricao": this.state.descricao,
        "fornecedorId": this.state.fornecedorId,
        "valorTotal": this.state.valorTotal,
        "lotes": this.state.lotes.map((lote) => {
          return {
            "id": parseInt(lote.id),
            "descricao": lote.descricao,
            "itens": lote.itens.map((item) => {
              return {
                "id": parseInt(item.id),
                "tipo": parseInt(item.tipo),
                "codigo": item.codigo,
                "descricao": item.descricao,
                "unidade": item.unidade,
                "quantidadeLimite": item.quantidadeLimite,
                "quantidadeMinima": item.quantidadeMinima,
                "quantidadeMaxima": item.quantidadeMaxima,
                "valorUnitario": item.valorUnitario,
                "marca": item.marca
              }
            })
          }
        })
      };

      let success = false;
      this.setState({ await: true });
      if (this.creatingNewLicitacao) {
        success = await licitacoesController.insertLicitacao(licitacao);
      } else {
        success = await licitacoesController.updateLicitacao(licitacao, this.props.params.idLicitacao);
        if (success) {
          const saldos = this.#getSaldosModificadosLicitacao();
          if (saldos.itens.length > 0)
            success = await licitacoesController.updateSaldoLicitacao(saldos, this.props.params.idLicitacao);
        }
      }
      this.setState({ await: false });

      if (success)
        this.props.viewManager.showView('ViewLicitacoes');
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }
}


export default ViewLicitacao;