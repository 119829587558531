import { ENDPOINT } from "./ApiDefs";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { notificationController } from "./NotificationController";

const AuthInfoKey = '@authInfo';

class AuthController {
  #currentUser
  #authInfo;

  constructor() {
    this.#authInfo = null;
    this.#currentUser = null;
  }

  authenticateUser = async (userName, userPassword) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Auth/login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          login: userName,
          senha: userPassword
        })
      });
      if (response.ok) {
        this.#authInfo = await response.json();
        await AsyncStorage.setItem(AuthInfoKey, JSON.stringify(this.#authInfo));
        return true;
      } else {
        this.#authInfo = null;
        notificationController.publishError('Falha ao autenticar o usuário', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao autenticar o usuário', error);
      return false;
    }
  }

  getUserToken = () => {
    if (!this.#authInfo) {
      throw 'Usuário não autenticado.';
    }

    return this.#authInfo.token;
  }

  checkSavedToken = async () => {
    const value = await AsyncStorage.getItem(AuthInfoKey);
    if (value !== null) {
      const savedAuthInfo = JSON.parse(value);
      try {
        const response = await fetch(ENDPOINT + '/api/Auth/refresh-token', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + savedAuthInfo.token
          },
        });
        if (response.ok) {
          this.#authInfo = await response.json();
          await AsyncStorage.setItem(AuthInfoKey, JSON.stringify(this.#authInfo));
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  }

  clearSavedToken = async () => {
    try {
      await AsyncStorage.removeItem('@authInfo')
    }
    catch (e) {
      console.log(e)
    }
  }

  fetchCurrentUser = async () => {
    try {
      const response = await fetch(ENDPOINT + '/api/Auth/user', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.getUserToken()
        },
      });
      if (response.ok) {
        this.#currentUser = await response.json();
        return true;
      } else {
        notificationController.publishError('Falha ao buscar o usuário atual', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao buscar o usuário atual', error);
      return false;
    }
  }

  getCurrentUser = () => {
    if (!this.#currentUser) {
      throw 'Usuário não carregado.';
    }

    return this.#currentUser;
  }

  changeUserPassword = async (oldPassword, newPassword) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Auth/change-password', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.getUserToken()
        },
        body: JSON.stringify({
          senhaAtual: oldPassword,
          novaSenha: newPassword
        })
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao alterar a senha do usuário', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao alterar a senha do usuário', error);
      return false;
    }
  }

  isCurrentUserAnAdmin = () => {
    if (!this.#currentUser) {
      throw 'Usuário não carregado.';
    }
    return ['admin', 'simple'].includes(this.#currentUser.nomePerfil.toLowerCase());
  }
}

  export const authController = new AuthController();
