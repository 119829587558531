import React, { useState } from "react";

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Modal,
  Spacer,
  HStack,
  FormControl,
  Spinner,
  Icon,
  Select,
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { fundosController } from "../controllers/FundosController";
import { usuariosController } from "../controllers/UsuariosController";
import SearchInput from "../components/SearchInput";
import PopOverAlterarSenha from "./ViewUsuarioAlterarSenha"
import { securityController } from "../controllers/SecurityController";
import { authController } from "../controllers/AuthController";
import MaskedInput from "../components/MaskedInput";
import MultipleInput from "../components/MultipleInput";
import { ResponsiveStack, ResponsiveFormControl } from "../components/ResponsiveUtils";
import { constsController } from "../controllers/ConstsController";

class ViewUsuario extends React.Component {

  constructor(props) {
    super(props);
    this.creatingNewUsuario = !!this.props.params?.insertion;

    let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.viewOnly = !(permicoes.includes("UsuariosPolicies.Criar") || permicoes.includes("UsuariosPolicies.Atualizar"));
    this.isMudarSenha = (permicoes.includes("UsuariosPolicies.MudarSenha"));

    this.state = {
      await: false,
      id: 0,
      nome: '',
      login: '',
      perfil: '',
      senha: '',
      fundos: [],
      localizacaoPadrao: 1,
      invalidInputs: []
    }
  }

  componentDidMount() {
    this.loadUsuario();
  }

  async loadUsuario() {
    if (!this.creatingNewUsuario) {
      const usuario = await usuariosController.getUsuario(this.props.params.idUsuario);
      this.setState({
        id: -1,
        nome: usuario.nome ?? '',
        login: usuario.login ?? '',
        perfil: usuario.perfil ?? '',
        fundos: usuario.fundos,
        localizacaoPadrao: usuario.localizacaoPadrao ?? 0,
      });
    }
  }

  render() {
    if (!this.creatingNewUsuario && this.state.id === 0) {
      return (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      );
    }

    return (
      <VStack flex={1}>
        <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
          <Heading color="white">
            {
              this.creatingNewUsuario ? 'Cadastrar novo Usuário' :
                this.viewOnly ? 'Visualizar Usuário' : 'Alterar Usuário'
            }
          </Heading>
          <Spacer />
          <Button
            my={1}
            leftIcon={<Icon as={FontAwesome5} name="arrow-left" size="4" />}
            onPress={() => { this.props.viewManager.showView('ViewUsuarios'); }}>
            Voltar
          </Button>
        </HStack>
        <Modal
          isOpen={this.state.await}
          onClose={() => this.setState({ await: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>

        <VStack space={2}>
          <ResponsiveStack my="2" space="2">
            <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('nome') !== -1} isDisabled={this.viewOnly}>
              <FormControl.Label marginBottom="0">Nome</FormControl.Label>
              <Input defaultValue={this.state.nome} onChangeText={(text) => this.setState({ nome: text })} />
              <FormControl.ErrorMessage marginTop="0">Informe o nome</FormControl.ErrorMessage>
            </ResponsiveFormControl>
            <ResponsiveFormControl flex={1} isDisabled={!this.creatingNewUsuario | this.viewOnly} isInvalid={this.state.invalidInputs.indexOf('login') !== -1}>
              <FormControl.Label marginBottom="0">Login</FormControl.Label>
              <Input defaultValue={this.state.login} onChangeText={(text) => this.setState({ login: text })} />
              <FormControl.ErrorMessage marginTop="0">Informe o login</FormControl.ErrorMessage>
            </ResponsiveFormControl>
            {
              this.creatingNewUsuario &&
              <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('senha') !== -1} >
                <FormControl.Label marginBottom="0">Senha</FormControl.Label>
                <Input type="password" defaultValue={this.state.senha} onChangeText={(text) => this.setState({ senha: text })} />
                <FormControl.ErrorMessage marginTop="0">Informe a senha</FormControl.ErrorMessage>
              </ResponsiveFormControl>
            }

            <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('localizacaoPadrao') !== -1} isDisabled={this.viewOnly}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Localização padrão</FormControl.Label>
              <Select
                defaultValue={this.state.localizacaoPadrao.toString()}
                onValueChange={(selectedItem) => this.setState({ localizacaoPadrao: parseInt(selectedItem) })}
                placeholder="Escolher localização"
              >
                {
                  Object.entries(constsController.setorLocalizacao).map(([id, desc]) => {
                    return <Select.Item label={desc} value={id} key={id} />;
                  })
                }
              </Select>
              <FormControl.ErrorMessage marginTop="0">Informe a localização padrão</FormControl.ErrorMessage>
            </ResponsiveFormControl>

            <SearchInput
              isDisabled={this.viewOnly}
              flex={1}
              label="Perfil"
              defaultValue={this.state.perfil}
              onSearchItems={this.#searchPerfis}
              onItemSelect={(item) => this.setState({ perfil: item.nome })}
              placeholder="Pesquisar perfis"
              isInvalid={this.state.invalidInputs.indexOf('perfil') !== -1}
              errorMessage="Informe o perfil"
              key={'perfil' + this.state.perfil}
            />
            {
              authController.isCurrentUserAnAdmin() | (this.isMudarSenha & this.state.login != 'admin') &&
              !this.creatingNewUsuario &&
              <VStack>
                <Text>  </Text>
                <PopOverAlterarSenha loginUsuario={this.state.login} />
              </VStack>
            }
          </ResponsiveStack>

          <MultipleInput
            isDisabled={this.viewOnly}
            isInvalid={this.state.invalidInputs.indexOf('fundos') !== -1}
            errorMessage="Informe no mínimo um fundo"
            items={this.state.fundos}
            title="Fundos"
            onSearchItems={fundosController.searchFundos}
            onItemSelect={(item) => {
              if (!this.state.fundos.find((f => f.id === item.id))) {
                const novosFundos = this.state.fundos.slice();
                novosFundos.push(item);
                this.setState({ fundos: novosFundos });
              }
            }}
            onDelete={(item) => {
              const novosFundos = this.state.fundos.filter((f) => f.id !== item.id);
              this.setState({ fundos: novosFundos });
            }}
            key={'fundo' + this.state.fundos.length}
          />
        </VStack>
        <HStack my="2" space="2">
          <Spacer />
          <Button
            isDisabled={this.viewOnly}
            onPress={() => { this.#onSave() }}
            leftIcon={<Icon as={FontAwesome5} name="check" size="4" />}
          >
            {this.creatingNewUsuario ? 'Cadastrar' : 'Alterar'}
          </Button>
        </HStack>
      </VStack>
    );
  }

  #validate = () => {
    const invalidInputs = [];
    if (this.state.nome.length === 0) {
      invalidInputs.push('nome');
    }
    if (this.state.perfil.length === 0) {
      invalidInputs.push('perfil');
    }
    if (this.creatingNewUsuario && this.state.login.length === 0) {
      invalidInputs.push('login');
    }
    if (this.creatingNewUsuario && this.state.senha.length === 0) {
      invalidInputs.push('senha');
    }
    if (this.state.fundos.length === 0) {
      invalidInputs.push('fundos');
    }
    if (this.state.localizacaoPadrao === 0) {
      invalidInputs.push('localizacaoPadrao');
    }

    return invalidInputs;
  }

  #onSave = async () => {
    const invalidInputs = this.#validate();
    if (invalidInputs.length === 0) {
      let usuario = {
        "nome": this.state.nome,
        "perfil": this.state.perfil,
        "fundoIds": this.state.fundos.map((fundo) => fundo.id),
        "localizacaoPadrao": this.state.localizacaoPadrao
      }

      if (this.creatingNewUsuario) {
        usuario["login"] = this.state.login;
        usuario["senha"] = this.state.senha;
      }

      let success = false;
      this.setState({ await: true });
      if (this.creatingNewUsuario) {
        success = await usuariosController.insertUsuario(usuario);
      } else {
        success = await usuariosController.updateUsuario(usuario, this.props.params.idUsuario);
      }
      this.setState({ await: false });

      if (success)
        this.props.viewManager.showView('ViewUsuarios');
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }

  #searchPerfis = async (text) => {
    const perfis = await securityController.getPerfis(text);
    return perfis.filter(p => p.id.toLowerCase() !== 'simple')
  }
}

export default ViewUsuario;