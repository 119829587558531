import React from "react";
import { Dimensions } from 'react-native';

import {
  Text,
  HStack,
  VStack,
  Divider,
  FlatList,
  Tooltip,
  Box,
  FormControl,
  Input,
  Select,
} from 'native-base';

import { isScreenOrientationPortrait } from "./ResponsiveUtils";
import NumberInput from "./NumberInput";
import CurrencyInput from "./CurrencyInput";


const TooltipText = (props) => {
  return (
    props.tooltipVisibool && props.value?.length > 0 ?
      <Tooltip label={props.value} openDelay={500} placement="top" hasArrow={true}>
        <Text {...props} onPress={props.onTextPress}>
          <Text {...props} bold>{props.label}</Text> {props.value}
        </Text>
      </Tooltip>
      :
      <Text {...props}>
        <Text {...props} bold>{props.label}</Text> {props.value}
      </Text>
  );
}


export class GridRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <HStack
        flex="1"
        backgroundColor={this.props.backgroundColor}>
        {
          this.props.values.map((value, i, values) => {
            return (
              (typeof value === 'object') ?
                <Box
                  key={i.toString()}
                  padding={this.props.padding ?? "1"}
                  flex={this.props.dimensions ? this.props.dimensions[i] : 1.0 / values.length}
                  textAlign={this.props.textAlignments ? this.props.textAlignments[i] : "left"}
                  color={this.props.textColor}
                  numberOfLines={this.props.numberOfLines ?? 1}
                >
                  {value}
                </Box>
                :
                <TooltipText
                  key={i.toString()}
                  padding={this.props.padding ?? "1"}
                  flex={this.props.dimensions ? this.props.dimensions[i] : 1.0 / values.length}
                  textAlign={this.props.textAlignments ? this.props.textAlignments[i] : "left"}
                  color={this.props.textColor}
                  numberOfLines={this.props.numberOfLines ?? 1}
                  value={value}
                  tooltipVisibool={this.props.tooltipVisibool}
                  onTextPress={this.props.onTextPress}
                />
            );
          })
        }
      </HStack>
    );
  }
}

export class GridItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <HStack
        flex="1"
        flexWrap="wrap"
        backgroundColor={this.props.backgroundColor}>
        {
          this.props.values.map((value, i, values) => {
            return (
              (typeof value === 'object') ?
                <Box
                  key={i.toString()}
                  padding={this.props.padding ?? "1"}
                  width={this.props.width ?? "50%"}
                  //flex="0.5"//{this.props.dimensions ? this.props.dimensions[i] : 1.0 / values.length}
                  textAlign={this.props.textAlignments ? this.props.textAlignments[i] : "left"}
                  color={this.props.textColor}
                  numberOfLines={this.props.numberOfLines ?? 1}
                >
                  {value}
                </Box>
                :
                <TooltipText
                  key={i.toString()}
                  padding={this.props.padding ?? "1"}
                  width={this.props.width ?? "50%"}
                  //flex="0.5"//{this.props.dimensions ? this.props.dimensions[i] : 1.0 / values.length}
                  textAlign={this.props.textAlignments ? this.props.textAlignments[i] : "left"}
                  color={this.props.textColor}
                  numberOfLines={this.props.numberOfLines ?? 1}
                  label={this.props.gridProps.columnCaptions[i] + ": "}
                  value={value}
                  tooltipVisibool={this.props.tooltipVisibool}
                  onTextPress={this.props.onTextPress}
                />
            );
          })
        }
      </HStack>
    );
  }
}

export class GridView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FlatList
        {...this.props}
        ListHeaderComponent={() => {
          if (isScreenOrientationPortrait()) {
            return null;
          } else {
            return (
              <GridRow flex="1"
                values={this.props.columnCaptions}
                dimensions={this.props.columnDimensions}
                backgroundColor="primary.700"
                textColor="white"
              />
            );
          }
        }}

        renderItem={({ item, index, separators }) => {
          if (this.props.renderGridItem) {
            return this.props.renderGridItem(item, index, separators, this.props);
          } else {
            return undefined;
          }
        }}

        ItemSeparatorComponent={() => {
          if (isScreenOrientationPortrait()) {
            return (
              <Box
                height={1}
                backgroundColor="coolGray.200"
              />
            );
          } else {
            return null;
          }
        }}
      />
    );
  }
}

export const ResponsiveGridItem = (props) => {
  if (isScreenOrientationPortrait()) {
    return <GridItem {...props} />
  } else {
    return <GridRow {...props} />
  }
}

export const ResponsiveGridLabel = (props) => {
  if (isScreenOrientationPortrait()) {
    return (
      <HStack {...props}>
        <Text paddingX={1} bold>{props.label + ((props.label?.length > 0) ? ':' : '')}</Text>
        <Text paddingX={1} noOfLines={1}>{props.text}</Text>
      </HStack>
    )
  } else {
    return <Text paddingX={2} {...props} noOfLines={1}>{props.text}</Text>
  }
}

export const ResponsiveGridNumberInput = (props) => {
  if (isScreenOrientationPortrait()) {
    return (
      <FormControl isInvalid={props.isInvalid} {...props}>
        <HStack flex={1}>
          <Text alignSelf="center" paddingX={1} bold>{props.label + ((props.label?.length > 0) ? ':' : '')}</Text>
          <NumberInput
            flex={1}
            number={props.number}
            onChangeNumber={props.onChangeNumber}
            key={props.inputKey}
          />
        </HStack>
        <FormControl.ErrorMessage>{props.errorMessage}</FormControl.ErrorMessage>
      </FormControl>
    )
  } else {
    return (
      <FormControl isInvalid={props.isInvalid} {...props}>
        <NumberInput
          number={props.number}
          onChangeNumber={props.onChangeNumber}
          key={props.inputKey}
        />
        <FormControl.ErrorMessage>{props.errorMessage}</FormControl.ErrorMessage>
      </FormControl>
    )
  }
}

export const ResponsiveGridTextInput = (props) => {
  if (isScreenOrientationPortrait()) {
    return (
      <FormControl isInvalid={props.isInvalid} {...props}>
        <HStack flex={1}>
          <Text alignSelf="center" paddingX={1} bold>{props.label + ((props.label?.length > 0) ? ':' : '')}</Text>
          <Input
            flex={1}
            paddingX={1}
            value={props.value}
            onChangeText={props.onChangeText}
            key={props.inputKey}
          />
        </HStack>
        <FormControl.ErrorMessage>{props.errorMessage}</FormControl.ErrorMessage>
      </FormControl>
    )
  } else {
    return (
      <FormControl isInvalid={props.isInvalid} {...props}>
        <Input
          paddingX={2}
          value={props.value}
          onChangeText={props.onChangeText}
          key={props.inputKey}
        />
        <FormControl.ErrorMessage>{props.errorMessage}</FormControl.ErrorMessage>
      </FormControl>
    )
  }
}

export const ResponsiveGridSelect = (props) => {
  if (isScreenOrientationPortrait()) {
    return (
      <FormControl isInvalid={props.isInvalid} {...props}>
        <HStack flex={1}>
          <Text alignSelf="center" paddingX={1} bold>{props.label + ((props.label?.length > 0) ? ':' : '')}</Text>
          <Box flex={1}>
            <Select
              paddingX={1}
              defaultValue={props.defaultValue}
              onValueChange={props.onValueChange}
              placeholder={props.placeholder}
              key={props.inputKey}
            >
              {
                props.selectEntries
              }
            </Select>
          </Box>
        </HStack>
        <FormControl.ErrorMessage>{props.errorMessage}</FormControl.ErrorMessage>
      </FormControl>
    )
  } else {
    return (
      <FormControl isInvalid={props.isInvalid} {...props}>
        <Select
          paddingX={2}
          defaultValue={props.defaultValue}
          onValueChange={props.onValueChange}
          placeholder={props.placeholder}
          key={props.inputKey}
        >
          {
            props.selectEntries
          }
        </Select>
        <FormControl.ErrorMessage>{props.errorMessage}</FormControl.ErrorMessage>
      </FormControl>
    )
  }
}

export const ResponsiveGridCurrencyInput = (props) => {
  if (isScreenOrientationPortrait()) {
    return (
      <FormControl isInvalid={props.isInvalid} {...props}>
        <HStack flex={1}>
          <Text alignSelf="center" paddingX={1} bold>{props.label + ((props.label?.length > 0) ? ':' : '')}</Text>
          <CurrencyInput
            flex={1}
            value={props.value}
            onChangeValue={props.onChangeValue}
            key={props.inputKey}
          />
        </HStack>
        <FormControl.ErrorMessage>{props.errorMessage}</FormControl.ErrorMessage>
      </FormControl>
    )
  } else {
    return (
      <FormControl isInvalid={props.isInvalid} {...props}>
        <CurrencyInput
          value={props.value}
          onChangeValue={props.onChangeValue}
          key={props.inputKey}
        />
        <FormControl.ErrorMessage>{props.errorMessage}</FormControl.ErrorMessage>
      </FormControl>
    )
  }
}
