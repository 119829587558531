import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Spacer,
  HStack,
  FormControl,
  Box,
  Icon,
  Spinner,
  Modal,
  Select,
} from 'native-base';

import NumberInput from "../components/NumberInput";
import { constsController } from "../controllers/ConstsController";
import CurrencyInput from "../components/CurrencyInput";

class LicitacaoProdutoEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.produto?.id ?? -1,
      codigo: this.props.produto?.codigo ?? '',
      marca: this.props.produto?.marca ?? '',
      tipo: this.props.produto?.tipo ?? 0,
      descricao: this.props.produto?.descricao ?? '',
      unidade: this.props.produto?.unidade ?? '',
      quantidadeLimite: this.props.produto?.quantidadeLimite ?? 0,
      quantidadeMinima: this.props.produto?.quantidadeMinima ?? 0,
      quantidadeMaxima: this.props.produto?.quantidadeMaxima ?? 0,
      valorUnitario: this.props.produto?.valorUnitario ?? 0,
      saldo: this.props.produto?.saldo ?? 0,
      invalidInputs: {
        codigo: false,
        marca: false,
        tipo: false,
        descricao: false,
        unidade: false,
        quantidadeLimite: false,
        quantidadeMinima: false,
        quantidadeMaxima: false,
        valorUnitario: false,
        saldo: false,
      }
    }
  }

  render() {
    if (!this.props.produto)
      return null;

    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        size="lg"
      >
        <Modal.Content >
          <Modal.CloseButton />
          <Modal.Header>{((this.props.produto.id > 0) ? 'Editar' : 'Adicionar') + ' produto'}</Modal.Header>
          <Modal.Body>

            <FormControl isInvalid={this.state.invalidInputs.codigo}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Código</FormControl.Label>
              <Input value={this.state.codigo} onChangeText={(text) => this.setState({ codigo: text })} />
              <FormControl.ErrorMessage marginTop="0">Informe o código do produto</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isInvalid={this.state.invalidInputs.descricao}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Descrição</FormControl.Label>
              <Input value={this.state.descricao} onChangeText={(text) => this.setState({ descricao: text })} />
              <FormControl.ErrorMessage marginTop="0">Informe a descrição do produto</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isInvalid={this.state.invalidInputs.marca}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Marca</FormControl.Label>
              <Input value={this.state.marca} onChangeText={(text) => this.setState({ marca: text })} />
              <FormControl.ErrorMessage marginTop="0">Informe a marca do produto</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isInvalid={this.state.invalidInputs.tipo}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Tipo</FormControl.Label>
              <Select
                defaultValue={this.state.tipo?.toString() ?? ''}
                onValueChange={(selectedItem) => this.setState({ tipo: selectedItem })}
                placeholder="Escolher tipo"
                key={'tipo' + this.state.tipo}
              >
                {
                  Object.entries(constsController.tiposItem).map(([id, desc]) => {
                    return <Select.Item label={desc} value={id} key={id} />;
                  })
                }
              </Select>
              <FormControl.ErrorMessage marginTop="0">Informe o tipo</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isInvalid={this.state.invalidInputs.unidade}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Unidade</FormControl.Label>
              <Select
                defaultValue={this.state.unidade?.toString() ?? ''}
                onValueChange={(selectedItem) => this.setState({ unidade: selectedItem })}
                placeholder="Escolher unidade"
                key={'unidade' + this.state.unidade}
              >
                {
                  Object.entries(constsController.tiposUnidade).map(([id, desc]) => {
                    return <Select.Item label={desc} value={id} key={id} />;
                  })
                }
              </Select>
              <FormControl.ErrorMessage marginTop="0">Informe a unidade</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isInvalid={this.state.invalidInputs.quantidadeLimite}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Quantidade limite</FormControl.Label>
              <NumberInput
                number={new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(this.state.quantidadeLimite) }
                onChangeNumber={(number) => { this.setState({ quantidadeLimite: number }) }}
                key={"quantidadeLimite" + this.state.id.toString()}
              />
              <FormControl.ErrorMessage marginTop="0">Informe valor maior que zero</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isInvalid={this.state.invalidInputs.quantidadeMinima}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Quantidade Mínima</FormControl.Label>
              <NumberInput
                number={new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(this.state.quantidadeMinima) }
                onChangeNumber={(number) => { this.setState({ quantidadeMinima: number }) }}
                key={"quantidadeMinima" + this.state.id.toString()}
              />
              <FormControl.ErrorMessage marginTop="0">Informe valor maior que zero</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isInvalid={this.state.invalidInputs.quantidadeMaxima}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Quantidade Máxima</FormControl.Label>
              <NumberInput
                number={new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(this.state.quantidadeMaxima) }
                onChangeNumber={(number) => { this.setState({ quantidadeMaxima: number }) }}
                key={"quantidadeMaxima" + this.state.id.toString()}
              />
              <FormControl.ErrorMessage marginTop="0">Informe valor maior que zero</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isInvalid={this.state.invalidInputs.saldo} isDisabled={this.state.id <= 0}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Saldo</FormControl.Label>
              <NumberInput
                number={new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(this.state.saldo)}
                onChangeNumber={(newValue) => { this.setState({ saldo: newValue }) }}
                key={"saldo" + this.state.id.toString()}
              />
              <FormControl.ErrorMessage marginTop="0">Informe saldo maior que zero</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isInvalid={this.state.invalidInputs.valorUnitario}>
              <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Valor unitário</FormControl.Label>
              <CurrencyInput
                value={this.state.valorUnitario}
                onChangeValue={(newValue) => { this.setState({ valorUnitario: newValue }) }}
                key={"valorUnitario" + this.state.id.toString()}
              />
              <FormControl.ErrorMessage marginTop="0">Informe valor maior que zero</FormControl.ErrorMessage>
            </FormControl>
          </Modal.Body>

          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => this.props.onClose?.()}
              >
                Cancelar
              </Button>
              <Button onPress={this.#handleSave}
              >
                Salvar
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }

  #handleSave = () => {
    const invalidInputs = {
      codigo: this.state.codigo.length === 0,
      tipo: this.state.tipo === 0,
      descricao: this.state.descricao.length === 0,
      unidade: this.state.unidade.length === 0,
      valorUnitario: this.state.valorUnitario === 0.0
    }

    let allInputIsValid = true;
    for (let key in invalidInputs) {
      if (invalidInputs[key]) {
        allInputIsValid = false;
        break;
      }
    }

    if (allInputIsValid) {
      this.props.onSave?.(this.state);
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }
}

export default LicitacaoProdutoEditor;