import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController';
import { notificationController } from "./NotificationController";

class FundosController {

  searchFundos = async (text) => {
    try {
      let query = ENDPOINT + '/api/Fundos?limit=100';

      if (text?.length > 0) {
        query += '&q=' + text;
      }

      const response = await fetch(query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao pesquisar os fundos', await response.text());
        return [];
      }
    } catch (error) {
      notificationController.publishError('Falha ao pesquisar os fundos', error);
      return [];
    }
  }

  getFundo = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Fundos/' + id.toString(), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao carregar o fundo', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao carregar o fundo', error);
      return null;
    }
  }
  
  insertFundo = async (fundo) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Fundos', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(fundo)
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao inserir o fundo', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao inserir o fundo', error);
      return null;
    }
  }
  
  updateFundo = async (fundo, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Fundos/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(fundo)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar o fundo', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar o fundo', error);
      return false;
    }
  }
  
  deleteFundo = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Fundos/' + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao remover o fundo', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao remover o fundo', error);
      return false;
    }
  }
}

export const fundosController = new FundosController();
