import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController'

class ConstsController {
  constructor() {
    this.tiposSolicitacao = {};
    this.tiposItem = {};
    this.prioridadesSolicitacao = {}
    this.tiposUnidade = {}
    this.modalidadesContrato = {}
    this.setorLocalizacao = {}
    this.tipoItensSolicitados = {}
    this.UFs = {
      "AC": "Acre",
      "AL": "Alagoas",
      "AP": "Amapá",
      "AM": "Amazonas",
      "BA": "Bahia",
      "CE": "Ceará",
      "DF": "Distrito Federal",
      "ES": "Espírito Santo",
      "GO": "Goiás",
      "MA": "Maranhão",
      "MT": "Mato Grosso",
      "MS": "Mato Grosso do Sul",
      "MG": "Minas Gerais",
      "PA": "Pará",
      "PB": "Paraíba",
      "PR": "Paraná",
      "PE": "Pernambuco",
      "PI": "Piauí",
      "RJ": "Rio de Janeiro",
      "RN": "Rio Grande do Norte",
      "RS": "Rio Grande do Sul",
      "RO": "Rondônia",
      "RR": "Roraima",
      "SC": "Santa Catarina",
      "SP": "São Paulo",
      "SE": "Sergipe",
      "TO": "Tocantins"
    }
    this.statusSolicitacao = {}
  }

  loadConsts = async () => {
    await Promise.allSettled([
      this.fetchConsts('tipos-solicitacao').then(consts => this.tiposSolicitacao = consts),
      this.fetchConsts('tipos-item').then(consts => this.tiposItem = consts),
      this.fetchConsts('prioridades-solicitacao').then(consts => this.prioridadesSolicitacao = consts),
      this.fetchConsts('modalidades-contrato').then(consts => this.modalidadesContrato = consts),
      this.fetchConsts('status-solicitacao').then(consts => this.statusSolicitacao = consts),
      this.fetchConsts('setor-localizacao').then(consts => this.setorLocalizacao = consts), 
      this.fetchConsts('tipo-itens-solicitacao').then(consts => this.tipoItensSolicitados = consts),
      this.fetchUnidades().then((listUnidades) => {
        listUnidades.forEach((unidade) => {
          this.tiposUnidade[unidade.id] = unidade.descricao;
        });
      })
    ]);
  }

  fetchConsts = async (name) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Constantes/' + name, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return await response.json();
      } else {
        return {};
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  fetchUnidades = async () => {
    try {
      const response = await fetch(ENDPOINT + '/api/UnidadesMedida', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return await response.json();
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}

export const constsController = new ConstsController();
