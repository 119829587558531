import React from "react";
import { notificationController } from "../controllers/NotificationController";

import {
  Text,
  Slide,
  Alert,
} from 'native-base';

class NotificationPanel extends React.Component {
  #notificationToken

  constructor (props) {
    super(props);

    this.state = {
      isOpen: false,
      message: '',
      detail: '',
    }
  }

  componentDidMount() {
    this.#notificationToken = notificationController.onError((error) => {
      console.error(error);
      this.setState({isOpen: true, message: error.message, detail: error.detail});
      setTimeout(() => this.setState({isOpen: false}), 5000);
    });
  }

  componentWillUnmount() {
    notificationController.unsubscribe(this.#notificationToken);
  }

  render() {
    return (
      <Slide in={this.state.isOpen} placement={"top"} duration={250}>
        <Alert justifyContent="center" status="error">
          <Alert.Icon size="md"/>
          <Text color="error.700" fontWeight="medium" fontSize="md">
            {this.state.message}
          </Text>
          <Text color="coolGray.400" fontSize="sm">
            {
              this.#getDetail()
            }
          </Text>
        </Alert>
      </Slide>
    );
  }

  #getDetail = () => {
    if (typeof this.state.detail === 'string') {
      let msgObj;
      try {
        msgObj = JSON.parse(this.state.detail);
      }
      catch(e) {
        msgObj = null;
      }

      if (msgObj && msgObj.message)
        return msgObj.message;

      return this.state.detail;
    } else {
      return this.state.detail?.message ?? 'Sem detalhes';
    }
  }
}

export default NotificationPanel;