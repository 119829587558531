import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Button,
  Pressable,
  FlatList,
  IconButton,
  Center,
  HStack,
  Input,
  FormControl,
  Select,
  Text,
  Box,
  VStack
} from 'native-base';

import { GridRow, GridView, ResponsiveGridItem } from "../components/GridView";
import NumberInput from "../components/NumberInput";
import { constsController } from "../controllers/ConstsController";
import CurrencyInput from "../components/CurrencyInput";
import ActionButton from "../components/ActionButton";
import LicitacaoProdutoEditor from "./ViewLicitacaoProdutoEditor";
import ConfirmModal from "../components/ConfirmModal";

const ActionButtons = (props) => {
  return (
    <Center padding="0">
      <HStack space="1">
        <ActionButton
          isDisabled={props.viewOnly}
          onPress={props.onDelete}
          iconName="trash-alt"
          tooltip="Excluir"
        />
      </HStack>
    </Center>
  );
}

export default class RecebimentoProdutoList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      produtoForDeletion: null
    }
  }

  render() {
    return (
      <VStack>
        <GridView
          width={this.props.width}
          borderRadius="4"
          minHeight="60"
          data={this.props.produtos}
          keyExtractor={(item) => { return item.id.toString(); }}
          columnCaptions={[
            "Código",
            "Descrição",
            "Marca",
            "Unidade",
            "Quantidade",
            ""
          ]}
          columnDimensions={[0.1, 0.3, 0.3, 0.1, 0.1, 0.1]}
          renderGridItem={(item, index, separators, props) => {
            return (
              <ResponsiveGridItem flex="1"
                tooltipVisibool={true}
                values={[
                  item.codigo,
                  item.descricao,
                  item.marca,
                  constsController.tiposUnidade[item.unidade],
                  <FormControl isInvalid={item.qtd > item.qtdDisponivel} isDisabled={this.viewOnly}>
                    <NumberInput
                      number={item.qtd}
                      onChangeNumber={(number) => this.props.onProdutoQtdChange(item.id, number)}
                    />
                    <FormControl.ErrorMessage marginTop="0">{'A quantidade não deve ser maior que ' + item.qtdDisponivel.toString()}</FormControl.ErrorMessage>
                  </FormControl>,
                  <ActionButtons
                    onDelete={() => this.setState({ produtoForDeletion: item })}
                    viewOnly={this.props.viewOnly}
                    creatingNewLicitacao={this.props.creatingNewLicitacao}
                  />
                ]}
                backgroundColor={(index % 2 === 0) ? "coolGray.100" : null}
                width="100%"
                gridProps={props}
                dimensions={props.columnDimensions}
              />
            );
          }}
        />

        <ConfirmModal
          isOpen={!!this.state.produtoForDeletion}
          onClose={() => this.setState({ produtoForDeletion: null })}
          onConfirm={() => {
            this.props.onProdutoDelete?.(this.state.produtoForDeletion)
            this.setState({ produtoForDeletion: null })
          }}
          headerText="Excluir produto"
          bodyText="Atenção! O produto será excluído do recebimento. Confirma esta ação?"
          buttonConfirmText="Excluir"
        />
      </VStack>
    );
  }
}