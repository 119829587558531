import React from "react";
import { Dimensions } from 'react-native';

import {
  Text,
  HStack,
  VStack,
  Divider,
  FlatList,
  Tooltip,
  Box,
  FormControl
} from 'native-base';

export function isScreenOrientationPortrait() {
  return Dimensions.get('window').width < Dimensions.get('window').height;
}

export const ResponsiveStack = (props) => {
  if (isScreenOrientationPortrait()) {
    return <VStack {...props} />
  } else {
    return <HStack {...props} />
  }
}

export const ResponsiveFormControl = (props) => {
  if (isScreenOrientationPortrait()) {
    return <FormControl {...props} flex={undefined} />
  } else {
    return <FormControl {...props} />
  }
}

export const PortraitText = (props) => {
  if (isScreenOrientationPortrait()) {
    return <Text {...props}>{props.text}</Text>
  } else {
    return <Text {...props}></Text>
  }
}

export class ResponsiveComponentSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      windowHeight: Dimensions.get('window').height,
      windowWidth: Dimensions.get('window').width,
    }
  }

  onChange = ({ window }) => {
    this.setState({ windowHeight: window.height, windowWidth: window.width });
  };

  componentDidMount() {
    this.dimensionsSubscription = Dimensions.addEventListener('change', this.onChange);
  }

  componentWillUnmount() {
    this.dimensionsSubscription?.remove();
  }

  render() {
    if (this.state.windowHeight > this.state.windowWidth) {
      return this.props.portraitComponent;
    } else {
      return this.props.landscapeComponent;
    }
  }
}