import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController';
import { notificationController } from "./NotificationController";

class FontesRecursosController {

  searchFontesRecursos = async (text, page, itensPerPage) => {
    try {
      let query = ENDPOINT + '/api/FontesRecursos?countTotal=true';

      if (text?.length > 0) {
        query += '&q=' + text;
      }

      if (page) {
        const skip = (page - 1) * itensPerPage;
        query += '&skip=' + skip.toString() + '&limit=' + itensPerPage.toString();
      } else {
        query += '&limit=100';
      }

      const response = await fetch(query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao pesquisar as fontes de recursos', await response.text());
        return [];
      }
    } catch (error) {
      notificationController.publishError('Falha ao pesquisar as fontes de recursos', error);
      return [];
    }
  }

  getFonteRecurso = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/FontesRecursos/' + id.toString(), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao carregar a fonte de recurso', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao carregar a fonte de recurso', error);
      return null;
    }
  }

  insertFonteRecurso = async (fonteRecurso) => {
    try {
      const response = await fetch(ENDPOINT + '/api/FontesRecursos', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(fonteRecurso)
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao inserir a fonte de recurso', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao inserir a fonte de recurso', error);
      return null;
    }
  }
  
  updateFonteRecurso = async (fonteRecurso, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/FontesRecursos/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(fonteRecurso)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar a fonte de recurso', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar a fonte de recurso', error);
      return false;
    }
  }
  
  deleteFonteRecurso = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/FontesRecursos/' + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao excluir a fonte de recurso', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao excluir a fonte de recurso', error);
      return false;
    }
  }
}

export const fontesRecursosController = new FontesRecursosController();
