import React from "react";

import {
  Center,
  HStack
} from 'native-base';

import ActionButton from "../components/ActionButton";

const CommonActionButtons = (props) => {
  return (
    <Center padding="0">
      <HStack space="1">
        <ActionButton
          isDisabled={props.isDisabled}
          onPress={props.onDelete}
          iconName="trash-alt"
          tooltip="Excluir"
        />
      </HStack>
    </Center>
  );
}

export default CommonActionButtons;
