import React from "react";
import {
  Center,
  VStack,
  Input,
  Button,
  Heading,
  Pressable,
  Spacer,
  HStack,
  FlatList,
  Icon,
  Spinner,
  Text
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { GridRow, GridView, ResponsiveGridItem } from '../components/GridView';
import { fundosController } from "../controllers/FundosController";
import TextSearchPanel from "../components/TextSearchPanel";
import { authController } from "../controllers/AuthController";
import ConfirmModal from "../components/ConfirmModal";
import CommonActionButtons from "../components/CommonActionButtons";
import { TooltipPressable } from "../components/TooltipPressable";

class ViewFundos extends React.Component {

  constructor(props) {
    super(props);

    let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.notCriar = !(permicoes.includes("FundosPolicies.Criar"));
    this.notRemover = !(permicoes.includes("FundosPolicies.Remover"));

    this.state = {
      searching: false,
      fundoForDeletion: 0,
      fundos: []
    }
    if (localStorage.getItem('page') !== 'fundo') {
      localStorage.removeItem('filtro');
      localStorage.setItem('page', 'fundo');
    }
  }

  componentDidMount() {
    this.#loadFundos();
  }

  render() {
    return (
      <Center flex={1}>
        <VStack flex={1} width={'100%'} space={2}>
          <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
            <Heading color="white">Fundos</Heading>
            <Spacer />
            <Button
              isDisabled={this.notCriar}
              my="1"
              leftIcon={<Icon as={FontAwesome5} name="plus" size="4" />}
              onPress={() => { this.props.viewManager.showView('ViewFundo', { insertion: true }) }}>
              Adicionar
            </Button>
          </HStack>

          <TextSearchPanel
            placeholder="Buscar fundos"
            onSearch={this.#searchFundos}
          />

          <GridView
            borderRadius="4"
            data={this.state.searching ? [] : this.state.fundos}
            keyExtractor={(item) => { return item.id.toString(); }}
            columnCaptions={["Nome", "CNPJ", ""]}
            columnDimensions={[0.6, 0.25, 0.15]}
            ListEmptyComponent={
              (
                this.state.searching &&
                <Spinner marginY={3} size="lg" />
              )
              ||
              (
                <Center>
                  <Text>Sem resultados</Text>
                </Center>
              )
            }
            renderGridItem={(item, index, separators, props) => {
              return (
                <TooltipPressable
                  onPress={() => { this.props.viewManager.showView('ViewFundo', { idFundo: item.id }) }}>
                  {
                    ({ isHovered, isFocused, isPressed }) => {
                      return (
                        <ResponsiveGridItem flex="1"
                          tooltipVisibool={true}
                          values={[
                            item.nome,
                            item.cnpj,
                            <CommonActionButtons flex={0.1}
                              isDisabled={this.notRemover}
                              onDelete={() => this.setState({ fundoForDeletion: item.id })}
                            />,
                          ]}
                          backgroundColor={isPressed ? "primary.200" : isHovered ? "primary.100" : (index % 2 === 0) ? "coolGray.100" : null}
                          width="100%"
                          gridProps={props}
                          dimensions={props.columnDimensions}
                        />
                      )
                    }
                  }
                </TooltipPressable>
              )
            }}
          />
        </VStack>

        <ConfirmModal
          isOpen={this.state.fundoForDeletion > 0}
          onClose={() => this.setState({ fundoForDeletion: 0 })}
          onConfirm={() => this.#deleteFundo(this.state.fundoForDeletion)}
          headerText="Excluir fundo"
          bodyText="Atenção! O fundo será excluído. Confirma esta ação?"
          buttonConfirmText="Excluir"
        />
      </Center>
    );
  }

  #searchFundos = async (searchedText) => {
    this.setState({ searching: true });
    localStorage.setItem('filtro', JSON.stringify(searchedText));
    const fundos = await fundosController.searchFundos(searchedText);
    this.setState({ searching: false, fundos: fundos });
  }

  #deleteFundo = async (fundoID) => {
    this.setState({ fundoForDeletion: 0 });
    this.setState({ await: true });
    if (await fundosController.deleteFundo(fundoID)) {
      this.#loadFundos();
    }
    this.setState({ await: false });
  }

  #loadFundos = () => {
    if (localStorage.getItem('page') === 'fundo') {
      const filtroStr = localStorage.getItem('filtro');
      if (filtroStr) {
        const filtro = JSON.parse(filtroStr);
        this.#searchFundos(filtro);
      } else
        this.#searchFundos('');
    }
  }
}

export default ViewFundos;