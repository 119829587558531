import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Input,
  Button,
  HStack,
  FormControl,
  Select,
  Icon,
  VStack,
  Text,
  Modal,
  Center,
  Spinner
} from 'native-base';
import StatusPanel from "./ViewCompraStatusPanel";
import { comprasController } from "../controllers/ComprasController";

export default class StatusPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idSolicitacao: -1,
      status: '',
      listaStatus: [],
      localizacao: '',
      historicoStatus: [],
    }
  }

  componentDidMount() {
    if (this.props.idSolicitacao > 0)
      this.loadSolicitacaoCompras();
  }

  loadSolicitacaoCompras = async () => {
    const solicitacaoCompra = await comprasController.getSolicitacaoCompra(this.props.idSolicitacao);
    this.setState({
      idSolicitacao: solicitacaoCompra.id,
      status: solicitacaoCompra.status,
      listaStatus: solicitacaoCompra.listaStatus,
      localizacao: solicitacaoCompra.localizacao,
      historicoStatus: solicitacaoCompra.historicoStatus,
    });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        size="lg"
      >
        <Modal.Content >
          <Modal.Body>
            {
              (
                this.state.idSolicitacao > 0 &&
                <StatusPanel
                  status={this.state.status}
                  listaStatus={this.state.listaStatus}
                  localizacao={this.state.localizacao}
                  historicoStatus={this.state.historicoStatus}
                  idSolicitacao={this.state.idSolicitacao}
                  width="100%"
                  maxWidth="600"
                  alignSelf="center"
                  marginBottom={10}
                  isDisabled={true}
                />
              )
              ||
              (
                <VStack alignItems="center" space={2}>
                  <Text>Carregando...</Text>
                  <Spinner size="lg"/>
                </VStack>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="outline"
                onPress={() => this.props.onClose?.()}
              >
                Fechar
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }
}
