import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Center,
  HStack,
  VStack
} from 'native-base';

import { GridView, ResponsiveGridItem } from "../components/GridView";
import { constsController } from "../controllers/ConstsController";
import ActionButton from "../components/ActionButton";
import ConfirmModal from "../components/ConfirmModal";
import { ListNavigator } from "../components/ListNavigator";

const NAVIGATOR_ITENS_PER_PAGE = 15;

const ActionButtons = (props) => {
  return (
    <Center padding="0">
      <HStack space="1">
        <ActionButton
          viewOnly={props.viewOnly}
          onPress={props.onEdit}
          iconName="edit"
          tooltip="Editar"
        />
        <ActionButton
          viewOnly={props.viewOnly}
          onPress={props.onDelete}
          iconName="trash-alt"
          tooltip="Excluir"
        />
      </HStack>
    </Center>
  );
}

export default class LicitacaoProdutoList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      produtoForDeletion: null
    }
  }

  render() {
    return (
      <VStack>
        <GridView
          width={this.props.width}
          borderRadius="4"
          minHeight="60"
          data={this.#getProdutosSlice(this.props.produtos, this.props.page)}
          keyExtractor={(i, index) => { return index.toString(); }}
          columnCaptions={[
            "Código",
            "Descrição",
            "Marca",
            "Tipo",
            "Unidade",
            "Qtd. Licitada",
            "Qtd. Mínima",
            "Qtd. Máxima",
            "Saldo Disponível",
            "Valor Unitário",
            ""
          ]}
          columnDimensions={[0.15, 0.15, 0.20, 0.1, 0.075, 0.075, 0.075, 0.075, 0.075, 0.075, 0.08]}
          renderGridItem={(item, index, separators, props) => {
            return (
              <ResponsiveGridItem flex="1"
                tooltipVisibool={true}
                values={[
                  item.codigo,
                  item.descricao,
                  item.marca,
                  constsController.tiposItem[item.tipo],
                  constsController.tiposUnidade[item.unidade],
                  new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(item.quantidadeLimite),                  
                  new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(item.quantidadeMinima),
                  new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(item.quantidadeMaxima),
                  new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(item.saldo),
                  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(item.valorUnitario),
                  <ActionButtons
                    onDelete={() => this.setState({ produtoForDeletion: item })}
                    onEdit={() => this.props.onProdutoEdit?.(item)}
                    viewOnly={this.props.viewOnly}
                    creatingNewLicitacao={this.props.creatingNewLicitacao}
                  />
                ]}
                backgroundColor={(index % 2 === 0) ? "coolGray.100" : null}
                width="100%"
                gridProps={props}
                dimensions={props.columnDimensions}
              />
            );
          }}

          ListFooterComponent={
            <HStack justifyContent="flex-start">
              <ListNavigator
                key={'ListNavigator' + this.props.page}
                page={this.props.page}
                countItens={this.props.produtos.length}
                itensPerPage={NAVIGATOR_ITENS_PER_PAGE}
                onChangePage={this.props.onChangePage}
              />
            </HStack>
          }
        />

        <ConfirmModal
          isOpen={!!this.state.produtoForDeletion}
          onClose={() => this.setState({ produtoForDeletion: null })}
          onConfirm={() => {
            this.props.onProdutoDelete?.(this.state.produtoForDeletion)
            this.setState({ produtoForDeletion: null })
          }}
          headerText="Excluir produto"
          bodyText="Atenção! O produto será excluído do lote. Confirma esta ação?"
          buttonConfirmText="Excluir"
        />
      </VStack>
    );
  }

  #getProdutosSlice = (produtos, page) => {
    const maxPages = Math.ceil(produtos.length / NAVIGATOR_ITENS_PER_PAGE);
    if (page > maxPages) {
      page = maxPages;
    }
    const skip = (page - 1) * NAVIGATOR_ITENS_PER_PAGE;
    return produtos.slice(skip, skip + NAVIGATOR_ITENS_PER_PAGE);
  }
}