import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Button,
  HStack,
  FormControl,
  Select,
  Icon,
  VStack,
  Heading,
  Center,
  FlatList,
  Text,
  Circle,
  Spacer,
  Divider,
  Modal,
  Tooltip,
  Spinner,
  TextArea
} from 'native-base';

import { constsController } from "../controllers/ConstsController";
import { comprasController } from "../controllers/ComprasController";
import { fontesRecursosController } from "../controllers/FontesRecursosController";
import SearchInput from "../components/SearchInput";
import { ResponsiveStack } from "../components/ResponsiveUtils";



export default class StatusPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      await: false,
      status: props.status,
      listaStatus: props.listaStatus,
      startStatus: props.status,
      editing: false,
      obs: '',
      localizacao: this.#getLocalizacaoFromProps(),
      fonteRecursoId: props.fonteRecursoId ?? 0,
      fonteRecursoNome: props.fonteRecursoNome ?? '',
      startfonteRecursoId: props.fonteRecursoId ?? 0,
      isDisabled: props.isDisabled,
    };
  }
  render() {
    return (
      <VStack space={2} {...this.props}>
        <HStack space="2">
          <Center>
            <Heading size="sm">Status</Heading>
          </Center>
          <Spacer />
          {
            !this.props.isDisabled &&
            <Button
              variant="ghost"
              onPress={() => this.setState({ editing: true })}
              isDisabled={this.props.isDisabled}
            >
              Alterar status
            </Button>
          }
        </HStack>
        <Modal
          isOpen={this.state.await}
          onClose={() => this.setState({ await: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>
        <Modal isOpen={this.state.editing} onClose={() => this.#closeModal()}>
          <Modal.Content width={450}>
            <Modal.CloseButton />
            <Modal.Header>Alterar status</Modal.Header>
            <Modal.Body>
              <VStack space={2}>
                <FormControl>
                  <FormControl.Label _text={{ isTruncated: true }} marginBottom="1">Novo status</FormControl.Label>
                  <Select
                    defaultValue={this.props.status?.toString() ?? ''}
                    onValueChange={(selectedItem) => this.setState({ status: parseInt(selectedItem) })}
                    placeholder="Escolher status"
                    key={'status' + this.props.status}
                  >
                    {
                      Object.entries(this.state.listaStatus).map(([id, desc]) => {
                        return <Select.Item label={desc} value={id} key={id} />;
                      })
                    }
                  </Select>
                </FormControl>

                <FormControl>
                  <FormControl.Label _text={{ isTruncated: true }} marginBottom="1">Localização</FormControl.Label>
                  <Select
                    defaultValue={this.#getLocalizacaoFromProps().toString() ?? ''}
                    onValueChange={(selectedItem) => this.setState({ localizacao: parseInt(selectedItem) })}
                    placeholder="Escolher localização"
                    key={'localizacao' + this.props.localizacao}
                  >
                    {
                      Object.entries(constsController.setorLocalizacao).map(([id, desc]) => {
                        return <Select.Item label={desc} value={id} key={id} />;
                      })
                    }
                  </Select>
                </FormControl>


                <SearchInput
                  label="Fonte do recurso"
                  defaultValue={this.state.fonteRecursoNome}
                  onSearchItems={this.#searchFontesRecursos}
                  onItemSelect={(item) => this.setState({ fonteRecursoId: item.id, fonteRecursoNome: item.nome })}
                  placeholder="Pesquisar fontes de recurso"
                  key={'fonteRecurso' + this.state.fonteRecursoId}
                />

                <FormControl>
                  <FormControl.Label _text={{ isTruncated: true }} marginBottom="1">Observação</FormControl.Label>
                  <TextArea
                    defaultValue="" onChangeText={(text) => this.setState({ obs: text })}
                    key={'obs' + this.props.obs}
                  />
                </FormControl>
              </VStack>
            </Modal.Body>
            <Modal.Footer justifyContent="flex-end">
              <Button.Group space={2}>
                <Button
                  variant="outline"
                  onPress={() => this.#closeModal()}
                >
                  Cancelar
                </Button>

                <Tooltip
                  label={(
                    (this.#canSave()) ? 'Salvar alterações' : 'Status ou localização ou fonte de recurso não alterados'
                  )}
                  openDelay={500}
                  placement="top"
                >
                  <Button
                    isDisabled={!this.#canSave()}
                    onPress={this.#onSave}
                  >
                    Alterar
                  </Button>
                </Tooltip>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>

        <FlatList
          ItemSeparatorComponent={() => {
            return (
              <Divider
                orientation="vertical"
                alignSelf="center"
                height="16"
                backgroundColor="coolGray.500"
              />
            );
          }}
          data={this.props.historicoStatus}
          keyExtractor={(item, index) => { return index.toString(); }}
          renderItem={({ item, index, separators }) => {
            return (
              <HStack
                space={2}
                flex={1}
                justifyContent="center"
                borderWidth={1}
                borderColor={(index === 0) ? "primary.500" : "coolGray.500"}
                borderRadius={5}
              >
                <Circle size="md" margin={1}>
                  <Icon as={FontAwesome5} name="check-circle" size="8" color={(index === 0) ? "primary.500" : "coolGray.500"} />
                </Circle>
                <VStack flex={1} margin={1}>
                  <ResponsiveStack flex={1}>
                    <Text flex={0.5} bold={true} isTruncated >{constsController.statusSolicitacao[item.status]}</Text>
                    <Text flex={0.5} isTruncated >Data: <Text bold>{new Date(item.dataHora).toLocaleString()}</Text></Text>
                  </ResponsiveStack>
                  <ResponsiveStack flex={1}>
                    <Text flex={0.5} isTruncated >Usuário: <Text bold>{item.usuario}</Text></Text>
                    <Text flex={0.5} isTruncated >Localização: <Text bold>{item.localizacao}</Text></Text>
                  </ResponsiveStack>
                  <Text>{item.observacoes}</Text>
                </VStack>
              </HStack>
            );
          }}
        />
      </VStack>
    );
  }

  #onSave = async () => {
    const statusInfo = {
      "status": this.state.status,
      "observacoes": this.state.obs,
      "localizacao": parseInt(this.state.localizacao),
      "fonteRecursoId": this.state.fonteRecursoId,
    };

    this.setState({ await: true });
    await comprasController.updateStatusSolicitacaoCompra(statusInfo, this.props.idSolicitacao);
    this.setState({ await: false });

    this.setState({ editing: false });
    this.props.onUpdateStatus?.();
  }

  #closeModal = () => {
    this.setState({ editing: false });
  }

  #getLocalizacaoFromProps = () => {
    return Object.entries(constsController.setorLocalizacao).find(([id, desc]) => this.props.localizacao === desc)?.[0] ?? 0;
  }

  #canSave = () => {
    return this.state.status !== this.state.startStatus ||
      this.state.localizacao !== this.#getLocalizacaoFromProps() ||
      this.state.fonteRecursoId !== this.state.startfonteRecursoId;
  }

  #searchFontesRecursos = async (text) => {
    const fontesRecursos = await fontesRecursosController.searchFontesRecursos(text);
    return fontesRecursos.items;
  }
}