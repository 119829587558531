import React from "react";

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Modal,
  Icon,
  Spacer,
  HStack,
  FormControl,
  Spinner
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { authController } from "../controllers/AuthController";
import { comprasController } from "../controllers/ComprasController";
import { recebimentosController } from "../controllers/RecebimentosController";
import { ResponsiveStack } from "../components/ResponsiveUtils";
import MaskedInput from "../components/MaskedInput";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import RecebimentoProdutoList from "./ViewRecebimentoProdutoList";
dayjs.extend(customParseFormat);

class ViewRecebimento extends React.Component {

  constructor(props) {
    super(props);
    this.creatingRecebimento = !!this.props.params?.insertion;

    let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.viewOnly = !!this.props.params?.viewOnly ; //!(permicoes.includes("RecebimentosPolicies.Criar") || permicoes.includes("RecebimentosPolicies.Atualizar"));

    this.state = {
      await: false,
      id: 0,
      data: '',
      numeroNota: '',
      solicitacaoCompraId: 0,
      items: [],
      invalidInputs: []
    }
    this.itemIdGen = -1;
  }

  componentDidMount() {
    this.loadRecebimento();
  }

  async loadRecebimento() {
    if (this.creatingRecebimento) {
      const solicitacaoCompra = await comprasController.getSolicitacaoCompra(this.props.params.idSolicitacao, true);
      this.setState({
        data: dayjs().format('DD/MM/YYYY'),
        solicitacaoCompraId: solicitacaoCompra.id,
        items: solicitacaoCompra.itens.map((itemSolicitacao, index) => {
          return {
            id: this.itemIdGen--,
            solicitacaoCompraItemId: itemSolicitacao.id,
            tipo: itemSolicitacao.tipo,
            codigo: itemSolicitacao.codigo,
            marca: itemSolicitacao.marca,
            descricao: itemSolicitacao.descricao,
            unidade: itemSolicitacao.unidade,
            qtd: itemSolicitacao.saldoRecebimento,
            qtdDisponivel: itemSolicitacao.saldoRecebimento,
            qtdItem: itemSolicitacao.quantidade,
          }
        })
      });
    } else {
      const recebimento = await recebimentosController.getRecebimento(this.props.params.idRecebimento);
      this.setState({
        id: recebimento.id,
        data: dayjs(recebimento.data).format('DD/MM/YYYY'),
        numeroNota: recebimento.numeroNota,
        solicitacaoCompraId: this.props.params.idSolicitacao,
        items: recebimento.itens.map((itemRecebimento) => {
          return {
            id: itemRecebimento.id,
            solicitacaoCompraItemId: itemRecebimento.solicitacaoCompraItemId,
            codigo: itemRecebimento.codigo,
            marca: itemRecebimento.marca,
            descricao: itemRecebimento.descricao,
            unidade: itemRecebimento.unidade,
            qtd: itemRecebimento.quantidadeRecebida,
            qtdDisponivel: itemRecebimento.quantidadeRecebida,
            qtdItem: itemRecebimento.quantidade,
          }
        })
      });
    }
  }

  render() {
    if (!this.creatingRecebimento && this.state.id === 0) {
      return (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      );
    }

    return (
      <VStack flex={1} space={2}>
        <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
          <Heading color="white">
            {
              this.creatingRecebimento ? 'Cadastrar novo Recebimento' :
                this.viewOnly ? 'Visualizar Recebimento' : 'Alterar Recebimento'
            }
          </Heading>
          <Spacer />
          <Button
            my={1}
            leftIcon={<Icon as={FontAwesome5} name="arrow-left" size="4" />}
            onPress={() => {
              if (this.viewOnly) {
                this.props.viewManager.showView('ViewRecebimentosExternal')
              } else {
                this.props.viewManager.showView('ViewRecebimentos', {
                  idSolicitacao: this.props.params.idSolicitacao
                })
              }
            }}
          >
            Voltar
          </Button>
        </HStack>

        <ResponsiveStack space="2">
          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('data') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Data</FormControl.Label>
            <MaskedInput
              mask="99/99/9999"
              placeholder="dd/mm/aaaa"
              value={this.state.data}
              onChangeMaskedText={(text) => this.setState({ data: text })}
            />
            <FormControl.ErrorMessage marginTop="0">Informe a data</FormControl.ErrorMessage>
          </FormControl>
          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('numeroNota') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Número da nota</FormControl.Label>
            <Input value={this.state.numeroNota} onChangeText={(text) => this.setState({ numeroNota: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe o número da nota</FormControl.ErrorMessage>
          </FormControl>
        </ResponsiveStack>

        <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('items') !== -1} isDisabled={this.viewOnly}>
          <RecebimentoProdutoList
            viewOnly={this.viewOnly}
            produtos={this.state.items}
            onProdutoQtdChange={this.#handleProdutoQtdChange}
            onProdutoDelete={this.#handleProdutoDelete}
          />
          <FormControl.ErrorMessage marginTop="0">Informe os produtos corretamente</FormControl.ErrorMessage>
        </FormControl>

        <HStack my="2" space="2">
          <Spacer />
          <Button
            leftIcon={<Icon as={FontAwesome5} name="check" size="4" />}
            onPress={() => { this.#onSave() }}
            isDisabled={this.viewOnly}
          >
            {this.creatingRecebimento ? 'Cadastrar' : 'Alterar'}
          </Button>
        </HStack>

        <Modal
          isOpen={this.state.await}
          onClose={() => this.setState({ await: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>

      </VStack>
    );
  }

  #validate = () => {

    return Object.getOwnPropertyNames(this.state).filter((propertyName) => {
      if (['id', 'solicitacaoCompraId', 'invalidInputs', 'await'].indexOf(propertyName) !== -1)
        return false;

      if (propertyName === 'items') {
        return (!this.state.items.find((i) => i.qtd > 0) ||
          this.state.items.find((i) => i.qtd > i.qtdDisponivel));
      }

      if (propertyName === 'data' && !dayjs(this.state.data, 'DD/MM/YYYY', true).isValid()) {
        return true;
      }

      switch (typeof this.state[propertyName]) {
        case 'string':
          return this.state[propertyName].length === 0;

        case 'number':
          return this.state[propertyName] === 0;

        default:
          return true;
      }
    });
  }

  #onSave = async () => {
    const invalidInputs = this.#validate();
    if (invalidInputs.length === 0) {
      const recebimento = {
        "data": dayjs(this.state.data, 'DD/MM/YYYY').toJSON(),
        "numeroNota": this.state.numeroNota,
        "solicitacaoCompraId": this.state.solicitacaoCompraId,
        "itemRecebimentos": this.state.items.map((item) => {
          return {
            id: item.id,
            itemSolicitacaoCompraId: item.solicitacaoCompraItemId,
            quantidadeRecebida: item.qtd,
          }
        }),
      }

      let success = false;
      this.setState({ await: true });
      if (this.creatingRecebimento) {
        success = await recebimentosController.insertRecebimento(recebimento);
      } else {
        success = await recebimentosController.updateRecebimento(recebimento, this.props.params.idRecebimento);
      }
      this.setState({ await: false, invalidInputs: [] });

      if (success)
        this.props.viewManager.showView('ViewRecebimentos', { idSolicitacao: this.props.params.idSolicitacao });
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }

  #handleProdutoQtdChange = (idItem, qtd) => {
    const item = this.state.items.find((i => i.id === idItem))
    if (item) {
      item.qtd = qtd;
      this.setState({ items: this.state.items.slice() });
    }
  }

  #handleProdutoDelete = (item) => {
    const newItems = this.state.items.filter((i) => i.id !== item.id);
    this.setState({ items: newItems });
  }
}

export default ViewRecebimento;