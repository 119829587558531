import React from "react";

import {
  Tooltip,
  IconButton
} from 'native-base';

import { FontAwesome5 } from '@expo/vector-icons';



class ActionButton extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Tooltip label={this.props.tooltip} openDelay={500} placement="top">
        <IconButton
          isDisabled={this.props.isDisabled}
          variant={this.props.variant ?? "ghost"}
          size="sm"
          padding="1"
          onPress={() => {
            if (!this.props.isDisabled)
              this.props.onPress();
          }}
          _icon={{
            as: FontAwesome5,
            name: this.props.iconName,
            size: "4",
            color: this.props.color,
            textAlign: "center",
          }}
        />
      </Tooltip>
    );
  }
}

export default ActionButton;