import React from "react";
import {
  Center,
  Text,
  VStack,
  Heading,
  Spacer,
  HStack,
  Box,
} from 'native-base';

import EmbeddedViewManager from "../components/EmbeddedViewManager";
import MainsScreenUserPanel from "./MainScreenUserPanel";
import { authController } from "../controllers/AuthController";
import ViewFundos from "./ViewFundos";
import ViewFundo from "./ViewFundo";
import ViewFornecedores from "./ViewFornecedores";
import ViewFornecedor from './ViewFornecedor';
import ViewCompra from "./ViewCompra";
import ViewCompras from "./ViewCompras";
import ViewContratos from "./ViewContratos";
import ViewContrato from "./ViewContrato";
import ViewFontesRecursos from "./ViewFontesRecursos";
import ViewFonteRecurso from "./ViewFonteRecurso";
import ViewUsuarios from "./ViewUsuarios";
import ViewUsuario from "./ViewUsuario";
import ViewLicitacoes from "./ViewLicitacoes";
import ViewLicitacao from "./ViewLicitacao";
import ViewImportacao from "./ViewImportacao";
import MainScreenChangePasswordDlg from "./MainScreenChangePasswordDlg";
import ViewRecebimentos from "./ViewRecebimentos";
import ViewRecebimento from "./ViewRecebimento";
import ViewContratoEmpenho from "./ViewContratoEmpenho";
import ViewRecebimentosExternal from "./ViewRecebimentosExternal";

const appVersion = require('../../app.json').expo.version;

class MainScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      changePasswordDlgVisible: false
    }
    this.viewManager = React.createRef();
    this.views = [
      {
        id: 'ViewCompras',
        title: 'Compras e Serviços',
        iconName: 'file-invoice',
        component: ViewCompras
      },
      {
        id: 'ViewCompra',
        title: 'Compras e Serviços',
        component: ViewCompra,
        hidden: true
      },
      {
        id: 'ViewRecebimentos',
        title: 'Compras e Serviços',
        component: ViewRecebimentos,
        hidden: true,
      },
      {
        id: 'ViewRecebimentosExternal',
        title: 'Recebimentos',
        iconName: 'clipboard-check',
        component: ViewRecebimentosExternal,
      },
      {
        id: 'ViewRecebimento',
        title: 'Compras e Serviços',
        component: ViewRecebimento,
        hidden: true,
      },
      {
        id: 'ViewFundos',
        title: 'Fundos',
        component: ViewFundos,
        iconName: 'file-invoice-dollar',
      },
      {
        id: 'ViewFundo',
        title: 'Fundos',
        component: ViewFundo,
        hidden: true
      },
      {
        id: 'ViewFornecedores',
        title: 'Fornecedores',
        component: ViewFornecedores,
        iconName: 'truck',
      },
      {
        id: 'ViewFornecedor',
        title: 'Fornecedores',
        component: ViewFornecedor,
        hidden: true
      },
      {
        id: 'ViewContratos',
        title: 'Contratos',
        component: ViewContratos,
        iconName: 'file-signature',
      },
      {
        id: 'ViewContrato',
        title: 'Contratos',
        component: ViewContrato,
        hidden: true
      },
      {
        id: 'ViewContratoEmpenho',
        title: 'Contratos',
        component: ViewContratoEmpenho,
        hidden: true
      },
      {
        id: 'ViewFontesRecursos',
        title: 'Fontes de Recursos',
        component: ViewFontesRecursos,
        iconName: 'landmark',
      },
      {
        id: 'ViewFonteRecurso',
        title: 'Fontes de Recursos',
        component: ViewFonteRecurso,
        hidden: true
      },
      {
        id: 'ViewUsuarios',
        title: 'Usuários',
        component: ViewUsuarios,
        iconName: 'users',
      },
      {
        id: 'ViewUsuario',
        title: 'Usuários',
        component: ViewUsuario,
        hidden: true
      },
      {
        id: 'ViewLicitacoes',
        title: 'Licitações',
        component: ViewLicitacoes,
        iconName: 'balance-scale',
      },
      {
        id: 'ViewLicitacao',
        title: 'Licitações',
        component: ViewLicitacao,
        hidden: true
      },
      {
        id: 'ViewImportacao',
        title: 'Importação',
        component: ViewImportacao,
        iconName: 'file-import',
      },
    ].filter(view => this.#currentUserCanView(view.id));
  }

  render() {
    return (
      <VStack flex={1}>
        <Box backgroundColor="primary.900" height={60}>
          <HStack flex={1} marginX={4}>
            <Center >
              <Heading color="white">Simple</Heading>
            </Center>
            <Spacer />
            <HStack>
              <MainsScreenUserPanel
                onAction={this.#handleUserPanelAction}
              />
            </HStack>
          </HStack>
        </Box>
        <EmbeddedViewManager
          ref={this.viewManager}
          flex={1}
          initialView="ViewCompras"
          views={this.views}
        />
        <HStack>
          <Text paddingX={2} color="coolGray.500">Versão {appVersion}</Text>
          <Spacer/>
        </HStack>
        <MainScreenChangePasswordDlg
          key={this.state.changePasswordDlgVisible}
          isOpen={this.state.changePasswordDlgVisible}
          onClose={() => this.setState({changePasswordDlgVisible: false})}
        />
      </VStack>
    );
  }

  #handleUserPanelAction = async (action) => {
    switch (action) {
      case 'logoff':
        await authController.clearSavedToken();
        this.props.navigation.navigate('AuthenticationScreen');
        break;
      case 'password':
        this.setState({changePasswordDlgVisible: true})
        break;
      case 'message':
      case 'notification':
      case 'help':
        break;
      case 'perfil':
        this.viewManager.current.showView('ViewUsuario', { idUsuario: authController.getCurrentUser().id });
        break;
    }
  }

  #currentUserCanView = (viewId)  => {
    let permicoes=JSON.parse(authController.getCurrentUser().perfil);
       
    
    let views=[];
    permicoes.forEach(permissao => {
      switch (permissao) {
        case 'ContratosPolicies.Listar':  
          views.push('ViewContratos');
          break;
        case 'ContratosPolicies.Visualizar':
        case 'ContratosPolicies.Criar':
        case 'ContratosPolicies.Atualizar':   
          views.push('ViewContratos');
          views.push('ViewContrato');
          views.push('ViewContratoEmpenho');
          break;
        case 'FontesRecursosPolicies.Listar':  
          views.push('ViewFontesRecursos');
          break;
        case 'FontesRecursosPolicies.Visualizar':
        case 'FontesRecursosPolicies.Criar':
        case 'FontesRecursosPolicies.Atualizar': 
          views.push('ViewFontesRecursos'); 
          views.push('ViewFonteRecurso');
          break;
        case 'FornecedoresPolicies.Listar':  
          views.push('ViewFornecedores');
          break;
        case 'FornecedoresPolicies.Visualizar':
        case 'FornecedoresPolicies.Criar':
        case 'FornecedoresPolicies.Atualizar':  
          views.push('ViewFornecedores');
          views.push('ViewFornecedor');
          break;
        case 'FundosPolicies.Listar':  
          views.push('ViewFundos');
          break;
        case 'FundosPolicies.Visualizar':
        case 'FundosPolicies.Criar':
        case 'FundosPolicies.Atualizar':  
          views.push('ViewFundos'); 
          views.push('ViewFundo');
          break;
        case 'ImportacaoPolicies.Licitacoes':  
          views.push('ViewImportacao');
          break;
        case 'LicitacoesPolicies.Listar':  
          views.push('ViewLicitacoes');
          break;
        case 'LicitacoesPolicies.Visualizar':
        case 'LicitacoesPolicies.Criar':
        case 'LicitacoesPolicies.Atualizar':  
          views.push('ViewLicitacoes');
          views.push('ViewLicitacao');
          break;
        case 'SolicitacoesComprasPolicies.Listar':  
          views.push('ViewCompras');
          views.push('ViewRecebimentos');
          break;
        case 'SolicitacoesComprasPolicies.Visualizar':
        case 'SolicitacoesComprasPolicies.Criar':
        case 'SolicitacoesComprasPolicies.Atualizar':
        case 'SolicitacoesComprasPolicies.Atualizar':
        case 'SolicitacoesComprasPolicies.Remover':
        case 'SolicitacoesComprasPolicies.ImprimirRelatorio':
        case 'SolicitacoesComprasPolicies.EnviarEmailContrato':  
          views.push('ViewCompras');  
          views.push('ViewCompra');
          views.push('ViewRecebimentos');
          views.push('ViewRecebimento');
          views.push('ViewRecebimentosExternal');
          break;
        case 'UsuariosPolicies.Listar':  
          views.push('ViewUsuarios');  
          break;
        case 'UsuariosPolicies.Visualizar':
        case 'UsuariosPolicies.Criar':
        case 'UsuariosPolicies.Atualizar':
        case 'UsuariosPolicies.MudarSenha':  
          views.push('ViewUsuarios'); 
          views.push('ViewUsuario'); 
          break;
      }
    });
    
    return views.includes(viewId);

    
  }
}

export default MainScreen;