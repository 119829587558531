import React from "react";

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Pressable,
  Spacer,
  HStack,
  FlatList,
  Icon,
  Spinner,
  Modal
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { GridRow, GridView, GridItem, ResponsiveGridItem } from '../components/GridView';
import { ListNavigator } from '../components/ListNavigator';
import { recebimentosController } from "../controllers/RecebimentosController";
import TextSearchPanel from "../components/TextSearchPanel";
import { authController } from "../controllers/AuthController";
import ConfirmModal from "../components/ConfirmModal";
import ViewRecebimento from "./ViewRecebimento";
import SearchPanelRecebimentos from "./ViewRecebimentosSearchPanel";
import ActionButton from "../components/ActionButton";
import { TooltipPressable } from "../components/TooltipPressable";
import PDFViewer from "../components/PDFViewer";

const NAVIGATOR_ITENS_PER_PAGE = 15;


const ActionButtons = (props) => {
  return (
    <Center padding="0">
      <HStack space="1">
        <ActionButton
          isDisabled={props.isDisabled}
          onPress={props.onDelete}
          iconName="trash-alt"
          tooltip="Excluir"
        />
        <ActionButton
          isDisabled={props.isDisabled}
          onPress={props.onDownload}
          iconName="download"
          tooltip="Baixar"
        />
      </HStack>
    </Center>
  );
}

class ViewRecebimentos extends React.Component {

  constructor(props) {
    super(props);

    let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.viewOnly = false;//!(permicoes.includes("RecebimentoPolicies.Criar"));
    this.notRemover = false;//!(permicoes.includes("RecebimentoPolicies.Remover"));

    this.state = {
      searching: false,
      downloading: false,
      recebimentoForDeletion: 0,
      currentPage: 1,
      countItens: 0,
      recebimentos: [], 
      canReceive: this.props.params.canReceive,
      urlSource: null
    }

    if (localStorage.getItem('page') !== 'recebimentos') {
      localStorage.removeItem('filtro');
      localStorage.removeItem('currentPage');
      localStorage.setItem('page', 'recebimentos');
    } else {
      if (localStorage.getItem('currentPage'))
        this.state.currentPage = localStorage.getItem('currentPage');
      else
        this.state.currentPage = 1;
    }

  }

  componentDidMount() {
    this.#loadRecebimentos();
  }

  render() {
    return (
      <Center flex={1}>
        <VStack flex={1} width={'100%'} space={2}>
          <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700" space={2}>
            <Heading color="white">Recebimentos</Heading>
            <Spacer />
              <Button
                isDisabled={!this.state.canReceive}
                my="1"
                leftIcon={<Icon as={FontAwesome5} name="plus" size="4" />}
                onPress={() => {
                this.props.viewManager.showView('ViewRecebimento', {
                    insertion: true,
                  idSolicitacao: this.props.params.idSolicitacao
                  })
                }}>
                Adicionar
              </Button>
              <Button
                my={1}
                leftIcon={<Icon as={FontAwesome5} name="arrow-left" size="4" />}
                onPress={() => {
                  this.props.viewManager.showView('ViewCompra', {
                  idSolicitacao: this.props.params.idSolicitacao
                  })
                }}
              >
                Voltar
              </Button>
          </HStack>

          <SearchPanelRecebimentos
            showSolicitacaoId={false}
            onSearch={(options) => {
              this.state.currentPage = 1;
              this.#searchRecebimentos(options);
            }}
          />

          <ListNavigator
            key={'ListNavigator' + this.state.currentPage}
            page={this.state.currentPage}
            countItens={this.state.countItens}
            itensPerPage={NAVIGATOR_ITENS_PER_PAGE}
            onChangePage={(page) => {
              this.setState({ currentPage: page }, () => this.#loadRecebimentos());
            }}
          />

          <GridView
            borderRadius="4"
            data={this.state.searching ? [] : this.state.recebimentos}
            keyExtractor={(item, index) => index.toString()}
            columnCaptions={[
              "Nº da Solicitação",
              "Nº da nota",
              "Data",
              "Valor da Nota Fiscal",
              ""
            ]}
            columnDimensions={[0.2, 0.3, 0.3, 0.2, 0.1]}
            ListEmptyComponent={
              (
                this.state.searching &&
                <Spinner marginY={3} size="lg" />
              )
              ||
              (
                <Center>
                  <Text>Sem resultados</Text>
                </Center>
              )
            }
            renderGridItem={(item, index, separators, props) => {
              return (
                <TooltipPressable
                  onPress={() => {
                    this.props.viewManager.showView('ViewRecebimento', {
                      idRecebimento: item.id,
                      idSolicitacao: this.props.params.idSolicitacao
                    })
                  }}>
                  {
                    ({ isHovered, isFocused, isPressed }) => {
                      return (
                        <ResponsiveGridItem flex="1"
                          tooltipVisibool={true}
                          values={[
                            item.solicitacaoCompraId,
                            item.numeroNota,
                            new Date(item.data).toLocaleString('br',{
                              weekday: "short",
                              year: "numeric",
                              month: "2-digit",
                              day: "numeric"
                            }),
                            new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(item.valorTotal),
                            <ActionButtons flex={0.1}
                              isDisabled={this.notRemover}
                              onDelete={() => this.setState({ recebimentoForDeletion: item.id })}
                              onDownload={() => this.#downloadRecebimento(item.id) }
                            />,
                          ]}
                          width="100%"
                          gridProps={props}
                          dimensions={props.columnDimensions}
                          backgroundColor={isPressed ? "primary.200" : isHovered ? "primary.100" : (index % 2 === 0) ? "coolGray.100" : null}
                        />
                      )
                    }
                  }
                </TooltipPressable>
              )
            }}
          />
        </VStack>
        <ConfirmModal
          isOpen={this.state.recebimentoForDeletion > 0}
          onClose={() => this.setState({ recebimentoForDeletion: 0 })}
          onConfirm={() => this.#deleteRecebimento(this.state.recebimentoForDeletion)}
          headerText="Excluir o recebimento"
          bodyText="Atenção! O recebimento será excluído. Confirma esta ação?"
          buttonConfirmText="Excluir"
        />

        <Modal
          isOpen={this.state.downloading}
          onClose={() => this.setState({ downloading: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Gerando relatório. Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>
        
        <PDFViewer
          isOpen={!!this.state.urlSource}
          onClose={() => this.setState({ urlSource: null })}
          urlSource={this.state.urlSource}
        />
      </Center>
    );
  }

  #searchRecebimentos = async (searchOptions) => {
    this.setState({ searching: true });
    searchOptions.solicitacaoId = this.props.params.idSolicitacao;
    localStorage.setItem('filtro', JSON.stringify(searchOptions));
    localStorage.setItem('currentPage', this.state.currentPage);
    const recebimentos = await recebimentosController.searchRecebimentos(searchOptions,
      this.state.currentPage, NAVIGATOR_ITENS_PER_PAGE);
    this.setState({ searching: false, recebimentos: recebimentos.items, countItens: recebimentos.totalCount });
  }

  #deleteRecebimento = async (id) => {
    this.setState({ recebimentoForDeletion: 0 });
    this.setState({ await: true });
    if (await recebimentosController.deleteRecebimento(id)) {
      this.#loadRecebimentos();
    }
    this.setState({ await: false });
  }

  #loadRecebimentos = () => {
    if (localStorage.getItem('page') === 'recebimentos') {
      const filtroStr = localStorage.getItem('filtro');
      if (filtroStr) {
        const filtro = JSON.parse(filtroStr);
        this.#searchRecebimentos(filtro);
      } else
        this.#searchRecebimentos({});
    }
  }

  #downloadRecebimento = async (id) => {
    this.setState({ downloading: true })
    const pdf = await recebimentosController.downloadRecebimento(id);
    this.setState({ downloading: false, urlSource: (pdf)? URL.createObjectURL(pdf) : null })
  }
}

export default ViewRecebimentos;
