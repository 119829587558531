import React from "react";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NativeBaseProvider, extendTheme } from 'native-base';

import AuthenticationScreen from './src/screens/AuthenticationScreen';
import MainScreen from "./src/screens/MainScreen";
import NotificationPanel from "./src/components/NotificationPanel";

//tema utilizado pelo Native Base
const nativeBaseTheme = extendTheme({
  config: {
    initialColorMode: 'light',
  },
});


//tema utilizado pelo NavigationContainer
const navigationTheme = {
  dark: false,
  colors: {
    primary: 'rgb(255, 45, 85)',
    background: 'rgb(255, 255, 255)',
    card: 'rgb(255, 255, 255)',
    text: 'rgb(28, 28, 30)',
    border: 'rgb(255, 255, 255)',
    notification: 'rgb(255, 69, 58)',
  },
};

const Stack = createNativeStackNavigator();

//gerenciador principal de telas
const RootStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="AuthenticationScreen"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name="AuthenticationScreen"
        component={AuthenticationScreen}
        options={{ title: 'Autenticação' }}
      />
      <Stack.Screen
        name="MainScreen"
        component={MainScreen}
        options={{ title: 'Simple' }}
      />
    </Stack.Navigator>
  );
}

export default function App() {
  return (
    <NavigationContainer theme={navigationTheme}>
      <NativeBaseProvider theme={nativeBaseTheme}>
        <NotificationPanel />
        <RootStack />
      </NativeBaseProvider>
    </NavigationContainer>
  );
}