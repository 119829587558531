import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController';
import { notificationController } from "./NotificationController";

class EmpenhosController {
  getEmpenho = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Empenhos/' + id.toString() + '?includeItens=true', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao carregar o empenho', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao carregar o empenho', error);
      return null;
    }
  }

  searchEmpenhos = async (searchOptions) => {
    try {
      let query = ENDPOINT + '/api/Empenhos?countTotal=true&q=' + searchOptions.text;
      if (searchOptions.idUsuario) {
        query += '&usuarioId=' + searchOptions.idUsuario;
      }
      if (searchOptions.idContrato) {
        query += '&contratoId=' + searchOptions.idContrato;
      }
      if (searchOptions.data){
        query += '&data=' + searchOptions.data
      }
      if (searchOptions.page) {
        const skip = (searchOptions.page - 1) * searchOptions.itensPerPage; 
        query += '&skip=' + skip.toString() + '&limit=' + searchOptions.itensPerPage.toString() 
      } else {
        query += '&limit=50';
      }

      const response = await fetch(query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        const empenhos = await response.json();
        const newItems = empenhos.items.map((empenho) => {
          return { ...empenho, nome: empenho.descricao}
        });
        empenhos.items = newItems;
        return empenhos;
      } else {
        notificationController.publishError('Falha ao pesquisar os empenhos', await response.text());
        return [];
      }
    } catch (error) {
      notificationController.publishError('Falha ao pesquisar os empenhos', error);
      return [];
    }
  }

  insertEmpenho = async (empenho) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Empenhos', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(empenho)
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao inserir o empenho', await response.text());
        return [];
      }
    } catch (error) {
      notificationController.publishError('Falha ao inserir o empenho', error);
      return [];
    }
  }
  
  updateEmpenho = async (empenho, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Empenhos/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(empenho)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar o empenho', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar o empenho', error);
      return false;
    }
  }

  deleteEmpenho = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Empenhos/' + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao excluir o empenho', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao excluir o empenho', error);
      return false;
    }
  }
}

export const empenhosController = new EmpenhosController();
