import React from "react";

import {
  VStack,
  Input,
  Button,
  FormControl,
  Popover,
  Icon
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { usuariosController } from "../controllers/UsuariosController";

class PopOverAlterarSenha extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      novaSenha: '',
      novaSenhaCopia: '',
      working: false,
    }
  }

  render() {
    return <Popover
      isOpen={this.state.isOpen}
      onClose={() => this.setState({ isOpen: false })}
      trigger={triggerProps => {
        return (
          <Button
            {...triggerProps}
            variant="outline"
            size="sm"
            leftIcon={<Icon as={FontAwesome5} name="key" size="4" />}
            onPress={() => this.setState({ isOpen: true })}
          >
            Alterar senha
          </Button>);
      }}
    >
      <Popover.Content width={300}>
        <Popover.Arrow />
        <Popover.CloseButton />
        <Popover.Header>Alterar senha</Popover.Header>
        <Popover.Body>
          <VStack space={2}>
            <FormControl>
              <FormControl.Label marginBottom="0">Nova senha</FormControl.Label>
              <Input
                type="password"
                value={this.state.novaSenha}
                onChangeText={(text) => this.setState({ novaSenha: text })}
              />
            </FormControl>

            <FormControl>
              <FormControl.Label marginBottom="0">Repetir a nova senha</FormControl.Label>
              <Input
                type="password"
                defaultValue={this.state.novaSenhaCopia}
                onChangeText={(text) => this.setState({ novaSenhaCopia: text })}
              />
            </FormControl>
          </VStack>
        </Popover.Body>
        <Popover.Footer justifyContent="flex-end">
          <Button.Group space={2}>
            <Button
              variant="ghost"
              onPress={() => this.setState({ isOpen: false })}
              width={120}
            >
              Cancelar
            </Button>
            <Button
              isDisabled={this.state.novaSenha.length == 0 || this.state.novaSenha !== this.state.novaSenhaCopia}
              onPress={async () => {
                this.setState({ working: true });
                await usuariosController.updateSenhaUsuario(this.props.loginUsuario, this.state.novaSenha);
                this.setState({
                  working: false,
                  isOpen: false,
                  novaSenha: '',
                  novaSenhaCopia: ''
                });
              }}
              isLoading={this.state.working}
              isLoadingText="Alterando..."
              width={120}
            >
              Alterar
            </Button>
          </Button.Group>
        </Popover.Footer>
      </Popover.Content>
    </Popover>
  }
}

export default PopOverAlterarSenha;