import React from "react";

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Modal,
  Spacer,
  HStack,
  FormControl,
  Spinner,
  Box,
  Icon,
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';
import { fundosController } from "../controllers/FundosController";
import MaskedInput from "../components/MaskedInput";
import { authController } from "../controllers/AuthController";
import { ResponsiveStack, ResponsiveFormControl } from "../components/ResponsiveUtils";

class ViewFundo extends React.Component {

  constructor(props) {
    super(props);
    this.creatingNewFundo = !!this.props.params?.insertion;

    let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.viewOnly = !(permicoes.includes("FundosPolicies.Criar") || permicoes.includes("FundosPolicies.Atualizar"));

    this.state = {
      await: false,
      id: 0,
      nome: '',
      cnpj: '',
      invalidInputs: []
    }
  }

  componentDidMount() {
    this.loadFundo();
  }

  async loadFundo() {
    if (!this.creatingNewFundo) {
      const fundo = await fundosController.getFundo(this.props.params.idFundo);
      this.setState({
        id: fundo.id,
        nome: fundo.nome ?? '',
        cnpj: fundo.cnpj ?? ''
      });
    }
  }

  render() {
    if (!this.creatingNewFundo && this.state.id === 0) {
      return (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      );
    }

    return (
      <VStack flex={1} space={2}>
        <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
          <Heading color="white">
            {
              this.creatingNewFundo ? 'Cadastrar novo Fundo' :
                this.viewOnly ? 'Visualizar Fundo' : 'Alterar Fundo'
            }
          </Heading>
          <Spacer />
          <Button
            my={1}
            leftIcon={<Icon as={FontAwesome5} name="arrow-left" size="4" />}
            onPress={() => { this.props.viewManager.showView('ViewFundos'); }}>
            Voltar
          </Button>
        </HStack>

        <Modal
          isOpen={this.state.await}
          onClose={() => this.setState({ await: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>

        <ResponsiveStack my="2" space="2">
          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('nome') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Nome</FormControl.Label>
            <Input defaultValue={this.state.nome} onChangeText={(text) => this.setState({ nome: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe o nome</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('cnpj') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">CNPJ</FormControl.Label>
            <MaskedInput
              mask="99.999.999/9999-99"
              value={this.state.cnpj}
              onChangeMaskedText={(text) => this.setState({ cnpj: text })}
            />
            <FormControl.ErrorMessage marginTop="0">Informe o CNPJ</FormControl.ErrorMessage>
          </ResponsiveFormControl>
        </ResponsiveStack>
        <HStack my="2" space="2">
          <Spacer />
          <Button
            onPress={() => { this.#onSave() }}
            leftIcon={<Icon as={FontAwesome5} name="check" size="4" />}
            isDisabled={this.viewOnly}
          >
            {this.creatingNewFundo ? 'Cadastrar' : 'Alterar'}
          </Button>
        </HStack>
      </VStack>
    );
  }

  #validate = () => {
    return Object.getOwnPropertyNames(this.state).filter((propertyName) => {
      if (['id', 'invalidInputs', 'await'].indexOf(propertyName) !== -1)
        return false;

      switch (typeof this.state[propertyName]) {
        case 'string':
          return this.state[propertyName].length === 0;

        case 'number':
          return this.state[propertyName] === 0;

        default:
          return true;
      }
    });
  }

  #onSave = async () => {
    const invalidInputs = this.#validate();
    if (invalidInputs.length === 0) {
      const fundo = {
        "nome": this.state.nome,
        "cnpj": this.state.cnpj
      }

      let success = false;
      this.setState({ await: true });
      if (this.creatingNewFundo) {
        success = await fundosController.insertFundo(fundo);
      } else {
        success = await fundosController.updateFundo(fundo, this.props.params.idFundo);
      }
      this.setState({ await: false });

      if (success)
        this.props.viewManager.showView('ViewFundos');
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }
}

export default ViewFundo;