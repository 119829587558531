import React from "react";

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Modal,
  Spacer,
  HStack,
  FormControl,
  Box,
  Select,
  TextArea,
  Spinner,
  Icon,
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import CompraProdutoEditor from "./ViewCompraProdutoEditor";
import ProdutoList from "./ViewCompraProdutoList";
import SearchInput from "../components/SearchInput";
import AlertModal from "../components/AlertModal";
import { comprasController } from "../controllers/ComprasController";
import { fundosController } from "../controllers/FundosController";
import { fornecedoresController } from "../controllers/FornecedoresController";
import { fontesRecursosController } from "../controllers/FontesRecursosController";
import { contratosController } from "../controllers/ContratosController";
import { constsController } from "../controllers/ConstsController";
import { licitacoesController } from "../controllers/LicitacoesController";
import StatusPanel from "./ViewCompraStatusPanel";
import { authController } from "../controllers/AuthController";

import dayjs from 'dayjs';
import { ResponsiveFormControl, ResponsiveStack, isScreenOrientationPortrait, ResponsiveBox, generateWidthDependentKey } from "../components/ResponsiveUtils";

class ViewCompra extends React.Component {

  constructor(props) {
    super(props);

    this.permicoes = JSON.parse(authController.getCurrentUser().perfil);

    
    this.state = {
      await: false,
      id: 0,
      solicitante: '',
      destino: '',
      fornecedor: {
        id: 0,
        nome: '',
      },
      prioridade: 0,
      tipo: 0,
      status: 0,
      localizacao: '',
      fundo: {
        id: 0,
        nome: '',
      },
      fonteRecurso: {
        id: 0,
        nome: '',
      },
      contrato: {
        id: 0,
        nome: '',
        saldo: NaN,
      },
      justificativa: '',
      produtos: [
      ],
      invalidInputs: {
        solicitante: false,
        destino: false,
        fornecedor: false,
        prioridade: false,
        tipo: false,
        fundo: false,
        fonteRecurso: false,
        contrato: false,
        justificativa: false,
        produtos: false,
        tipoItensSolicitacao: false
      },
      editingProduto: null,
      historicoAlteracaoStatus: [],
      alertModalOpen: false,
      alertModalText: '',
      tipoItensSolicitacao: 0,
      produtosPage: 1,
      creatingNewCompra: !!this.props.params?.insertion,
      viewOnly: !!this.props.params?.viewOnly | !(this.permicoes.includes("SolicitacoesComprasPolicies.Atualizar")),
      canReceive: this.props.params?.canReceive && (this.permicoes.includes("RecebimentosPolicies.Criar")),
    }
    this.produtoIdGen = -1;
  }

  componentDidMount() {
    if (!this.state.creatingNewCompra) {
      this.loadSolicitacaoCompra(this.props.params.idSolicitacao);
    }
  }

  async loadSolicitacaoCompra(idSolicitacao) {
    this.setState({ id: 0 });

    const solicitacaoCompra = await comprasController.getSolicitacaoCompra(idSolicitacao);
    const newState = {
      id: solicitacaoCompra.id,
      solicitante: solicitacaoCompra.solicitante,
      destino: solicitacaoCompra.destino,
      fornecedor: {
        id: solicitacaoCompra.fornecedorId,
        nome: solicitacaoCompra.fornecedor,
      },
      prioridade: solicitacaoCompra.prioridade,
      tipo: solicitacaoCompra.tipo,
      status: solicitacaoCompra.status,
      listaStatus: solicitacaoCompra.listaStatus,
      localizacao: solicitacaoCompra.localizacao,
      fundo: {
        id: solicitacaoCompra.fundoId,
        nome: solicitacaoCompra.fundo,
      },
      fonteRecurso: {
        id: solicitacaoCompra.fonteRecursoId ?? 0,
        nome: solicitacaoCompra.fonteRecurso ?? '',
      },
      contrato: {
        id: solicitacaoCompra.contratoId ?? 0,
        nome: solicitacaoCompra.contrato ?? '',
        saldo: solicitacaoCompra.contratoSaldo ?? NaN
      },
      justificativa: solicitacaoCompra.justificativa,
      produtos: solicitacaoCompra.itens.map((item, index) => {
        return {
          id: item.id,
          itemId: item.itemId,
          tipo: item.tipo,
          codigo: item.codigo,
          marca: item.marca,
          descricao: item.descricao,
          unidade: item.unidade,
          qtd: item.quantidade,
          qtdOriginal: item.quantidade,
          valorUnitario: item.valorUnitario,
          saldo: item.saldo,
          saldoOriginal: item.saldo,
          lote: item.lote,
          quantidadeMinima: item.quantidadeMinima,
          quantidadeMaxima: item.quantidadeMaxima,
        }
      }),
      historicoStatus: solicitacaoCompra.historicoStatus,
      tipoItensSolicitacao: solicitacaoCompra.tipoItensSolicitacao, 
    }
    this.setState(newState);
  }

  render() {
    if (!this.state.creatingNewCompra && this.state.id === 0) {
      return (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      );
    }

    return (
      <VStack flex={1} marginRight={2} space={2}>
        <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
          <Heading color="white">
            {
              this.state.creatingNewCompra ? 'Emitir Solicitação de Compras e Serviços' :
                this.state.viewOnly ? 'Visualizar Solicitação de Compras e Serviços' : 'Editar Solicitação de Compras e Serviços'
            }
          </Heading>
          <Spacer />
          <Button
            my={1}
            leftIcon={<Icon as={FontAwesome5} name="arrow-left" size="4" />}
            onPress={() => { this.props.viewManager.showView('ViewCompras'); }}>
            Voltar
          </Button>
        </HStack>
        <Modal
          isOpen={this.state.await}
          onClose={() => this.setState({ await: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>
        <ResponsiveStack space="2">

          <ResponsiveFormControl flex={0.5} isInvalid={this.state.invalidInputs.solicitante} isDisabled={this.state.viewOnly}>
            <FormControl.Label marginBottom="0" _text={{ isTruncated: true }} >Nome do solicitante</FormControl.Label>
            <Input
              defaultValue={this.state.solicitante}
              onChangeText={(text) => this.setState({ solicitante: text })}
            />
            <FormControl.ErrorMessage marginTop="0">Informe o solicitante</FormControl.ErrorMessage>
          </ResponsiveFormControl>

          <ResponsiveFormControl flex={0.5} isInvalid={this.state.invalidInputs.destino} isDisabled={this.state.viewOnly}>
            <FormControl.Label marginBottom="0" _text={{ isTruncated: true }} >Nome do destino</FormControl.Label>
            <Input
              defaultValue={this.state.destino}
              onChangeText={(text) => this.setState({ destino: text })}
            />
            <FormControl.ErrorMessage marginTop="0">Informe o destino</FormControl.ErrorMessage>
          </ResponsiveFormControl>
        </ResponsiveStack>

        <ResponsiveStack space="2">
          <SearchInput
            flex="0.5"
            label="Fornecedor"
            defaultValue={this.state.fornecedor.nome}
            onSearchItems={this.#searchFornecedores}
            onItemSelect={(item) => this.setState({ fornecedor: { id: item.id, nome: item.nome } })}
            placeholder="Pesquisar fornecedores"
            isInvalid={this.state.invalidInputs.fornecedor}
            errorMessage="Informe o fornecedor"
            key={'fornecedor' + this.state.fornecedor.id}
            isDisabled={this.state.iewOnly}
          />

          <FormControl flex="0.5" isInvalid={this.state.invalidInputs.tipoItensSolicitacao} isDisabled={this.state.viewOnly}>
            <FormControl.Label marginBottom="0">Tipo</FormControl.Label>
            <Select
              defaultValue={this.state.tipoItensSolicitacao?.toString() ?? '0'}
              onValueChange={(selectedItem) => this.setState({ tipoItensSolicitacao: parseInt(selectedItem) })}
              placeholder="Escolher tipo"
              key={'tipoItensSolicitacao' + this.state.tipoItensSolicitacao}
            >
              {
                Object.entries(constsController.tipoItensSolicitados).map(([id, desc]) => {
                  return <Select.Item label={desc} value={id} key={id} />;
                })
              }
            </Select>
            <FormControl.ErrorMessage marginTop="0">Selecione o tipo</FormControl.ErrorMessage>
          </FormControl>
        </ResponsiveStack>


        <ResponsiveStack space="2">
          <ResponsiveFormControl flex="0.5" isInvalid={this.state.invalidInputs.prioridade} isDisabled={this.state.viewOnly}>
            <FormControl.Label marginBottom="0">Prioridade</FormControl.Label>
            <Select
              defaultValue={this.state.prioridade?.toString() ?? '0'}
              onValueChange={(selectedItem) => this.setState({ prioridade: parseInt(selectedItem) })}
              placeholder="Escolher prioridade"
              key={'prioridade' + this.state.prioridade}
            >
              {
                Object.entries(constsController.prioridadesSolicitacao).map(([id, desc]) => {
                  return <Select.Item label={desc} value={id} key={id} />;
                })
              }
            </Select>
            <FormControl.ErrorMessage marginTop="0">Selecione a prioridade</FormControl.ErrorMessage>
          </ResponsiveFormControl>

          <ResponsiveFormControl flex="0.5" isInvalid={this.state.invalidInputs.tipo} isDisabled={this.state.viewOnly}>
            <FormControl.Label marginBottom="0">Modalidade</FormControl.Label>
            <Select
              defaultValue={this.state.tipo?.toString() ?? ''}
              onValueChange={(selectedItem) => this.setState({ tipo: parseInt(selectedItem) })}
              placeholder="Escolher a modalidade"
              key={'tipo' + this.state.tipo}
            >
              {
                Object.entries(constsController.tiposSolicitacao).map(([id, desc]) => {
                  return <Select.Item label={desc} value={id} key={id} />;
                })
              }
            </Select>
            <FormControl.ErrorMessage marginTop="0">Selecione o tipo</FormControl.ErrorMessage>
          </ResponsiveFormControl>
        </ResponsiveStack>

        <ResponsiveStack space="2">
          <SearchInput
            flex="0.5"
            label="Fundo"
            defaultValue={this.state.fundo.nome}
            onSearchItems={fundosController.searchFundos}
            onItemSelect={(item) => this.setState({ fundo: { id: item.id, nome: item.nome } })}
            placeholder="Pesquisar fundos "
            isInvalid={this.state.invalidInputs.fundo}
            errorMessage="Informe o fundo"
            key={'fundo' + this.state.fundo.id}
            isDisabled={this.state.viewOnly}
          />

          <SearchInput
            flex="0.5"
            label="Fonte do recurso"
            defaultValue={this.state.fonteRecurso.nome}
            onSearchItems={this.#searchFontesRecursos}
            onItemSelect={(item) => this.setState({ fonteRecurso: { id: item.id, nome: item.nome } })}
            placeholder="Pesquisar fontes de recurso"
            isInvalid={this.state.invalidInputs.fonteRecurso}
            errorMessage="Informe a fonte de recurso"
            key={'fonteRecurso' + this.state.fonteRecurso.id}
            isDisabled={this.state.viewOnly}
          />
        </ResponsiveStack>

        <ResponsiveStack space="2">
          <ResponsiveFormControl space="2" isInvalid={this.state.invalidInputs.justificativa} isDisabled={this.state.viewOnly}>
            <FormControl.Label marginBottom="0">Justificativa</FormControl.Label>
            <TextArea
              height="75"
              defaultValue={this.state.justificativa}
              onChangeText={(text) => this.setState({ justificativa: text })}
            />
            <FormControl.ErrorMessage marginTop="0">Selecione a justificativa</FormControl.ErrorMessage>
          </ResponsiveFormControl>
        </ResponsiveStack>

        {
          (this.#isTipoSolicitacaoContrato()) &&
          <HStack space="2">
            <SearchInput
              flex="auto"
              label="Contrato"
              defaultValue={this.state.contrato?.nome}
              onSearchItems={this.#searchContratos}
              onItemSelect={this.#handleContratoSelection}
              placeholder="Pesquisar contratos"
              isInvalid={this.state.invalidInputs.contrato}
              errorMessage="Informe o contrato"
              key={'contrato' + this.state.contrato?.id}
              isDisabled={this.state.viewOnly}
            />

            {(!isNaN(this.state.contrato.saldo)) &&
              <VStack space="2">
                <Text textAlign="center">Saldo do contrato</Text>
                <Text textAlign="center">
                  {
                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(this.#getSaldoAtual())
                  }
                </Text>
              </VStack>
            }
          </HStack>
        }

        <ResponsiveFormControl isInvalid={this.state.invalidInputs.produtos} isDisabled={this.state.viewOnly}>
          <VStack space={1}>
            <HStack space={2}>
              <Box flexDirection="row" alignItems="center">
                <Heading size="md">Produtos</Heading>
              </Box>
              <Spacer />
              {
                (!this.#isTipoSolicitacaoContrato()) &&
                <Button
                  variant="ghost"
                  leftIcon={<Icon as={FontAwesome5} name="plus" size="4" />}
                  onPress={this.#handleProdutoAdd}
                  isDisabled={this.state.viewOnly}
                >
                  Incluir novo produto
                </Button>
              }
            </HStack>

            <ProdutoList
              produtos={this.state.produtos}
              onDeleteItem={this.#handleDeleteProduto}
              onEditItem={this.#handleEditProduto}
              isDisabled={this.state.viewOnly}
              page={this.state.produtosPage}
              onChangePage={(page) => {
                this.setState({produtosPage: page});
              }}
            />
          </VStack>
          <FormControl.ErrorMessage marginTop="0">Informe no mínimo um produto</FormControl.ErrorMessage>
        </ResponsiveFormControl>

        <HStack space="2">
          {
            !this.state.creatingNewCompra &&
            <Button
              onPress={() => this.props.viewManager.showView('ViewRecebimentos', { idSolicitacao: this.props.params?.idSolicitacao, canReceive: this.state.canReceive })}
              leftIcon={<Icon as={FontAwesome5} name="clipboard-check" size="4" />}
              isDisabled={!this.state.canReceive}
            >
              Recebimentos
            </Button>
          }          
          {
            !this.state.creatingNewCompra &&
            <Button
              onPress={this.#onCopy}
              leftIcon={<Icon as={FontAwesome5} name="clipboard-check" size="4" />}
            >
              Duplicar
            </Button>
          }
          <Spacer />
          <Button
            onPress={this.#onSave}
            leftIcon={<Icon as={FontAwesome5} name="check" size="4" />}
            isDisabled={this.state.viewOnly}
          >
            {this.state.creatingNewCompra ? 'Cadastrar' : 'Alterar'}
          </Button>
        </HStack>

        {
          !this.state.creatingNewCompra &&
          <StatusPanel
            status={this.state.status}
            listaStatus={this.state.listaStatus}
            localizacao={authController.getCurrentUser().localizacaoPadrao}
            historicoStatus={this.state.historicoStatus}
            idSolicitacao={this.props.params.idSolicitacao}
            width="100%"
            maxWidth="600"
            alignSelf="center"
            marginBottom={10}
            onUpdateStatus={async () => await this.loadSolicitacaoCompra(this.props.params.idSolicitacao)}
            isDisabled={!(this.permicoes.includes("SolicitacoesComprasPolicies.AtualizarStatus"))}
          />
        }
        <AlertModal
          isOpen={this.state.alertModalOpen}
          onClose={() => this.setState({ alertModalOpen: false })}
          bodyText={this.state.alertModalText}
        />

        <CompraProdutoEditor
          isOpen={!!this.state.editingProduto}
          produto={this.state.editingProduto}
          onClose={() => this.setState({ editingProduto: null })}
          onSave={this.#handleProdutoSave}
          qtdEdit={this.state.editingProduto?.id > 0}
          key={"ProdutoInserter" + this.state.editingProduto?.id}
          isTipoSolicitacaoContrato={this.#isTipoSolicitacaoContrato()}
        />
      </VStack>
    );
  }

  #searchContratos = async (text) => {
    const contratos = await contratosController.searchContratos({
      text: text,
      idFornecedor: this.state.fornecedor?.id,
      idFundo: this.state.fundo?.id,
      semVencidos: true,
      semSaldo: true
    });
    return contratos.items;
  }

  #searchFornecedores = async (text) => {
    const fornecedores = await fornecedoresController.searchFornecedores(text);
    return fornecedores.items;
  }

  #searchFontesRecursos = async (text) => {
    const fontesRecursos = await fontesRecursosController.searchFontesRecursos(text);
    return fontesRecursos.items;
  }

  #handleContratoSelection = async (contrato) => {
    if (contrato?.id) {

      if (contrato.saldo === 0) {
        this.#showAlert("O contrato selecionado não possui saldo.");
      }
      else if (dayjs().isAfter(dayjs(contrato.vencimento))) {
        this.#showAlert("O contrato selecionado está vencido.");
      }
      else if (dayjs().add(1, 'month').isAfter(dayjs(contrato.vencimento))) {
        this.#showAlert("O contrato selecionado está próximo ao vencimento.");
      }

      const itensLicitacao = (await licitacoesController.getLicitacao(contrato.licitacaoId)).itens;
      const novosProdutos = itensLicitacao?.map((item, index) => {
        return {
          id: this.produtoIdGen--,
          itemId: item.id, //Nesse caso o id do item é o itemID - que é o item da licitação
          codigo: item.codigo,
          marca: item.marca,
          tipo: item.tipo,
          descricao: item.descricao,
          unidade: item.unidade ?? 'Kg',
          qtd: 0,
          qtdOriginal: 0,
          valorUnitario: item.valorUnitario ?? 1,
          saldo: item.saldo,
          saldoOriginal: item.saldo,
          lote: item.lote,
          quantidadeMinima: item.quantidadeMinima,
          quantidadeMaxima: item.quantidadeMaxima,
        }
      }).filter(novoProduto => !this.state.produtos.find(produtoExistente => produtoExistente.codigo === novoProduto.codigo));

      const produtos = this.state.produtos.slice().concat(novosProdutos);

      this.setState({
        contrato: {
          id: contrato.id,
          nome: contrato.nome,
          saldo: contrato.saldo
        },
        produtos: produtos ?? []
      });
    } else {
      this.setState({
        contrato: {
          id: 0,
          nome: ''
        },
        produtos: []
      });
    }
  }

  #handleProdutoAdd = () => {
    const newProduto = {
      id: this.produtoIdGen--,
      itemId: this.produtoIdGen--,
      codigo: '',
      marca: '',
      tipo: 1,
      descricao: '',
      unidade: '',
      qtd: 0,
      valorUnitario: 0,
    }

    this.setState({ editingProduto: newProduto });
  }

  #handleProdutoSave = (produto) => {
    let existingProduto = this.state.produtos.find((p) => p.id === produto.id);

    if (existingProduto) {
      Object.assign(existingProduto, produto);
      existingProduto.saldo = existingProduto.saldoOriginal - (existingProduto.qtd - existingProduto.qtdOriginal);

      this.setState({ produtos: this.state.produtos.slice(), editingProduto: null });
    } else {
      const newProdutos = this.state.produtos.slice();
      newProdutos.push(produto);
      this.setState({
        produtos: newProdutos,
        editingProduto: null,
        produtosPage: newProdutos.length,
      });
    }
  }

  #onSave = async () => {
    const invalidInputs = {
      solicitante: this.state.solicitante.length === 0,
      destino: this.state.destino.length === 0,
      fornecedor: this.state.fornecedor.id === 0,
      prioridade: this.state.prioridade === 0,
      tipo: this.state.tipo === 0,
      fundo: this.state.fundo.id === 0,
      contrato: this.#isTipoSolicitacaoContrato() && this.state.contrato.id === 0,
      justificativa: this.state.justificativa.length === 0,
      produtos: this.state.produtos.length === 0 || this.state.produtos.filter(p => p.qtd > 0).length === 0
    }

    let allInputIsValid = true;
    for (let key in invalidInputs) {
      if (invalidInputs[key]) {
        allInputIsValid = false;
        break;
      }
    }

    if (allInputIsValid) {
      const solicitacaoCompra = {
        "solicitante": this.state.solicitante,
        "destino": this.state.destino,
        "fornecedorId": this.state.fornecedor.id,
        "prioridade": this.state.prioridade,
        "tipo": this.state.tipo,
        "fundoId": this.state.fundo.id,
        "fonteRecursoId": this.state.fonteRecurso.id,
        "justificativa": this.state.justificativa,
        "tipoItensSolicitacao": this.state.tipoItensSolicitacao,
        "itens": this.state.produtos.filter(item => item.qtd > 0).map((item) => {
          return {
            "id": item.id,
            "itemId": item.itemId,
            "tipo": item.tipo,
            "codigo": item.codigo,
            "marca": item.marca,
            "descricao": item.descricao,
            "unidade": item.unidade,
            "valorUnitario": item.valorUnitario,
            "quantidade": item.qtd
          }
        })
      };

      if (this.#isTipoSolicitacaoContrato()) {
        solicitacaoCompra["contratoId"] = this.state.contrato.id;
      }

      let success = false;
      this.setState({ await: true });
      if (this.state.creatingNewCompra) {
        success = await comprasController.insertSolicitacaoCompra(solicitacaoCompra);
      } else {
        success = await comprasController.updateSolicitacaoCompra(solicitacaoCompra, this.props.params.idSolicitacao);
      }
      this.setState({ await: false });
      if (success)
        this.props.viewManager.showView('ViewCompras');
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }

  #onCopy = () => {
    console.log("onCopy");
    this.setState({ 
      id: 0,
      creatingNewCompra: true,
      viewOnly: false,
      status: 0
     });
  }

  #handleDeleteProduto = (produto) => {
    let removedItem = this.state.produtos.find(p => p.id === produto.id);
    let newProdutos = this.state.produtos.filter(p => p.id !== produto.id);

    this.setState({
      produtos: newProdutos,
      contrato: {
        id: this.state.contrato.id,
        nome: this.state.contrato.nome,
        saldo: this.#getSaldoAtual() + removedItem.qtd * removedItem.valorUnitario
      },
    });
  }

  #handleEditProduto = (produto) => {
    this.setState({ editingProduto: produto });
  }

  #getSaldoAtual = () => {
    let valorTotal = 0.0;
    this.state.produtos.forEach((produto) => {
      valorTotal += (produto.qtd - produto.qtdOriginal) * produto.valorUnitario;
    });
    return this.state.contrato.saldo - valorTotal;
  }

  #isTipoSolicitacaoContrato = () => {
    return [1, 2, 5, 6, 8, 9, 10, 11, 12, 13].includes(this.state.tipo);
  }

  #showAlert = (text) => {
    this.setState({
      alertModalOpen: true,
      alertModalText: text
    });
  }
}

export default ViewCompra;