import React from "react";

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Pressable,
  Spacer,
  HStack,
  FlatList,
  Icon,
  Spinner
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { GridRow, GridView, ResponsiveGridItem } from './../components/GridView';
import { ListNavigator } from '../components/ListNavigator';
import { contratosController } from "../controllers/ContratosController";
import TextSearchPanel from "../components/TextSearchPanel";
import { authController } from "../controllers/AuthController";
import ConfirmModal from "../components/ConfirmModal";
import CommonActionButtons from "../components/CommonActionButtons";
import SearchPanelContratos from "./ViewContratosSearchPanel";
import { TooltipPressable } from "../components/TooltipPressable";

const NAVIGATOR_ITENS_PER_PAGE = 15;

class ViewContratos extends React.Component {

  constructor(props) {
    super(props);

    let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.notCriar = !(permicoes.includes("ContratosPolicies.Criar"));
    this.notRemover = !(permicoes.includes("ContratosPolicies.Remover"));

    this.state = {
      searching: false,
      contratoForDeletion: 0,
      currentPage: 1,
      countItens: 0,
      contratos: []
    }
    if (localStorage.getItem('page') !== 'contrato') {
      localStorage.removeItem('filtro');
      localStorage.removeItem('currentPage');
      localStorage.setItem('page', 'contrato');
    } else {
      if (localStorage.getItem('currentPage'))
        this.state.currentPage = localStorage.getItem('currentPage');
      else  
        this.state.currentPage = 1;
    }
  }

  componentDidMount() {
    this.#loadContratos();
  }

  render() {
    return (
      <Center flex={1}>
        <VStack flex={1} width={'100%'} space={2}>
          <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
            <Heading color="white">Contratos</Heading>
            <Spacer />
            <Button
              isDisabled={this.notCriar}
              my="1"
              leftIcon={<Icon as={FontAwesome5} name="plus" size="4" />}
              onPress={() => { this.props.viewManager.showView('ViewContrato', { insertion: true }) }}>
              Adicionar
            </Button>
          </HStack>

          <SearchPanelContratos
            onSearch={(options) => {
              this.state.currentPage = 1;
              this.#searchContratos(options);
            }}
          />

          <ListNavigator
            key={'ListNavigator' + this.state.currentPage}
            page={this.state.currentPage}
            countItens={this.state.countItens}
            itensPerPage={NAVIGATOR_ITENS_PER_PAGE}
            onChangePage={(page) => {
              this.setState({ currentPage: page }, () => this.#loadContratos());
            }}
          />

          <GridView
            borderRadius="4"
            data={this.state.searching ? [] : this.state.contratos}
            keyExtractor={(item) => { return item.id.toString(); }}
            columnCaptions={[
              "Nome",
              "Licitação",
              "Fundo",
              "Numero",
              "Vencimento",
              "Valor",
              "Saldo",
              ""
            ]}
            ListEmptyComponent={
              (
                this.state.searching &&
                <Spinner marginY={3} size="lg" />
              )
              ||
              (
                <Center>
                  <Text>Sem resultados</Text>
                </Center>
              )
            }
            renderGridItem={(item, index, separators, props) => {
              return (
                <TooltipPressable
                  onPress={() => { this.props.viewManager.showView('ViewContrato', { idContrato: item.id }) }}>
                  {
                    ({ isHovered, isFocused, isPressed }) => {
                      return (
                        <ResponsiveGridItem flex="1"
                          tooltipVisibool={true}
                          values={[
                            item.descricao,
                            item.licitacao,
                            item.fundoDotacao,
                            item.numero,
                            new Date(item.vencimento).toLocaleDateString(),
                            new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(item.valor),
                            new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(item.saldo),
                            <CommonActionButtons flex={0.1}
                              isDisabled={this.notRemover}
                              onDelete={() => this.setState({ contratoForDeletion: item.id })}
                            />,
                          ]}
                          backgroundColor={isPressed ? "primary.200" : isHovered ? "primary.100" : (index % 2 === 0) ? "coolGray.100" : null}
                          gridProps={props}
                          width="100%"
                        />
                      )
                    }
                  }
                </TooltipPressable>
              )
            }}
          />
        </VStack>
        <ConfirmModal
          isOpen={this.state.contratoForDeletion > 0}
          onClose={() => this.setState({ contratoForDeletion: 0 })}
          onConfirm={() => this.#deleteContrato(this.state.contratoForDeletion)}
          headerText="Excluir contrato"
          bodyText="Atenção! O contrato será excluído. Confirma esta ação?"
          buttonConfirmText="Excluir"
        />
      </Center>
    );
  }

  #searchContratos = async (searchOptions) => {
    this.setState({ searching: true });
    localStorage.setItem('filtro', JSON.stringify(searchOptions));
    localStorage.setItem('currentPage', this.state.currentPage);
    const contratos = await contratosController.searchContratos({
      text: searchOptions.searchedText,
      startDate: searchOptions.startDate,
      finalDate: searchOptions.finalDate,
      page: this.state.currentPage,
      itensPerPage: NAVIGATOR_ITENS_PER_PAGE,
      semVencidos: false,
      semSaldo: false
    });
    this.setState({ searching: false, contratos: contratos.items, countItens: contratos.totalCount });
  }

  #deleteContrato = async (id) => {
    this.setState({ contratoForDeletion: 0 });
    this.setState({ await: true });
    if (await contratosController.deleteContrato(id)) {
      this.#loadContratos();
    }
    this.setState({ await: false });
  }

  #loadContratos = () => {
    if (localStorage.getItem('page') === 'contrato') {
      const filtroStr = localStorage.getItem('filtro');
      if (filtroStr) {
        const filtro = JSON.parse(filtroStr);
        this.#searchContratos(filtro);
      } else
        this.#searchContratos({});
    }
  }
}

export default ViewContratos;
