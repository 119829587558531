import React from "react";

import {
  Center,
  Button,
  AlertDialog,
} from 'native-base';


class AlertModal extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AlertDialog isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{this.props.headerText ?? 'Aviso'}</AlertDialog.Header>
          <AlertDialog.Body>
            {this.props.bodyText}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button onPress={this.props.onClose}>
                OK
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  }
};


export default AlertModal;