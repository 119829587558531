import React from "react";

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Modal,
  Spacer,
  HStack,
  FormControl,
  Spinner,
  Box,
  Icon,
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';
import { empenhosController } from "../controllers/EmpenhosController";
import MaskedInput from "../components/MaskedInput";
import { authController } from "../controllers/AuthController";
import { ResponsiveStack, ResponsiveFormControl } from "../components/ResponsiveUtils";

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CurrencyInput from "../components/CurrencyInput";
dayjs.extend(customParseFormat);

class ViewContratoEmpenho extends React.Component {

  constructor(props) {
    super(props);
    this.creatingNewEmpenho = !!this.props.params?.insertion;

    //let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.viewOnly = false;// !(permicoes.includes("FundosPolicies.Criar") || permicoes.includes("FundosPolicies.Atualizar"));

    this.state = {
      await: false,
      id: 0,
      descricao: '',
      usuario: '',
      valor: 0,
      data: '',
      invalidInputs: []
    }
  }

  componentDidMount() {
    this.loadEmpenho();
  }

  async loadEmpenho() {
    if (this.creatingNewEmpenho) {
      this.setState({
        id: 0,
        usuario: authController.getCurrentUser().nome,
        data: dayjs().format('DD/MM/YYYY'),
      });
    } else {
      const empenho = await empenhosController.getEmpenho(this.props.params.idEmpenho);
      this.setState({
        id: empenho.id,
        descricao: empenho.descricao ?? '',
        usuario: empenho.usuario ?? '',
        valor: empenho.valor ?? 0,
        data: dayjs(empenho.data).format('DD/MM/YYYY') ?? '',
      });
    }
  }

  render() {
    if (!this.creatingNewEmpenho && this.state.id === 0) {
      return (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      );
    }

    return (
      <VStack flex={1} space={2}>
        <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
          <Heading color="white">
            {
              this.creatingNewEmpenho ? 'Cadastrar novo Empenho' :
                this.viewOnly ? 'Visualizar Empenho' : 'Alterar Empenho'
            }
          </Heading>
          <Spacer />
          <Button
            my={1}
            leftIcon={<Icon as={FontAwesome5} name="arrow-left" size="4" />}
            onPress={() => { this.props.viewManager.showView('ViewContrato', { idContrato: this.props.params.idContrato }); }}>
            Voltar
          </Button>
        </HStack>

        <Modal
          isOpen={this.state.await}
          onClose={() => this.setState({ await: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>

        <ResponsiveStack my="2" space="2">
          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('descricao') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Descrição</FormControl.Label>
            <Input defaultValue={this.state.descricao} onChangeText={(text) => this.setState({ descricao: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe a descrição</FormControl.ErrorMessage>
          </ResponsiveFormControl>

          <ResponsiveFormControl flex={1} isDisabled={true}>
            <FormControl.Label marginBottom="0">Usuário</FormControl.Label>
            <Input defaultValue={this.state.usuario} onChangeText={(text) => this.setState({ usuario: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe o usuário</FormControl.ErrorMessage>
          </ResponsiveFormControl>

          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('data') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Data</FormControl.Label>
            <MaskedInput
              mask="99/99/9999"
              value={this.state.data}
              placeholder="dd/mm/aaaa"
              onChangeMaskedText={(text) => this.setState({ data: text })}
            />
            <FormControl.ErrorMessage marginTop="0">Informe a data</FormControl.ErrorMessage>
          </ResponsiveFormControl>

          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('valor') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Valor</FormControl.Label>
            <CurrencyInput
              value={this.state.valor}
              onChangeValue={(newValue) => { this.setState({ valor: newValue }) }}
              key={"valor" + this.state.id.toString()}
            />
            <FormControl.ErrorMessage marginTop="0">Informe valor &gt;= 0</FormControl.ErrorMessage>
          </ResponsiveFormControl>
        </ResponsiveStack>

        <HStack my="2" space="2">
          <Spacer />
          <Button
            onPress={() => { this.#onSave() }}
            leftIcon={<Icon as={FontAwesome5} name="check" size="4" />}
            isDisabled={this.viewOnly}
          >
            {this.creatingNewEmpenho ? 'Cadastrar' : 'Alterar'}
          </Button>
        </HStack>
      </VStack>
    );
  }

  #validate = () => {
    return Object.getOwnPropertyNames(this.state).filter((propertyName) => {
      if (['id', 'invalidInputs', 'await', 'usuario'].indexOf(propertyName) !== -1)
        return false;

      if (propertyName === 'data') {
        return !dayjs(this.state.data, 'DD/MM/YYYY', true).isValid();
      }

      switch (typeof this.state[propertyName]) {
        case 'string':
          return this.state[propertyName].length === 0;

        case 'number':
          return this.state[propertyName] === 0;

        default:
          return true;
      }
    });
  }

  #onSave = async () => {
    const invalidInputs = this.#validate();
    if (invalidInputs.length === 0) {
      const empenho = {
        "descricao": this.state.descricao,
        "contratoId": this.props.params.idContrato,
        "data": dayjs(this.state.data, 'DD/MM/YYYY').toJSON(),
        "valor": this.state.valor,
      }

      let success = false;
      this.setState({ await: true });
      if (this.creatingNewEmpenho) {
        success = await empenhosController.insertEmpenho(empenho);
      } else {
        success = await empenhosController.updateEmpenho(empenho, this.props.params.idEmpenho);
      }
      this.setState({ await: false });

      if (success)
        this.props.viewManager.showView('ViewContrato', { idContrato: this.props.params.idContrato });
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }
}

export default ViewContratoEmpenho;