import React from "react";
import {
  Center,
  VStack,
  Button,
  Heading,
  Spacer,
  HStack,
  Icon} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';
import Dropzone from 'react-dropzone';

import { importacaoController } from "../controllers/ImportacaoController";

const DropZone = (props) => {
  return (
    <Dropzone onDrop={props.onDrop}>
      {({getRootProps, getInputProps}) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Center {...props}>
              <Icon
                as={<FontAwesome5 name="folder-open"/>}
                size="xl"
              />
              <Heading size="md">Clique ou arraste um arquivo aqui</Heading>
            </Center>
          </div>
        </section>
      )}
    </Dropzone>
  );
}


class ViewImportacao extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      importing: false,
      result: ''
    }
  }

  render() {
    return (
      <Center flex={1}>
        <VStack flex={1} width={'100%'} space={2}>
          <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
            <Heading color="white" paddingBottom={2}>Importar licitações</Heading>
            <Spacer />
          </HStack>
          {
            (
              this.state.selectedFile &&
              <Center
                borderWidth={1}
                borderRadius={4}
                borderColor="coolGray.300"
                height={300}
              >
                <Heading size="sm" margin={2}>{this.state.selectedFile.name}</Heading>
                <HStack space={2}>
                  <Button
                    width={130}
                    isDisabled={this.state.importing || this.state.result === 'success'}
                    isLoading={this.state.importing}
                    isLoadingText={"Importando..."}
                    leftIcon={
                      <Icon
                        as={FontAwesome5}
                        name={(this.state.result === 'success'? 'check' : 'file-import')}
                        size="4"
                      />
                    }
                    onPress={this.#importaLicitacao}
                  >
                    {(this.state.result === 'success'? 'Importado!' : 'Importar')}
                  </Button>
                  <Button
                    variant="outline"
                    width={130}
                    isDisabled={this.state.importing || this.state.result === 'success'}
                    onPress={() => this.setState({
                      selectedFile: null,
                      importing: false,
                      result: ''
                    })}
                  >
                    Cancelar
                  </Button>
                </HStack>
              </Center>
            )
            ||
            (
              <DropZone
                borderWidth={1}
                borderRadius={4}
                borderColor="coolGray.300"
                height={300}
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles?.length > 0)
                    this.setState({selectedFile: acceptedFiles[0]})
                }}
              />
            )
          }
        </VStack>
      </Center>  
    );
  }

  #importaLicitacao = async () => {
    this.setState({importing: true});
    const licitacao = await importacaoController.importLicitacao(this.state.selectedFile);
    this.setState({importing: false, result: (licitacao? 'success' : '')});
    if (licitacao) {
      setTimeout(() => {
        this.props.viewManager.showView('ViewLicitacao', { idLicitacao: licitacao.id });
      }, 100);
    }
  }
}

export default ViewImportacao;