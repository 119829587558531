import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController';
import { notificationController } from "./NotificationController";

class LicitacoesController {
  getLicitacao = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Licitacoes/' + id.toString() + '?includeItens=true&comSaldoItens=true', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao carregar a licitação', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao inserir a licitação', error);
      return null;
    }
  }

  searchLicitacoes = async (searchOptions, page, itensPerPage) => {
    try {
      let query = ENDPOINT + '/api/Licitacoes?countTotal=true';
      if (searchOptions.searchedText) {
        query += '&q=' + searchOptions.searchedText;
      }
      if (searchOptions.startDate) {
        query += '&dataInicial=' + searchOptions.startDate;
      }
      if (searchOptions.finalDate) {
        query += '&dataFinal=' + searchOptions.finalDate;
      }

      if (page) {
        const skip = (page - 1) * itensPerPage;
        query += '&skip=' + skip.toString() + '&limit=' + itensPerPage.toString();
      } else {
        query += '&limit=50';
      }

      const response = await fetch(query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        const licitacoes = await response.json();
        const newItems = licitacoes.items.map((licitacao) => {
          return { ...licitacao, nome: licitacao.descricao + ' [' + licitacao.fornecedor + ']'}
        });
        licitacoes.items = newItems;
        return licitacoes;
      } else {
        notificationController.publishError('Falha ao pesquisar as licitações', await response.text());
        return [];
      }
    } catch (error) {
      notificationController.publishError('Falha ao pesquisar as licitações', error);
      return [];
    }
  }

  insertLicitacao = async (licitacao) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Licitacoes', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(licitacao)
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao inserir a licitação', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao inserir a licitação', error);
      return null;
    }
  }
  
  updateLicitacao = async (licitacao, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Licitacoes/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(licitacao)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar a licitação', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar a licitação', error);
      return false;
    }
  }

  updateSaldoLicitacao = async (saldos, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Licitacoes/atualiza-saldo/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(saldos)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar o saldo da licitação', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar o saldo da licitação', error);
      return false;
    }
  }

  deleteLicitacao = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Licitacoes/' + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao excluir a licitação', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao excluir a licitação', error);
      return false;
    }
  }
}

export const licitacoesController = new LicitacoesController();
