import React from "react";
import { authController } from "../controllers/AuthController";

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  FormControl,
} from 'native-base';

import { constsController } from "../controllers/ConstsController";

class AuthenticationScreen extends React.Component {
  #unsubscribeFocus;

  constructor (props) {
    super(props);

    this.state = {
      userName: '',
      userPassword: '',
      authenticating: false,
      authError: false
    }
    this.userInputRef = React.createRef();
    this.passwordInputRef = React.createRef();
  }

  autenticateUser = async (user, password) => {
    if (user?.length === 0 || password?.length === 0) {
      this.setState({authenticating: false, authError: true});
      return;
    }

    this.setState({authenticating: true});
    if (await authController.authenticateUser(user, password)) {
      await this.#showMainScreen();
    } else {
      this.setState({authenticating: false, authError: true});
    }
  }

  handleUserChange = (event) => {
    this.setState({ userName: event.target.value, authError: false });
  }

  handlePasswordChange = (event) => {
    this.setState({ userPassword: event.target.value, authError: false });
  }

  componentDidMount() {
    this.#unsubscribeFocus = this.props.navigation.addListener('focus', async () => {
      this.setState({
        authenticating: true,
        userName: '',
        userPassword: '',
      });
      if (await authController.checkSavedToken()) {
        await this.#showMainScreen();
      } else {
        this.setState({authenticating: false});
      }
    });
  }

  componentWillUnmount() {
    this.#unsubscribeFocus?.();
  }

  render() {
    return (
      <Center flex={1}>
        <VStack flex={1} width={'70%'} space={2} maxWidth={400}>

          <Center flex={0.5}>
            <Heading size="2xl">Simple</Heading>
          </Center>

          <FormControl isDisabled={this.state.authenticating}>
            <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Usuário</FormControl.Label>
            <Input
              ref={this.userInputRef}
              value={this.state.userName}
              placeholder="Informe o usuário"
              onChange={this.handleUserChange}
              onKeyPress={({ nativeEvent: { key: keyValue } }) => {
                if(keyValue === 'Enter') {
                  this.passwordInputRef.current?.focus();
                }
              }}
            />
          </FormControl>

          <FormControl isDisabled={this.state.authenticating}>
            <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Senha</FormControl.Label>
            <Input
              ref={this.passwordInputRef}
              value={this.state.userPassword}
              placeholder="Informe a senha"
              type="password"
              onChange={this.handlePasswordChange}
              onKeyPress={({ nativeEvent: { key: keyValue } }) => {
                if(keyValue === 'Enter') {
                  this.autenticateUser(this.state.userName, this.state.userPassword);
                }
              }}
            />
          </FormControl>

          <Button
            onPress={this.autenticateUser.bind(this, this.state.userName, this.state.userPassword)}
            disabled={this.state.authenticating}
            isLoading={this.state.authenticating}
            isLoadingText={"Entrando..."}
          >
            Entrar
          </Button>

          <Text color="red.500" textAlign="center">
            {(this.state.authError)? "Usuário ou senha inválidos": "  "}
          </Text>

        </VStack>
      </Center>
    );
  }

  #showMainScreen = async () => {
    await authController.fetchCurrentUser();
    await constsController.loadConsts();
    this.props.navigation.navigate('MainScreen');
  }
}

export default AuthenticationScreen;