import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Input,
  Button,
  FormControl,
  Icon,
  VStack,
  Text,
  Box,
  Popover,
  ScrollView,
  Select
} from 'native-base';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

import MaskedInput from "../components/MaskedInput";
import { ResponsiveComponentSelector, ResponsiveStack } from "../components/ResponsiveUtils";

class SearchPanelBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedText: '',
      startDate: '',
      finalDate: '',
      year: 'none',
      invalidInputs: []
    };
    const filtroStr = localStorage.getItem('filtro');
    if (filtroStr) {
      const options = JSON.parse(filtroStr);
      if (options) {
        this.state.searchedText = options.searchedText ? options.searchedText : '';
        this.state.startDate = options.startDate ? options.startDate : '';
        this.state.finalDate = options.finalDate ? options.finalDate : '';
      }
    }
    this.timeoutID = -1;
  }

  render() {
    return (
      <Box {...this.props}>
        <ResponsiveStack space={1} flex={1} height={40}>
          <FormControl flex={1}>
            <FormControl.Label marginBottom="0">Texto</FormControl.Label>
            <Input defaultValue={this.state.searchedText} onChangeText={(text) => this.setState({ searchedText: text }, this.#onSearch)} />
          </FormControl>

          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('startDate') !== -1}>
            <FormControl.Label marginBottom="0">Data inicial</FormControl.Label>
            <MaskedInput
              mask="99/99/9999"
              placeholder="dd/mm/aaaa"
              value={this.state.startDate}
              onChangeMaskedText={(text) => this.setState({ startDate: text, year: 'none' }, this.#onSearch)}
            />
            <FormControl.ErrorMessage marginTop="0">Data inválida</FormControl.ErrorMessage>
          </FormControl>

          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('finalDate') !== -1}>
            <FormControl.Label marginBottom="0">Data final</FormControl.Label>
            <MaskedInput
              mask="99/99/9999"
              placeholder="dd/mm/aaaa"
              value={this.state.finalDate}
              onChangeMaskedText={(text) => this.setState({ finalDate: text, year: 'none' }, this.#onSearch)}
            />
            <FormControl.ErrorMessage marginTop="0">Data inválida</FormControl.ErrorMessage>
          </FormControl>

          <FormControl flex={1} isInvalid={false}>
            <FormControl.Label marginBottom="0">Ano</FormControl.Label>
            <Select
              placeholder="Selecione o ano"
              selectedValue={this.state.year}
              onValueChange={(selectedItem) => {
                if (selectedItem !== 'none') {
                  const startDate = '01/01/' + selectedItem;
                  const finalDate = '31/12/' + selectedItem;
                  this.setState({ startDate: startDate, finalDate: finalDate, year: selectedItem }, this.#onSearch);
                } else {
                  this.setState({ year: selectedItem });
                }
              }}
            >
              {
                (() => {
                  let years = [];
                  years.push(<Select.Item label={'Não selecionado'} value={'none'} key={'none'} />);
                  for (let year = new Date().getFullYear(); year > 1989; year--) {
                    years.push(<Select.Item label={year.toString()} value={year.toString()} key={year.toString()} />);
                  }
                  return years;
                })()
              }
            </Select>
            <FormControl.ErrorMessage marginTop="0">Ano inválido</FormControl.ErrorMessage>
          </FormControl>

          <VStack flex={1}>
            <Text>   </Text>
            <Button
              variant="outline"
              size="sm"
              leftIcon={<Icon as={FontAwesome5} name="search" size="4" />}
              onPress={this.#onSearch}
            >
              Buscar
            </Button>
          </VStack>
        </ResponsiveStack>
      </Box>
    );
  }

  #getFilter = () => {
    const invalidInputs = [];
    if (this.state.startDate)
      if (this.state.startDate.length > 0 && !dayjs(this.state.startDate, 'DD/MM/YYYY', true).isValid()) {
        invalidInputs.push('startDate');
      } if (this.state.finalDate)
      if (this.state.finalDate.length > 0 && !dayjs(this.state.finalDate, 'DD/MM/YYYY', true).isValid()) {
        invalidInputs.push('finalDate');
      }

    if (invalidInputs.length === 0) {
      this.setState({ invalidInputs: [] });
      return {
        "searchedText": this.state.searchedText,
        "startDate": dayjs(this.state.startDate, 'DD/MM/YYYY', true).toJSON(),
        "finalDate": dayjs(this.state.finalDate, 'DD/MM/YYYY', true).toJSON()
      };
    } else {
      this.setState({ invalidInputs: invalidInputs });
      return null;
    }
  }

  #onSearch = () => {
    if (this.timeoutID > 0) {
      clearTimeout(this.timeoutID);
    }

    this.timeoutID = setTimeout(() => {
      this.timeoutID = -1;
      const filter = this.#getFilter();
      if (filter) {
        this.props.onSearch?.(filter);
      }
    }, 400);
  }
}


class SearchPanelPortrait extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopOverOpen: false
    }
  }

  render() {
    return (
      <Popover
        isOpen={this.state.isPopOverOpen}
        onOpen={() => this.setState({ isPopOverOpen: true })}
        onClose={() => this.setState({ isPopOverOpen: false })}
        trigger={(triggerProps) => {
          return (
            <Button
              variant="outline"
              size="sm"
              leftIcon={<Icon as={FontAwesome5} name="search" size="4" />}
              {...triggerProps}
            >
              Pesquisar
            </Button>
          )
        }}>
        <Popover.Content>
          <Popover.Arrow />
          <Popover.CloseButton />
          <Popover.Header>Pesquisar recebimentos</Popover.Header>
          <Popover.Body height={500} >
            <ScrollView width={300}>
              <SearchPanelBody {...this.props} />
            </ScrollView>
          </Popover.Body>
        </Popover.Content>
      </Popover>
    );
  }
}

export default function SearchPanelContratos(props) {
  return (
    <ResponsiveComponentSelector
      portraitComponent={
        <SearchPanelPortrait {...props} />
      }
      landscapeComponent={
        <SearchPanelBody {...props} />
      }
    />
  );
}