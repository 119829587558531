import React from "react";

import {
  Box,
  Button,
  HStack,
  FlatList,
  Icon,
  Center,
  Spacer,
  VStack,
  ScrollView,
  Popover,
  IconButton,
  Input,
  Tooltip,
  Text,
} from 'native-base';

import { FontAwesome5 } from '@expo/vector-icons';

const NavigatorButton = (props) => {
  return (
    <Tooltip label={props.tooltip} openDelay={500} placement="top">
      <IconButton
        isDisabled={props.isDisabled}
        variant={props.variant ?? "ghost"}
        padding="2"
        onPress={props.onPress}
        _icon={{
          as: FontAwesome5,
          name: props.iconName,
          size: "5",
          color: props.color ?? "coolGray.700",
          textAlign: "center",
        }}
      />
    </Tooltip>
  )
}


export class ListNavigator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editText: '1'
    }
  }

  render() {
    return (
      <HStack space={1} alignItems="center">
        <Text>
          Página
        </Text>
        <NavigatorButton
          isDisabled={this.#getCurrentPage() === 1}
          iconName="fast-backward"
          tooltip="Ir para a primeira página"
          onPress={() => this.#updateCurrentPage('fast-backward')}
        />
        <NavigatorButton
          isDisabled={this.#getCurrentPage() === 1}
          iconName="backward"
          tooltip="Ir para a página anterior"
          onPress={() => this.#updateCurrentPage('backward')}
        />
        <Input
          size="sm"
          width={38}
          value={this.#getCurrentPage().toString()}
          onChangeText={(text) => this.setState({ editText: text })}
          onEndEditing={() => this.#updateCurrentPage(this.state.editText)}
          onBlur={() => this.#updateCurrentPage(this.state.editText)}
          selectTextOnFocus={true}
        />
        <NavigatorButton
          isDisabled={this.#getCurrentPage() === this.#getCountPages()}
          iconName="forward"
          tooltip="Ir para a próxima página"
          onPress={() => this.#updateCurrentPage('forward')}
        />
        <NavigatorButton
          isDisabled={this.#getCurrentPage() === this.#getCountPages()}
          iconName="fast-forward"
          tooltip="Ir para a última página"
          onPress={() => this.#updateCurrentPage('fast-forward')}
        />
        <Text>
          de {this.#getCountPages()} páginas
        </Text>
      </HStack>
    );
  }

  #getCountPages = () => {
    let countPages = Math.ceil(this.props.countItens / this.props.itensPerPage);
    if (countPages === 0)
      countPages = 1;

    return countPages;
  }

  #updateCurrentPage = (direction) => {
    let newPage = 1;
    switch (direction) {
      case 'forward':
        newPage = this.#getCurrentPage() + 1;
        break;
      case 'backward':
        newPage = this.#getCurrentPage() - 1;
        break;
      case 'fast-forward':
        newPage = this.#getCountPages()
        break;
      case 'fast-backward':
        newPage = 1;
        break;
      default: {
        newPage = parseInt(direction);
        if (isNaN(newPage)) {
          newPage = 1;
        }
      }
    }

    if (newPage > this.#getCountPages()) {
      newPage = this.#getCountPages();
    }

    if (newPage < 1) {
      newPage = 1;
    }

    this.setState({ editText: newPage.toString() }, () => this.props.onChangePage?.(newPage));
  }

  #getCurrentPage = () => {
    const lastPage = this.#getCountPages();
    if (+this.props.page > lastPage) {
      return lastPage;
    } else if (+this.props.page < 1) {
      return 1;
    } else {
      return +this.props.page;
    }
  }
}