import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Pressable,
  Spacer,
  HStack,
  FormControl,
  Box,
  FlatList,
  IconButton,
  Spinner,
  Popover,
  Icon
} from 'native-base';
import { GridRow } from "./GridView";


class SearchInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      inputText: this.props.defaultValue,
      searchItems: [],
      searching: false,
      isPopOverOpen: false,
      isInputFocused: false,
    }
    this.timeoutID = -1;
  }

  render() {
    return (
      <FormControl
        flex={this.props.flex}
        isDisabled={this.props.isDisabled}
        isInvalid={this.props.isInvalid}
      >
        <FormControl.Label _text={{ isTruncated: true }} marginBottom="0" >{this.props.label}</FormControl.Label>
        <HStack>
          <Input
            flex="auto"
            onFocus={() => this.setState({ isInputFocused: true })}
            onBlur={() => this.setState({ isInputFocused: false })}
            value={this.state.inputText}
            placeholder={this.props.placeholder}
            isReadOnly={true}
            borderRightWidth={(this.props.isDisabled) ? undefined : 0}
            borderBottomRightRadius={(this.props.isDisabled) ? undefined : 0}
            borderTopRightRadius={(this.props.isDisabled) ? undefined : 0}
            borderColor="coolGray.200"
            InputRightElement={
              this.state.inputText.length > 0 && !this.props.isDisabled &&
              <IconButton
                isDisabled={this.props.isDisabled}
                onPress={() => this.props.onItemSelect?.({ id: 0, nome: '' })}
                marginRight={1}
                variant="ghost"
                colorScheme="gray"
                size="7"
                justifyContent="center"
                _icon={{
                  as: FontAwesome5,
                  name: "times",
                  size: "4",
                  textAlign: "center",
                  alignSelf: "center",
                  color: "gray.500"
                }}
              />
            }
          />
          <Popover
            isOpen={this.state.isPopOverOpen}
            onOpen={this.#handlePopoverOpen}
            onClose={() => this.setState({ isPopOverOpen: false })}
            trigger={(triggerProps) => {
              if (this.props.isDisabled) {
                return undefined;
              }
              return (
                <IconButton
                  isDisabled={this.props.isDisabled}
                  variant="outline"
                  colorScheme="gray"
                  padding="1"
                  size="9"
                  justifyContent="center"
                  borderLeftWidth={0}
                  borderBottomLeftRadius={0}
                  borderTopLeftRadius={0}
                  borderColor={this.state.isInputFocused ? "primary.400" : this.props.isInvalid ? "error.600" : "coolGray.200"}
                  _icon={{
                    as: FontAwesome5,
                    name: "angle-down",
                    size: "5",
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                  {...triggerProps}
                />
              )
            }}>
            <Popover.Content>
              <Popover.Arrow />
              <Popover.CloseButton />
              <Popover.Header>{this.props.label}</Popover.Header>
              <Popover.Body width={this.props.popOverWidth ?? 350} height={this.props.popOverHeight ?? 210}>
                <VStack space={2}>
                  <Input
                    autoFocus={true}
                    placeholder="Pesquisar"
                    onSubmitEditing={() => {
                      if (this.state.searchItems.length === 1) {
                        this.#handleItemPress(this.state.searchItems[0]);
                      }
                    }}
                    onChangeText={(text) => {
                      if (this.timeoutID > 0) {
                        clearTimeout(this.timeoutID);
                      }
                      this.timeoutID = setTimeout(() => {
                        this.timeoutID = -1;
                        this.#searchText(text);
                      }, 400);
                    }}
                    InputLeftElement={
                      <Icon
                        as={FontAwesome5}
                        name="search"
                        size="4"
                        textAlign="center"
                        alignSelf="center"
                        marginLeft={1}
                      />
                    }
                  />
                  {
                    this.state.searching &&
                    <Center
                      height={this.props.popOverHeight ? this.props.popOverHeight - 65 : 145}
                      borderWidth={1}
                      borderColor="coolGray.400"
                      borderRadius={5}
                    >
                      <Spinner></Spinner>
                    </Center>
                    ||
                    !this.state.searching &&
                    <FlatList
                      height={this.props.popOverHeight ? this.props.popOverHeight - 65 : 145}
                      data={this.state.searchItems}
                      renderItem={this.#renderSearchItem}
                      keyExtractor={(item) => { return item.id.toString(); }}
                      borderWidth={1}
                      borderColor="coolGray.400"
                      borderRadius={5}
                      ListEmptyComponent={<Center><Text>Sem resultados</Text></Center>}
                    />
                  }
                </VStack>
              </Popover.Body>
            </Popover.Content>
          </Popover>
        </HStack>
        <FormControl.ErrorMessage marginTop="0">{this.props.errorMessage}</FormControl.ErrorMessage>
      </FormControl>
    );
  }

  #renderSearchItem = ({ item, index, separators }) => {
    return (
      <Pressable
        onPress={this.#handleItemPress.bind(this, item)}>
        {
          ({ isHovered, isFocused, isPressed }) => {
            return (
              <GridRow 
                tooltipVisibool={false}    
                flex="1"
                values={[item.nome]}
                dimensions={[1]}
                backgroundColor={isPressed ? "primary.200" : isHovered ? "primary.100" : (index % 2 === 0) ? "coolGray.100" : "white"}
              />
            );
          }
        }
      </Pressable>
    )
  }

  #handlePopoverOpen = async () => {
    if (this.props.onSearchItems) {
      this.setState({ searching: true, isPopOverOpen: true });
      const items = await this.props.onSearchItems('');
      this.setState({
        searching: false,
        searchItems: items ?? [],
      });
    }
  }

  #searchText = async (text) => {
    if (this.props.onSearchItems) {
      this.setState({ searching: true });
      const items = await this.props.onSearchItems(text);
      this.setState({
        searching: false,
        searchItems: items ?? [],
      });
    }
  }

  #handleItemPress = (item) => {
    this.props.onItemSelect?.(item);
    this.setState({ isPopOverOpen: false });
  }

}

export default SearchInput;