import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController'

class SecurityController {

  getPerfis = async (text) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Security/perfis', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        const perfis = await response.json();
        return perfis.filter( p => text.length === 0 || p.nome.toLowerCase() === text.toLowerCase()).map(p => {
          return {id: p.nome, nome: p.descricao}
        });
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  getPerfil = async (text) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Security/perfis/'+text, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        const perfil = await response.json();
        return perfil;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export const securityController = new SecurityController();
