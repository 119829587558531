import React from "react";

import {
  FlatList,
  HStack,
  Text,
  Box,
  VStack
} from 'native-base';

import ActionButton from "../components/ActionButton";
import LicitacaoProdutoList from "./ViewLicitacaoProdutoList";
import LicitacaoProdutoEditor from "./ViewLicitacaoProdutoEditor";
import TextSearchPanel from "../components/TextSearchPanel";

const produtoIncludesText = (produto, text) => {
  const textUpperCasse = text.toUpperCase();
  return (
    produto.descricao.toUpperCase().includes(textUpperCasse)
    ||
    produto.codigo.toUpperCase().includes(textUpperCasse)
  );
}

class LoteList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentProdutosPage: 1
    }
  }

  render() {
    return (
      <VStack flex={1} >
        <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.800">
          <Box flex={1} />
          <Text flex={1} padding={1} color="white" textAlign={"center"} >{this.props.lote.descricao}</Text>
          <HStack flex={1} padding={1} justifyContent="flex-end">
            <ActionButton
              viewOnly={this.props.viewOnly}
              iconName="edit"
              tooltip="Editar lote"
              color="white"
              onPress={() => this.props.onLoteEdit?.(this.props.lote)}
            />
            <ActionButton
              viewOnly={this.props.viewOnly}
              iconName="trash"
              tooltip="Excluir este lote"
              color="white"
              onPress={() => this.props.onLoteDelete?.(this.props.lote)}
            />
          </HStack>
        </HStack>
        <LicitacaoProdutoList
          produtos={this.#filterProdutos()}
          onProdutoDelete={this.props.onProdutoDelete}
          onProdutoEdit={this.props.onProdutoEdit}
          page={this.state.currentProdutosPage}
          onChangePage={(page) => {
            this.setState({ currentProdutosPage: page });
          }}
        />
      </VStack>
    )
  }

  #filterProdutos = () => {
    return this.props.lote.itens.filter((produto) => produtoIncludesText(produto, this.props.filterText.trim()));
  }
}


export default class LicitacaoLoteList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editingLote: null,
      editingProduto: null,
      produtoForDeletion: null,
      searchedText: '',
    }
  }

  render() {
    return (
      <VStack space={2}>

        <TextSearchPanel
          placeholder="Buscar produtos"
          disablePersistence={true}
          onSearch={(text) => this.setState({ searchedText: text })}
        />

        <FlatList
          borderRadius="4"
          minHeight="60"
          data={this.#filterLotes()}
          keyExtractor={(item, index) => { return index.toString(); }}
          renderItem={({ item, index, separators }) => {
            return (
              <LoteList
                lote={item}
                filterText={this.state.searchedText}
                onLoteEdit={this.props.onLoteEdit}
                onLoteDelete={this.props.onLoteDelete}
                onProdutoDelete={(produto) => { this.#handleProdutoDelete(produto, item) }}
                onProdutoEdit={(produto) => this.setState({
                  editingProduto: produto,
                  editingLote: item,
                })}
              />
            );
          }}
        />
        <LicitacaoProdutoEditor
          isOpen={!!this.state.editingProduto}
          produto={this.state.editingProduto}
          onClose={() => this.setState({ editingProduto: null })}
          onSave={this.#handleProdutoSave}
          key={"LicitacaoEditorProduto" + this.state.editingProduto?.id}
        />
      </VStack>
    );
  }

  #handleProdutoSave = (produto) => {
    const existingProduto = this.state.editingLote.itens.find((p) => p.id === produto.id);
    if (existingProduto) {
      Object.assign(existingProduto, produto);
    } else {
      this.state.editingLote.itens.push(produto);
    }
    this.props.onLoteChanged?.(Object.assign({}, this.state.editingLote));
    this.setState({
      editingProduto: null,
      clickedLote: null,
    });
  }

  #handleProdutoDelete = (produto, lote) => {
    lote.itens = lote.itens.filter(p => p.id !== produto.id);
    this.props.onLoteChanged?.(Object.assign({}, lote));
  }

  #filterLotes = () => {
    return this.props.lotes.filter((lote) => {
      return lote.itens.some((produto) => produtoIncludesText(produto, this.state.searchedText.trim()));
    });
  }
}